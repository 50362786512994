/*===============================
========== 00 Google Fonts Local ==========
===============================*/
html,
body {
  height: 100%;
  width: 100%;
}
body.avepdf {
  background-color: #ffffff;
  color: #2c2c2c;
}
* {
  -webkit-overflow-scrolling: touch;
}
#root {
  height: 100%;
}
.async-svg-container {
  display: block;
}
.async-svg-container svg {
  width: 100%;
  height: 100%;
}
.avepdf {
  /*===============================
========== 01 General ==========
===============================*/
  /*===============================
========== 02. Typography ==========
===============================*/
  /*========== Lead Paragraph ==========*/
  /*========== Special Headings ==========*/
  /*========== Font Weight ==========*/
  /*====================================
========== Main Wrapper ==========
======================================*/
  /*===============================
========== AvePDF Header ==========
===============================*/
  /*====== Header ======*/
  /*=== New main header ===*/
  /*====== AvePDF Auth Buttons ======*/
  /*====== AvePDF Hamburger Menu ======*/
  /*========== Notification Bar ==========*/
  /*===============================
========== AvePDF Main Content ==========
===============================*/
  /* Login & Sign up */
  /*===============================
========== 09. Tools ==========
===============================*/
  /* Tools */
  /* tooltip header item */
  /* SHARE BUTTON START */
  /* SHARE BUTTON END */
  /* Share Bar Start */
  /* Share Bar End*/
  /* RENAME FILE MODAL */
  /* END RENAME FILE MODAL */
  /* EMBED BUTTON START */
  /* EMBED BUTTON END */
  /* Labels Tools*/
  /* Tools Info*/
  /* Upload Button */
  /*===============================
========== AvePDF Footer ==========
===============================*/
  /*===============================
========== Logos ==========
===============================*/
  /*============================================
========== 03. AvePDF tooltip start ==========
=============================================*/
  /*===========================================*/
  /*=========================================
========== 04. Internet Explorer ==========
===========================================*/
  /*=========================================
========== 04. Buttons ==========
===========================================*/
  /* Ant Default Button*/
  /* White Button */
  /* Upload & Download Buttons Group */
  /*----------------------
    SDK Merchandizing
    -----------------------*/
  /*===============================
========== Forms & Inputs ==============
===============================*/
  /*========== Forms ==========*/
  /*===============================
========== Select & Dropdown ==============
===============================*/
  /*===============================
========== Filters ==============
===============================*/
  /*===============================
========== Search - tools ==============
===============================*/
  /*===============================
========== Tool Item ==============
===============================*/
  /*====== Tool Page Content ======*/
  /*====== Custom Notifications ======*/
  /*====== Notifications ======*/
  /*===============================
========== Cards ==============
===============================*/
  /*==================================================
========== 15. AvePDF Custom CSS ==========
====================================================*/
  /*====== SDK OFFER ======*/
  /*====== NEW SDK OFFER ======*/
  /*====== Blog ======*/
  /*====== Home Page Style ======*/
  /*========== Special Background ==========*/
  /*========== Testimonials ==========*/
  /*====== OFFER TOOLS ON DOWNLOAD START ======*/
  /*====== HOW TO USE SECTION ======*/
  /*====== DID YOU KNOW SECTION ======*/
  /*====== Slider ======*/
  /* WIN10 LOADER */
  /*====== Crop Modal ======*/
  /*====== InputNumber (disable native & useless HTML arrow) ======*/
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
  /*====== Redaction Modal ======*/
  /*====== Signature modal  ======*/
  /* favorite icon */
  /*default state*/
  /*----------------------
    Watermark
    -----------------------*/
  /*----------------------
    PDF/A Validation
    -----------------------*/
  /*===============================
========== 16. Responsive Breakpoints ==========
===============================*/
  /* Large devices (desktops, less than 1200px) */
  /* Medium devices (tablets, less than 992px)  */
  /* Small devices (landscape phones, less than 768px) */
  /* Extra small devices (portrait phones, less than 576px) */
  /* Extra very small devices (portrait phones, less than 376px) */
  /* Mobile grid homepage*/
  /* End Mobile grid homepage*/
  /*====================================
========== 17. Tools Colors ==========

    moved to less
======================================*/
  /* styles for '...' */
  /*====================================
========== 18. Signup Page ==========
======================================*/
  /*===== Action Row =====*/
  /*==========================================
========== 19. Logged user header ==========
============================================*/
  /*============================================
========== 20. Document Viewer Page ==========
==============================================*/
  /*====== Custom angle =====*/
  /* Floating Toolbar */
  /* Ant Default Tooltips */
  /* Loading Bar */
  /* Share Overlay */
  /*=================================
========== 12. Utilities ==========
===================================*/
  /*========== White Space ==========*/
  /*========== Icons ==========*/
  /* icon position */
  /*========== Icon List Styles ==========*/
  /* Icon List */
  /* Separator List */
  /* List Medium */
  /* List Big */
  /* Media List */
  /* Rating List */
  /* List 3 columns */
  /* List 2 columns */
  /*========== Icon List Styles ==========*/
  /*===============================
========== Premium ==========
===============================*/
  /* Premium banner notification*/
  /* Empty placeholder */
  /* Single placeholder animation */
  /*===============================
========== Cookies Consent ==========
===============================*/
}
.avepdf a {
  color: #1D7C76;
}
.avepdf a:hover,
.avepdf a:focus {
  color: #1D7C76;
  text-decoration: none;
}
.avepdf a:focus,
.avepdf a:active,
.avepdf button::-moz-focus-inner,
.avepdf input[type="reset"]::-moz-focus-inner,
.avepdf input[type="button"]::-moz-focus-inner,
.avepdf input[type="submit"]::-moz-focus-inner,
.avepdf select::-moz-focus-inner,
.avepdf input[type="file"] > input[type="button"]::-moz-focus-inner {
  border: 0;
  outline: none !important;
}
.avepdf .vmiddle {
  display: flex;
  align-items: center;
}
.avepdf h1 small,
.avepdf h2 small,
.avepdf h3 small,
.avepdf h4 small,
.avepdf h5 small,
.avepdf h6 small,
.avepdf .h1 small,
.avepdf .h2 small,
.avepdf .h3 small,
.avepdf .h4 small,
.avepdf .h5 small,
.avepdf .h6 small,
.avepdf h1 .small,
.avepdf h2 .small,
.avepdf h3 .small,
.avepdf h4 .small,
.avepdf h5 .small,
.avepdf h6 .small,
.avepdf .h1 .small,
.avepdf .h2 .small,
.avepdf .h3 .small,
.avepdf .h4 .small,
.avepdf .h5 .small,
.avepdf .h6 .small {
  font-weight: 500;
}
.avepdf h1 a,
.avepdf h2 a,
.avepdf h3 a,
.avepdf h4 a,
.avepdf h5 a,
.avepdf h6 a {
  color: inherit;
}
.avepdf .text-muted {
  color: #b0bdd2;
}
.avepdf .text-notransform {
  text-transform: none;
}
.avepdf .ant-btn.ant-btn-buy {
  background-color: #1f1f1f;
  border-color: #1f1f1f;
  color: #fff;
  padding: 0.6em 1.6em !important;
  font-weight: 700;
  border-radius: 5px;
  border: 1px solid transparent;
}
.avepdf .ant-btn.ant-btn-buy:hover {
  opacity: 0.8;
}
.avepdf .ant-btn .ant-btn-disabled {
  background-color: rgba(120, 120, 120, 0.05) !important;
  color: rgba(31, 31, 31, 0.25) !important;
  border-color: rgba(120, 120, 120, 0.25) !important;
}
.avepdf .ant-btn .ant-btn-disabled svg path {
  fill: rgba(31, 31, 31, 0.25) !important;
}
.avepdf .ant-btn .ant-btn-disabled img {
  opacity: 0.35;
}
.avepdf .ant-btn .btn-icon {
  width: 28px;
  height: auto;
}
.avepdf .ant-btn.ant-btn-primary[disabled] {
  background-color: rgba(120, 120, 120, 0.05) !important;
  color: rgba(31, 31, 31, 0.25) !important;
  border-color: rgba(120, 120, 120, 0.25) !important;
}
.avepdf .ant-btn.ant-btn-primary[disabled] svg path {
  fill: rgba(31, 31, 31, 0.25) !important;
}
.avepdf .ant-btn.ant-btn-primary[disabled] img {
  opacity: 0.35;
}
.avepdf .ant-btn.ant-btn-primary[disabled]:hover {
  background-color: rgba(120, 120, 120, 0.05) !important;
  color: rgba(31, 31, 31, 0.25) !important;
  border-color: rgba(120, 120, 120, 0.25) !important;
}
.avepdf .ant-btn.ant-btn-primary[disabled]:hover svg path {
  fill: rgba(31, 31, 31, 0.25) !important;
}
.avepdf .ant-btn.ant-btn-primary[disabled]:hover img {
  opacity: 0.35;
}
.avepdf .ant-btn[disabled] {
  background-color: rgba(120, 120, 120, 0.05) !important;
  color: rgba(31, 31, 31, 0.25) !important;
  border-color: rgba(120, 120, 120, 0.25) !important;
}
.avepdf .ant-btn[disabled] svg path {
  fill: rgba(31, 31, 31, 0.25) !important;
}
.avepdf .ant-btn[disabled] img {
  opacity: 0.35;
}
.avepdf .ant-btn[disabled]:hover {
  background-color: rgba(120, 120, 120, 0.05) !important;
  color: rgba(31, 31, 31, 0.25) !important;
  border-color: rgba(120, 120, 120, 0.25) !important;
}
.avepdf .ant-btn[disabled]:hover svg path {
  fill: rgba(31, 31, 31, 0.25) !important;
}
.avepdf .ant-btn[disabled]:hover img {
  opacity: 0.35;
}
.avepdf .ant-btn.ant-btn-icon-only {
  width: auto;
}
.avepdf .ant-btn.download-action-button,
.avepdf .ant-btn.upload-more {
  color: #1f1f1f !important;
  border-width: 1px;
  border-color: #1f1f1f;
  background: #fff;
}
.avepdf .ant-btn.download-action-button.white-action-button,
.avepdf .ant-btn.upload-more.white-action-button {
  color: #1f1f1f;
  border-color: #1f1f1f;
}
.avepdf .ant-btn.download-action-button.white-action-button svg path,
.avepdf .ant-btn.upload-more.white-action-button svg path {
  fill: #1f1f1f;
}
.avepdf .ant-btn.download-action-button .download-icon-svg,
.avepdf .ant-btn.upload-more .download-icon-svg {
  vertical-align: top;
  width: 48px;
}
@media (max-width: 575px) {
  .avepdf .ant-btn.download-action-button .download-icon-svg,
  .avepdf .ant-btn.upload-more .download-icon-svg {
    margin: auto;
  }
}
.avepdf .ant-btn.download-action-button .download-icon-svg path,
.avepdf .ant-btn.upload-more .download-icon-svg path {
  fill: #1f1f1f;
}
.avepdf .ant-btn.download-action-button svg path,
.avepdf .ant-btn.upload-more svg path {
  fill: #1f1f1f;
}
.avepdf .ant-btn.download-action-button.delete-file-btn,
.avepdf .ant-btn.upload-more.delete-file-btn {
  display: block;
  width: 100%;
  margin-top: 5px;
}
.avepdf .ant-btn.download-action-button.delete-file-btn p,
.avepdf .ant-btn.upload-more.delete-file-btn p {
  margin: 0;
}
.avepdf .ant-btn.download-action-button:hover,
.avepdf .ant-btn.upload-more:hover,
.avepdf .ant-btn.download-action-button:focus,
.avepdf .ant-btn.upload-more:focus {
  border-color: #1f1f1f !important;
  background: #fff !important;
}
.avepdf .ant-btn.ant-btn-danger {
  background: #ff4d4f !important;
  border-color: #ff4d4f !important;
}
.avepdf .ant-btn.ant-btn-success {
  background: #629722 !important;
  border-color: #629722 !important;
}
.avepdf .lead.lead-small {
  font-size: 1.16em;
}
.avepdf .lead.lead-medium {
  font-size: 1.4em;
}
.avepdf .lead.lead-xbig {
  font-size: 1.8em;
}
.avepdf .lead.lead-big {
  font-size: 2em;
}
.avepdf .title-large {
  font-size: 5.143em;
}
.avepdf .title-medium {
  font-size: 4.571em;
}
.avepdf .title-xmedium {
  font-size: 3.857em;
}
.avepdf .title-normal {
  font-size: 2.8em;
}
.avepdf .title-xnormal {
  font-size: 2.4em;
}
.avepdf .title-small {
  font-size: 2em;
}
.avepdf .title-exsmall {
  font-size: 1.429em;
}
.avepdf .title-header {
  font-size: 1.764em;
}
.avepdf .xlight {
  font-weight: 100 !important;
}
.avepdf .light {
  font-weight: 300 !important;
}
.avepdf .sbold {
  font-weight: 600 !important;
}
.avepdf .bold {
  font-weight: 700 !important;
}
.avepdf .ubold {
  font-weight: 900 !important;
}
.avepdf .main-content {
  /* background-color: #fafdff; */
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.avepdf .main-content-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.avepdf .header {
  position: relative;
  padding: 5px 15px;
}
.avepdf .ave-header {
  padding: 10px 0;
  border-bottom: 1px solid rgba(102, 110, 132, 0.2);
}
.avepdf .ave-header .logo-wrapper {
  margin-bottom: 0;
  padding: 0;
  width: 161px;
  height: 26px;
}
.avepdf .ave-header .logo-wrapper svg {
  display: block;
  width: 161px;
  height: 26px;
}
.avepdf .ave-header .navbar .hamburger-menu-button {
  height: 54px;
  margin: 0;
}
.avepdf .ave-header .navbar .hamburger-menu-button.active {
  border: none;
}
.avepdf .ave-header .navbar .header-hamburger-menu {
  display: none;
  position: relative;
  z-index: initial;
  right: 0;
  margin-top: 0;
}
.avepdf .ave-header .navbar .header-hamburger-menu.show {
  display: block;
}
.avepdf .ave-header .navbar .header-hamburger-menu ul li {
  margin-bottom: 0;
}
@media (max-width: 1199.98px) {
  .avepdf .ave-header .navbar .header-hamburger-menu ul li {
    margin: 0;
  }
}
.avepdf .ave-header .navbar a {
  color: #666E84;
}
.avepdf .ave-header .navbar a:hover,
.avepdf .ave-header .navbar a:focus,
.avepdf .ave-header .navbar a:active {
  color: #1D7C76;
}
@media (max-width: 1199.98px) {
  .avepdf .ave-header .navbar .navbar-nav {
    padding: 0 15px 15px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #e8e9ec;
    margin-top: 0;
  }
}
@media (max-width: 1199.98px) {
  .avepdf .ave-header .navbar .navbar-nav .ave-main-menu-user {
    width: 100%;
  }
}
.avepdf .ave-header .navbar .navbar-nav .nav-item {
  font-size: 14px;
  margin: 0 0 0 20px;
}
@media (max-width: 1199.98px) {
  .avepdf .ave-header .navbar .navbar-nav .nav-item {
    margin: 0;
    display: block;
  }
  .avepdf .ave-header .navbar .navbar-nav .nav-item .nav-link {
    padding: 1rem;
  }
}
@media (max-width: 1199.98px) {
  .avepdf .ave-header .navbar .navbar-nav li > a {
    line-height: 1.375rem;
  }
}
.avepdf .ave-header .navbar .navbar-nav .selected-language {
  font-weight: 600;
  display: block;
  border-radius: 5px;
  padding: 9px 15px;
  border: 2px solid #e8e9ec;
  color: #666E84;
  text-transform: uppercase;
}
.avepdf .ave-header .navbar .navbar-nav .selected-language svg {
  vertical-align: initial;
}
.avepdf .ave-header .navbar .navbar-nav .ave-main-menu-user .user-buttons .ant-btn {
  font-weight: 600;
  color: #1D7C76 !important;
  padding: 5px 15px !important;
  border-radius: 5px;
  background-color: rgba(76, 164, 159, 0.2);
  margin-right: 20px;
  border: none;
}
@media (max-width: 1199.98px) {
  .avepdf .ave-header .navbar .navbar-nav .ave-main-menu-user .user-buttons .ant-btn {
    margin: 15px 0;
    width: 49%;
  }
  .avepdf .ave-header .navbar .navbar-nav .ave-main-menu-user .user-buttons .ant-btn:first-child {
    margin-right: 2%;
  }
}
@media (max-width: 767.98px) {
  .avepdf .ave-header .navbar .navbar-nav .ave-main-menu-user .user-buttons .ant-btn {
    display: block;
    width: 100%;
    margin: 15px 0;
  }
}
.avepdf .ave-header .navbar .navbar-nav .ave-main-menu-user .user-buttons .ant-btn:hover,
.avepdf .ave-header .navbar .navbar-nav .ave-main-menu-user .user-buttons .ant-btn:focus,
.avepdf .ave-header .navbar .navbar-nav .ave-main-menu-user .user-buttons .ant-btn:active {
  color: #fff !important;
  background-color: #1D7C76;
  border: none;
}
.avepdf .ave-header .navbar .navbar-nav .ave-main-menu-user .user-buttons .ant-btn svg path {
  fill: #1D7C76;
}
.avepdf .ave-header .navbar.ave-navbar a {
  color: #1f1f1f;
  font-weight: 600;
}
.avepdf .ave-header .navbar.ave-navbar a:hover,
.avepdf .ave-header .navbar.ave-navbar afocus {
  color: #1D7C76;
}
.avepdf .ave-header .navbar.ave-navbar .navbar-nav {
  padding: 0;
  border-radius: 0;
  background: transparent;
  border: none;
  margin-top: 0;
}
@media (max-width: 991.98px) {
  .avepdf .ave-header .navbar.ave-navbar .navbar-nav .nav-link {
    border-bottom: none !important;
    padding: 0.6rem 1rem;
    white-space: nowrap;
    background: rgba(234, 234, 250, 0.3);
    margin-bottom: 2px;
    border-radius: 5px;
  }
}
.avepdf .ave-header .navbar.ave-navbar .megamenu {
  position: static;
}
.avepdf .ave-header .navbar.ave-navbar .megamenu .dropdown-menu {
  width: 100%;
  background: #ffffff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  top: calc(100% + 13px);
  padding: 0;
  border: 1px solid rgba(102, 110, 132, 0.2);
  border-top: transparent;
  box-shadow: 0 40px 60px -20px rgba(102, 110, 132, 0.15);
}
@media (max-width: 991.98px) {
  .avepdf .ave-header .navbar.ave-navbar .megamenu .dropdown-menu {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: 1px solid rgba(102, 110, 132, 0.2);
  }
}
.avepdf .ave-header .navbar.ave-navbar .megamenu .dropdown-menu .megamenu-tools {
  position: relative;
  width: 100%;
}
.avepdf .ave-header .navbar.ave-navbar .megamenu .dropdown-menu .megamenu-tools .megamenu-tools-category {
  width: 100%;
  margin-bottom: 15px;
}
.avepdf .ave-header .navbar.ave-navbar .megamenu .dropdown-menu .megamenu-tools .megamenu-tools-category .megamenu-category-title {
  text-transform: capitalize;
  font-size: 1.1em;
  font-weight: 600;
  border-bottom: 1px solid rgba(102, 110, 132, 0.2);
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #929292;
}
.avepdf .ave-header .navbar.ave-navbar .megamenu .dropdown-menu .megamenu-tools .megamenu-tools-category ul {
  font-size: 14px;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  column-count: initial;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: row;
  gap: 2px;
  align-items: center;
}
@media (max-width: 1199.98px) {
  .avepdf .ave-header .navbar.ave-navbar .megamenu .dropdown-menu .megamenu-tools .megamenu-tools-category ul {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 991.98px) {
  .avepdf .ave-header .navbar.ave-navbar .megamenu .dropdown-menu .megamenu-tools .megamenu-tools-category ul {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 575.98px) {
  .avepdf .ave-header .navbar.ave-navbar .megamenu .dropdown-menu .megamenu-tools .megamenu-tools-category ul {
    grid-template-columns: repeat(1, 1fr);
  }
}
.avepdf .ave-header .navbar.ave-navbar .megamenu .dropdown-menu .megamenu-tools .megamenu-tools-category ul li {
  display: inline-block;
  line-height: 1.4;
  padding: 0 5px 0 45px;
  width: 100%;
  position: relative;
  border: 1px solid transparent;
  border-radius: 5px;
}
.avepdf .ave-header .navbar.ave-navbar .megamenu .dropdown-menu .megamenu-tools .megamenu-tools-category ul li.active {
  background: rgba(234, 234, 250, 0.08);
  border: 1px solid rgba(102, 110, 132, 0.2);
}
.avepdf .ave-header .navbar.ave-navbar .megamenu .dropdown-menu .megamenu-tools .megamenu-tools-category ul li:hover {
  background: rgba(234, 234, 250, 0.08);
  border: 1px solid rgba(102, 110, 132, 0.2);
}
.avepdf .ave-header .navbar.ave-navbar .megamenu .dropdown-menu .megamenu-tools .megamenu-tools-category ul li:hover a {
  color: #1D7C76 !important;
}
.avepdf .ave-header .navbar.ave-navbar .megamenu .dropdown-menu .megamenu-tools .megamenu-tools-category ul li .tool-item-block-icon {
  display: inline-block;
  max-width: 32px;
  position: absolute;
  left: 5px;
  top: 50%;
  margin-top: -16px;
  padding: 4px;
}
.avepdf .ave-header .navbar.ave-navbar .megamenu .dropdown-menu .megamenu-tools .megamenu-tools-category ul li a {
  font-weight: 400;
  display: block;
  padding: 12px 0;
}
.avepdf .logo-wrapper svg path {
  fill: #1f1f1f !important;
}
.avepdf .modal-logo {
  max-height: 32px;
  margin-right: 10px;
}
.avepdf .theme-switch-wrapper {
  margin: 0;
  font-size: 14px;
}
.avepdf .theme-switch {
  position: relative;
  margin-right: 10px;
  /* WIN10 LOADER ANIMATION*/
}
.avepdf .theme-switch .btn {
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
}
.avepdf .theme-switch .btn_background {
  width: 45px;
  height: 26px;
  border-radius: 50px;
  background: transparent;
  border: 3px solid #1f1f1f;
  cursor: pointer;
}
.avepdf .theme-switch .btn_background .btn_face {
  background: #1f1f1f;
}
.avepdf .theme-switch .btn_background.color {
  background: #1f1f1f;
  border-color: #fff;
}
.avepdf .theme-switch .btn_background.color .btn_face {
  background: #fff;
}
.avepdf .theme-switch .btn_face {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #1f1f1f;
}
.avepdf .theme-switch .btn_face.move {
  transform: translateX(17px);
}
@keyframes orbit {
  0% {
    transform: rotate(225deg);
    opacity: 1;
    animation-timing-function: ease-out;
  }
  7% {
    transform: rotate(345deg);
    animation-timing-function: linear;
  }
  30% {
    transform: rotate(455deg);
    animation-timing-function: ease-in-out;
  }
  39% {
    transform: rotate(690deg);
    animation-timing-function: linear;
  }
  70% {
    transform: rotate(815deg);
    opacity: 1;
    animation-timing-function: ease-out;
  }
  75% {
    transform: rotate(945deg);
    animation-timing-function: ease-out;
  }
  76% {
    transform: rotate(945deg);
    opacity: 0;
  }
  100% {
    transform: rotate(945deg);
    opacity: 0;
  }
}
.avepdf .header-container {
  font-size: 0.875em;
}
.avepdf .header .auth-buttons .btn {
  font-weight: 600;
}
.avepdf .header .auth-buttons a {
  display: inline-block;
  color: #1f1f1f;
  background-color: #fff;
  border: none;
  outline: none;
  margin-right: 8px;
  padding: 7px 20px;
  border-radius: 5px;
}
.avepdf .header .auth-buttons a:last-child {
  margin-right: 0;
}
.avepdf .header .auth-buttons .login-button {
  border: 2px solid #1f1f1f;
}
.avepdf .header .auth-buttons .signup-button {
  color: #fff;
  background-color: #1f1f1f;
  border: 2px solid #1f1f1f;
}
.avepdf .hamburger-menu-button:after,
.avepdf .hamburger-menu-button:before,
.avepdf .hamburger-menu-button span {
  background-color: #1f1f1f;
}
.avepdf .hamburger-menu-button.active {
  border: 1px solid rgba(102, 110, 132, 0.2);
  border-bottom-color: #fff;
  border-radius: 5px 5px 0 0;
}
.avepdf .hamburger-menu-button.active:before {
  transform: translateY(11px) rotate(135deg);
}
.avepdf .hamburger-menu-button.active:after {
  transform: translateY(-9px) rotate(-135deg);
}
.avepdf .hamburger-menu-button.active span {
  transform: scale(0);
}
.avepdf .header-hamburger .logged-user-image {
  margin: 0 auto;
  display: block;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .avepdf .header-hamburger-menu {
    position: relative;
    right: 0;
  }
}
.avepdf .header-hamburger-menu.active {
  display: block;
}
.avepdf .header-hamburger-menu .username {
  margin: 10px 0;
  text-align: center;
  color: #1f1f1f;
}
.avepdf .header-hamburger-menu ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.avepdf .header-hamburger-menu ul li {
  margin-bottom: 15px;
}
.avepdf .header-hamburger-menu .login-button,
.avepdf .header-hamburger-menu .signup-button {
  color: #fff !important;
  background-color: #1f1f1f;
  border: none;
  border-radius: 5px;
}
.avepdf .header-hamburger-menu .signup-button {
  color: #fff;
  background-color: #1D7C76;
}
.avepdf .header-hamburger-menu .horizontal-borders {
  border: 1px solid #ededed;
  border-left: none;
  border-right: none;
  padding: 12px 20px 12px 0;
  cursor: pointer;
}
.avepdf .header-hamburger-menu .horizontal-borders a {
  color: #1f1f1f;
  width: 100%;
  display: inline-block;
}
.avepdf .header-hamburger-menu .icon-dropdown {
  cursor: pointer;
  max-width: 20px;
}
.avepdf .header-hamburger-menu .pre-menu-icon img,
.avepdf .header-hamburger-menu .main-menu-nav-icon img {
  cursor: pointer;
  max-width: 20px;
}
.avepdf .header-hamburger-menu .pre-menu-icon img path,
.avepdf .header-hamburger-menu .main-menu-nav-icon img path {
  fill: #1f1f1f !important;
}
.avepdf .ave-main-menu {
  border: 1px solid rgba(102, 110, 132, 0.2);
  border-radius: 5px 0 5px 5px;
}
@media (max-width: 768px) {
  .avepdf .ave-main-menu {
    margin-top: -10px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    min-width: 100%;
  }
}
.avepdf .ave-main-menu:before {
  content: "";
  position: absolute;
  width: 42px;
  height: 6px;
  background-color: white;
  top: -3px;
  right: 0;
}
.avepdf .ave-main-menu .ave-main-menu-user {
  border-bottom: 1px solid rgba(102, 110, 132, 0.2);
}
.avepdf .ave-main-menu .ave-main-menu-user .ave-main-menu-profile {
  padding: 0 5px;
}
.avepdf .ave-main-menu .ave-main-menu-user .ave-main-menu-profile .logged-in-menu li:last-child {
  margin-bottom: 0;
}
.avepdf .ave-main-menu .ave-main-menu-user .ave-main-menu-profile .logged-in-menu li .ant-btn {
  color: #1f1f1f;
  border: 1px solid rgba(102, 110, 132, 0.2);
  padding: 10px 15px !important;
}
.avepdf .ave-main-menu .ave-main-menu-user .ave-main-menu-profile .logged-in-menu li .ant-btn .ant-btn-disabled {
  background-color: rgba(120, 120, 120, 0.05);
  color: rgba(31, 31, 31, 0.25);
  border-color: rgba(120, 120, 120, 0.25);
}
.avepdf .ave-main-menu .ave-main-menu-user .ave-main-menu-profile .logged-in-menu li .ant-btn .ant-btn-disabled svg path {
  fill: rgba(31, 31, 31, 0.25) !important;
}
.avepdf .ave-main-menu .ave-main-menu-user .ave-main-menu-profile .logged-in-menu li .ant-btn .ant-btn-disabled img {
  opacity: 0.5;
}
.avepdf .ave-main-menu .ave-main-menu-user .ave-main-menu-profile .logged-in-menu li .ant-btn[disabled] {
  background-color: rgba(120, 120, 120, 0.05);
  color: rgba(31, 31, 31, 0.25);
  border-color: rgba(120, 120, 120, 0.25);
}
.avepdf .ave-main-menu .ave-main-menu-user .ave-main-menu-profile .logged-in-menu li .ant-btn[disabled] svg path {
  fill: rgba(31, 31, 31, 0.25) !important;
}
.avepdf .ave-main-menu .ave-main-menu-user .ave-main-menu-profile .logged-in-menu li .ant-btn[disabled] img {
  opacity: 0.5;
}
.avepdf .ave-main-menu .ave-main-menu-user .ave-main-menu-profile .logged-in-menu li .ant-btn[disabled]:hover {
  background-color: rgba(120, 120, 120, 0.05);
  color: rgba(31, 31, 31, 0.25);
  border-color: rgba(120, 120, 120, 0.25);
}
.avepdf .ave-main-menu .ave-main-menu-user .ave-main-menu-profile .logged-in-menu li .ant-btn[disabled]:hover svg path {
  fill: rgba(31, 31, 31, 0.25) !important;
}
.avepdf .ave-main-menu .ave-main-menu-user .ave-main-menu-profile .logged-in-menu li .ant-btn[disabled]:hover img {
  opacity: 0.5;
}
.avepdf .ave-main-menu .ave-main-menu-user .ave-main-menu-profile .logged-in-menu li .ant-btn:hover,
.avepdf .ave-main-menu .ave-main-menu-user .ave-main-menu-profile .logged-in-menu li .ant-btn:focus {
  background-color: rgba(160, 188, 222, 0.08);
}
.avepdf .ave-main-menu .ave-main-menu-user .ave-main-menu-profile .icon-dropdown {
  margin-right: 15px;
}
.avepdf .ave-main-menu .ave-main-menu-nav {
  font-size: 14px;
  font-weight: 500;
}
.avepdf .ave-main-menu .ave-main-menu-nav .material-icons {
  vertical-align: middle;
  margin-right: 10px;
}
.avepdf .ave-main-menu .ave-main-menu-nav ul li {
  margin: 0;
  padding: 0;
  border-top: 1px solid rgba(102, 110, 132, 0.2);
}
.avepdf .ave-main-menu .ave-main-menu-nav ul li .ave-nav-link {
  color: #1f1f1f !important;
}
.avepdf .ave-main-menu .ave-main-menu-nav ul li:hover,
.avepdf .ave-main-menu .ave-main-menu-nav ul li:focus {
  background-color: rgba(160, 188, 222, 0.08);
  cursor: pointer;
}
.avepdf .ave-main-menu .ave-main-menu-nav ul li .menu-item-dropdown {
  border: 1px solid rgba(102, 110, 132, 0.2);
  background: #fff;
  position: absolute;
  top: 0;
  right: 100%;
  margin-right: 10px;
  border-radius: 5px;
  width: max-content;
}
@media (max-width: 768px) {
  .avepdf .ave-main-menu .ave-main-menu-nav ul li .menu-item-dropdown {
    position: relative;
    right: 0;
    width: 100%;
    margin: 15px auto 0;
  }
}
.avepdf .ave-main-menu .ave-main-menu-nav ul li .menu-item-dropdown .menu-dropdown-list li {
  position: relative;
}
.avepdf .ave-main-menu .ave-main-menu-nav ul li .menu-item-dropdown .menu-dropdown-list li .pre-menu-icon {
  width: 20px;
  margin-right: 15px;
  display: inline-block;
}
.avepdf .ave-main-menu .ave-main-menu-nav ul li .menu-item-dropdown .menu-dropdown-list li .dev-icon {
  width: 20px;
  margin-right: 10px;
  display: inline-block;
}
.avepdf .ave-main-menu .ave-main-menu-nav ul li .menu-item-dropdown .menu-dropdown-list li .dev-icon.docuvieware-icon {
  color: #0382d4 !important;
}
.avepdf .ave-main-menu .ave-main-menu-nav ul li .menu-item-dropdown .menu-dropdown-list li .dev-icon.docuvieware-icon svg path {
  fill: #0382d4 !important;
}
.avepdf .ave-main-menu .ave-main-menu-nav ul li .menu-item-dropdown .menu-dropdown-list li a {
  padding: 15px 30px;
  margin: 0;
  display: block;
}
.avepdf .ave-main-menu .ave-main-menu-nav ul li .menu-item-dropdown .menu-dropdown-list li a.external-link {
  padding: 15px 60px 15px 15px;
}
.avepdf .ave-main-menu .ave-main-menu-nav ul li .menu-item-dropdown .menu-dropdown-list.menu-language-list {
  font-size: 13px;
  padding: 15px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 992px) {
  .avepdf .ave-main-menu .ave-main-menu-nav ul li .menu-item-dropdown .menu-dropdown-list.menu-language-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.avepdf .ave-main-menu .ave-main-menu-nav ul li .menu-item-dropdown .menu-dropdown-list.menu-language-list li {
  border: none;
  margin: 2px 0;
}
.avepdf .ave-main-menu .ave-main-menu-nav ul li .menu-item-dropdown .menu-dropdown-list.menu-language-list li span {
  padding: 10px 15px;
  display: block;
  border-radius: 5px;
}
.avepdf .ave-main-menu .ave-main-menu-nav ul li .menu-item-dropdown .menu-dropdown-list.menu-language-list li:hover,
.avepdf .ave-main-menu .ave-main-menu-nav ul li .menu-item-dropdown .menu-dropdown-list.menu-language-list li:focus {
  cursor: pointer;
  background-color: rgba(160, 188, 222, 0.08);
  border-radius: 5px;
}
.avepdf .ave-main-menu .ave-main-menu-nav ul li .menu-item-dropdown .menu-dropdown-list.menu-language-list li.active-lang span {
  font-weight: 600;
  background-color: rgba(160, 188, 222, 0.08);
}
.avepdf .ave-main-menu .ave-main-menu-theme-switch {
  border-top: 1px solid rgba(102, 110, 132, 0.2);
}
.avepdf .homepage-no-result-found {
  padding: 40px 0px;
  margin: auto;
  font-weight: 500;
}
.avepdf .content-inner-container {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 36px;
}
.avepdf .language-notification {
  margin: 0;
  color: #42a5f5;
  padding: 15px 0;
  z-index: 10;
  width: 100%;
  border-top: none;
  border-radius: 0;
  -webkit-border-radius: 0;
}
.avepdf .language-notification.alert-dismissable .close,
.avepdf .language-notification.alert-dismissible .close {
  opacity: 0.6;
  right: 0;
}
.avepdf .alert-out {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 100;
}
.avepdf .pricing-button {
  background-color: #1D7C76;
  color: #fff;
}
.avepdf .pricing-button:hover {
  color: #ffffff !important;
  opacity: 0.8;
}
.avepdf .header-alert {
  background-color: #1f1f1f;
  color: #fff;
  border: none;
  border-bottom: 1px solid rgba(102, 110, 132, 0.2);
  border-radius: 0;
  font-size: 85%;
}
.avepdf .header-alert .badge {
  font-size: 90%;
}
.avepdf .header-alert a {
  color: #fff;
  border-bottom: 1px dashed;
}
.avepdf .notification-bar {
  display: block;
  position: relative;
  width: 100%;
  background: #985885;
  background: linear-gradient(90deg, #985885 0%, #b5358b 100%);
  padding: 10px 0;
}
.avepdf .notification-bar p {
  margin: 0;
  color: #fff;
  font-size: 14px;
}
.avepdf .notification-bar a {
  color: #fff;
  text-decoration: underline;
}
.avepdf .home-header {
  color: #1f1f1f;
}
.avepdf .home-subheader {
  font-size: 17px;
  text-align: center;
  color: #3a485d;
  padding-top: 16px;
}
.avepdf .tool-item-description {
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #1f1f1f;
  padding: 0 5px;
}
.avepdf .small-icons .tool-item-icon img {
  max-width: 72px;
}
.avepdf .small-icons .tool-item-content {
  width: 130px;
  height: 130px;
}
.avepdf .small-icons .tool-item-content .tool-item-description {
  line-height: 0.7;
}
.avepdf .small-icons .tool-item-content .tool-item-description span {
  font-size: 11px;
}
.avepdf .language-overlay {
  height: 300px;
  width: 150px;
  overflow: auto;
}
.avepdf .font-family-overlay {
  width: 225px;
  overflow: auto;
  height: 300px;
}
.avepdf .font-family-overlay .ant-dropdown-menu {
  margin: 0 !important;
}
.avepdf .language-title {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
}
.avepdf #dragAndDropArea .selected-language {
  font-size: 1em;
  font-weight: 500;
}
.avepdf .language-change-loader {
  width: 100%;
  height: 5px;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}
.avepdf .language-select .custom-select {
  margin: 0;
  padding: 0 1.75rem 0rem 0;
  border: none;
  color: #354063;
  font-size: 0.857em;
  height: auto;
  line-height: 1.4;
  font-weight: 300;
  vertical-align: middle;
  width: 100%;
  cursor: pointer;
}
.avepdf .language-select .custom-select:focus {
  box-shadow: none;
}
.avepdf .language-select .dropdown-toggle {
  padding: 0;
}
.avepdf .language-select .dropdown-menu {
  border: 1px solid #354063 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 0;
}
.avepdf .language-select .dropdown-menu li {
  border: none;
  padding: 0;
  font-size: 0.875rem;
}
.avepdf .validation-summary-errors {
  margin-left: 0 !important;
  color: #ffffff !important;
  padding: 15px;
  margin-bottom: 30px;
  background-color: #ff0000;
  border: 2px solid #dd0000;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.avepdf .validation-summary-errors ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.857em;
}
.avepdf .field-validation-error {
  display: inline-block;
  margin: 5px 0;
  font-size: 0.857em;
}
.avepdf .social-login .col-sm-6 {
  padding-left: 5px;
  padding-right: 5px;
}
.avepdf .sign-form .checkbox {
  margin: 0;
  display: inline-block;
}
.avepdf .sign-form .forgot-pass {
  min-height: 20px;
  text-align: end;
  line-height: 26px;
  float: right;
}
.avepdf .dv-sticky-viewer {
  top: 20px;
  z-index: 990;
}
.avepdf .ant-modal-close-x {
  height: 35px;
  line-height: 35px;
  width: 35px;
}
.avepdf input[type='checkbox']:checked {
  filter: unset;
  -webkit-filter: invert(1) contrast(14) saturate(0);
}
.avepdf .dv-main input[type='checkbox']:checked {
  filter: unset;
  -webkit-filter: unset;
}
@-moz-document url-prefix() {
  .avepdf input[type='checkbox']:checked {
    filter: unset;
  }
}
.avepdf .tool-field {
  margin: 0 15px 45px;
  padding: 40px 30px 40px 30px;
  border: 1px solid rgba(102, 110, 132, 0.2);
  border-radius: 5px;
}
.avepdf .tool-field legend {
  display: inline-block;
  width: auto;
  padding: 0 10px;
  font-size: 18px;
  font-weight: bolder;
}
.avepdf .avetool-preloader {
  display: block;
  margin: 15% auto;
  height: 100%;
  width: 100%;
  max-width: 256px;
  z-index: 5;
}
.avepdf .avetool-preloader path {
  fill: #1f1f1f;
}
@media (max-width: 575.98px) {
  .avepdf .avetool-preloader {
    max-width: 180px;
  }
}
.avepdf #action {
  display: none;
}
.avepdf #download {
  display: none;
}
.avepdf .tool-action-wrapper {
  min-height: 256px;
}
.avepdf .drag-and-drop-area {
  min-height: 256px;
  border-style: dashed;
  border-width: 2px;
  border-color: #dfe6ec;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.avepdf .downloaded-wrapper {
  border-radius: 5px;
}
.avepdf .proceed-after-unlock {
  margin-top: 20px;
}
.avepdf .scroll-element {
  overflow-y: scroll;
  height: 35vw;
}
.avepdf .tools-container {
  padding: 60px 0;
}
.avepdf .header-item-wrapper {
  position: relative;
}
.avepdf .header-item-wrapper .ave-tooltip,
.avepdf .header-item-wrapper .ave-tooltip-triangle {
  border: 2px solid rgba(102, 110, 132, 0.2);
}
.avepdf .header-tooltip-wrapper {
  left: 8px;
  top: 180%;
}
.avepdf .header-tooltip-wrapper .ave-tooltip {
  padding: 15px;
  text-align: center;
}
.avepdf .header-tooltip-wrapper .ave-tooltip-triangle {
  height: 15px;
  width: 15px;
  top: -9px;
  -ms-transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}
.avepdf .input-number-tooltip-wrapper.active {
  opacity: 1 !important;
  visibility: visible;
}
.avepdf .embed-button-wrapper:hover,
.avepdf .embed-button-wrapper:focus {
  background-color: #1f1f1f;
}
.avepdf .embed-button-wrapper:hover .share-button-label,
.avepdf .embed-button-wrapper:focus .share-button-label {
  color: #fff;
}
.avepdf .share-button-wrapper {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.avepdf .share-button-wrapper .share-button-tooltip-wrapper {
  bottom: 125%;
  left: 0;
}
.avepdf .share-button-wrapper .share-button-tooltip-wrapper .ave-tooltip-triangle {
  height: 7px;
  width: 7px;
  bottom: -4px;
  left: 10px;
}
.avepdf .share-button .share-button-icon-svg path {
  fill: #1f1f1f;
  -webkit-transition: none !important;
  transition: none !important;
}
.avepdf .element-with-tooltip:hover .share-button-tooltip-wrapper {
  transition: visibility 0.6s, opacity 0.6s linear;
  -webkit-transition: visibility 0.6s, opacity 0.6s linear;
  transition-delay: 0.6s;
}
.avepdf .share-socials {
  position: absolute;
  border: 1px solid #dce6f5;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  min-width: 200px;
  bottom: 125%;
  left: 0;
}
.avepdf .share-socials-triangle {
  display: block;
  position: absolute;
  height: 7px;
  width: 7px;
  border-top: none !important;
  border-left: none !important;
  background: #fff;
  bottom: -4px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 10px;
  border: 1px solid #dce6f5;
}
.avepdf .share-socials ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.avepdf .share-socials ul li {
  display: inline-block;
  margin-right: 15px;
}
.avepdf .share-socials ul li:first-child {
  margin-left: 7px;
}
.avepdf .share-socials ul li:last-child {
  margin-right: 0;
}
.avepdf .share-facebook svg path {
  fill: #4368b0;
}
.avepdf .share-twitter svg path {
  fill: #1ca1f1;
}
.avepdf .share-linkedin svg path {
  fill: #0077b5;
}
.avepdf .share-email svg path {
  fill: #385682;
}
.avepdf .share-social-icon-svg {
  width: 30px;
  cursor: pointer;
}
.avepdf .share-icon-bar {
  background: #1f1f1f;
  border-radius: 5px;
  border: 1px solid #787878;
}
.avepdf .share-icon-bar ul li {
  color: white;
  background: #1f1f1f;
}
.avepdf .share-icon-bar ul li:hover {
  background-color: #333333;
}
@media (max-width: 576px) {
  .avepdf .share-icon-bar {
    display: none;
  }
}
@keyframes zoominout {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}
.avepdf .share-icon-bar.share-pulse {
  animation: zoominout 0.5s infinite;
}
.avepdf .rename-file-modal .download-file-methods .rename-download-file {
  width: 100%;
  border-radius: 5px;
}
.avepdf .rename-file-modal .download-file-methods .rename-download-file .ant-input-group-wrapper {
  border-radius: 5px;
}
.avepdf .embed-modal {
  margin: 0 15px;
}
.avepdf .embed-modal .ant-modal {
  max-width: 720px;
}
.avepdf .embed-modal .ant-modal-content {
  padding: 50px;
  border-radius: 5px;
}
.avepdf .embed-modal .ant-modal-header {
  padding: 25px 0;
}
.avepdf .embed-modal .ant-modal-header p {
  margin: 0;
}
.avepdf .embed-modal .embed-copy-message {
  position: absolute;
  right: 15px;
  top: -86px;
}
.avepdf .embed-modal .embed-copy-message span {
  font-size: 0.825em;
  background: #fff;
  border: 1px solid rgba(102, 110, 132, 0.2);
  padding: 15px;
  border-radius: 5px 0 5px 0;
}
.avepdf .embed-modal .ant-modal-footer {
  padding: 25px 0;
}
.avepdf .embed-modal .ant-modal-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}
.avepdf .embed-modal .ant-modal-body {
  padding: 24px 0;
}
.avepdf .embed-modal .ant-modal-body p {
  font-size: 1.2em;
  margin-bottom: 0;
}
.avepdf .embed-modal .embed-script {
  resize: none;
  background-color: #fafbff;
  border: none;
  height: auto;
}
.avepdf .embed-modal .copy-embed-button,
.avepdf .embed-modal .copy-embed-button:hover,
.avepdf .embed-modal .copy-embed-button:focus {
  color: #fff;
  border: none;
  background-color: #1f1f1f;
  padding: 13px 20px;
  height: auto;
}
@media (max-width: 768px) {
  .avepdf .embed-modal .ant-modal-content {
    padding: 30px 15px;
  }
}
@media (max-width: 376px) {
  .avepdf .embed-modal .ant-modal {
    top: 15px;
  }
}
.avepdf .tool-item {
  text-align: center;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  padding: 10px 0;
}
.avepdf .coming-soon {
  display: block;
  position: absolute;
  top: -10px;
  right: -20px;
  text-transform: lowercase;
  background: #333333;
  color: #ffffff;
  padding: 2px 12px;
  border-radius: 5px;
  font-size: 12px;
  line-height: 20px;
}
.avepdf .tool-item-content {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  position: relative;
}
.avepdf .tool-item-icon {
  position: relative;
  line-height: 1;
}
.avepdf .tool-item-icon img {
  width: 60%;
  padding: 11% 0 6% 0;
}
.avepdf .tool-item-description {
  text-align: center;
}
.avepdf .tool-item-description span {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 0;
  line-height: 1.2;
}
.avepdf .tool-label {
  position: absolute;
  top: -15px;
  right: -10px;
  padding: 2px 12px;
  background-color: #232b43;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.avepdf .tool-label p {
  margin: 0;
  color: #ffffff;
  font-size: 0.675rem;
  font-weight: 500;
  text-transform: uppercase;
}
.avepdf .tool-header-container .tool-label p {
  color: #2c2c2c;
}
.avepdf .megamenu-list-category .beta-tool .tool-label p {
  color: #2c2c2c;
}
.avepdf .tools-info-title-wrapper {
  padding-bottom: 15px;
}
.avepdf .tools-info-title-wrapper .title-header {
  margin-bottom: 0;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
}
.avepdf .tools-box {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  padding-top: 90px;
  padding-bottom: 90px;
  position: relative;
  border: 1px dashed rgba(102, 110, 132, 0.8);
}
.avepdf .tools-box-embedded {
  padding-top: 48px;
  padding-bottom: 120px;
  position: relative;
}
.avepdf .embedded-power-by {
  display: inline-block;
  bottom: 20px;
  right: 20px;
  position: absolute;
  max-width: 180px;
}
.avepdf .embedded-power-by svg path {
  fill: #2c2c2c;
}
.avepdf #files {
  display: none;
  /* do not display the actual file upload button */
}
.avepdf #files-upload-action {
  display: none;
}
.avepdf .thumbnail-part {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0 15px 15px;
  padding: 45px 15px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 8px 24px 0 #f1f5fc;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}
.avepdf .thumbnail-part .eqbox-wrapper {
  width: auto;
}
@media (max-width: 768px) {
  .avepdf .thumbnail-part .eqbox-wrapper {
    width: 100%;
  }
}
.avepdf .pages-sortable {
  padding: 0;
}
.avepdf .thumbnail-part:not(.ui-sortable-helper) {
  top: 0px !important;
}
.avepdf .thumbnail-part:hover:not(.ui-sortable-helper) {
  /* background-color: #f2f8fc; */
  top: -5px !important;
  background-color: #f8f8f8;
}
.avepdf .thumbnail-title {
  max-width: 250px;
  font-size: 0.875em;
  font-weight: 500;
  margin: 1em auto 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #1f1f1f;
}
.avepdf .thumbnail-number {
  margin: 0 0 -20px;
  font-size: 0.875em;
  color: #1f1f1f;
}
.avepdf .unable-load-page-preview {
  display: flex;
  align-items: center;
}
.avepdf .unable-load-page-preview p {
  margin: 0;
}
.avepdf .thumbnail-image-wrapper,
.avepdf .unable-load-page-preview {
  height: 256px;
  width: 256px;
}
.avepdf .thumbnail-empty-page {
  position: relative;
}
.avepdf .thumbnail-empty-page::before {
  background-color: #ffffff;
  content: " ";
  height: 256px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 180px;
  z-index: -1;
}
.avepdf .thumbnail-image-wrapper canvas,
.avepdf .unable-load-page-preview {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.avepdf .thumbnail-image-wrapper img {
  border: 1px solid #dfe6ec;
}
.avepdf #file-preview-pdf {
  text-align: center;
  padding: 0;
}
.avepdf .thumbnail-icons-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  text-align: end;
  display: block;
  width: 100%;
  padding: 4px 6px !important;
}
.avepdf .thumbnail-icons-wrapper svg {
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
}
.avepdf .thumbnail-icons-wrapper .thumbnail-close svg path {
  fill: #2c2c2c;
}
.avepdf .thumbnail-icons-wrapper .ant-btn {
  padding: 4px 6px !important;
  min-width: auto;
}
.avepdf .thumbnail-icons-wrapper .btn {
  color: #1f1f1f;
  padding: 4px 6px !important;
  min-width: auto;
}
.avepdf .thumbnail-icons-wrapper .btn:hover {
  background-color: transparent;
}
.avepdf .extract-pdf-pages .thumbnail-icons-wrapper svg,
.avepdf .split-pdf .thumbnail-icons-wrapper svg {
  background: #d0e0ea !important;
  border-radius: 5px;
}
.avepdf .thumbnail-close {
  color: #354063;
  background-color: transparent;
  position: absolute;
  right: -30px;
  top: 4px;
  padding: 4px 6px !important;
  min-width: auto;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  opacity: 0;
}
.avepdf .thumbnail-close img {
  width: 16px;
  height: 16px;
}
.avepdf .thumbnail-close:hover,
.avepdf .thumbnail-close:focus {
  background-color: #1f1f1f;
}
.avepdf .thumbnail-select {
  cursor: pointer;
  font-size: 1.4em;
  color: #d0e0ea !important;
  background-color: transparent;
  min-width: auto;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
}
.avepdf .thumbnail-select svg path {
  fill: #d0e0ea;
}
.avepdf [id^='thumbnail-select-'] svg path {
  fill: inherit !important;
}
.avepdf .thumbnail-part:hover .thumbnail-close {
  right: 4px;
  opacity: 1;
}
.avepdf .thumbnail-rotation {
  position: absolute;
  top: 5px;
  width: 77px;
  left: 95px;
  border: 1px solid #dfe6ee;
  border-radius: 5px;
  text-align: center;
}
.avepdf .thumbnail-rotation.invalid {
  color: red;
}
.avepdf .extract-pdf-pages-options i {
  width: 40px;
  height: 40px;
  font-size: 40px;
  margin-right: 15px;
  color: #1f1f1f;
  cursor: pointer;
}
.avepdf .extract-pdf-pages-options i:last-child {
  margin-right: 0;
}
.avepdf .split-instructions,
.avepdf .split-instructions-mobile {
  margin-bottom: 20px;
}
.avepdf .split-instructions-mobile {
  display: none;
}
.avepdf .split-label {
  color: #ffffff;
}
.avepdf .split-list-elem {
  margin: 0 5px;
  margin-bottom: 5px;
}
.avepdf .ui-selectable-helper {
  position: absolute;
  z-index: 100;
  border: 1px dotted;
}
.avepdf #splits {
  margin-bottom: 0;
}
.avepdf .selectable-preview.split-selectable-preview {
  padding-top: 35px;
}
.avepdf .selectable-preview .ui-selecting {
  background: #f5f6f7;
}
.avepdf .selectable-preview .ui-selected-custom {
  background: #f8f8f8;
}
.avepdf .split-close {
  right: 0px;
  width: 24px;
  color: #354063;
  background-color: transparent;
  position: absolute;
  top: 2px;
  padding: 4px 6px !important;
  min-width: auto;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.avepdf .split-close i.material-icons {
  font-size: inherit;
}
.avepdf .split-add {
  background: #1d7c76;
  border: none;
  padding: 6px 10px;
  color: #ffffff;
  border-radius: 5px;
  height: 38px;
  width: 38px;
}
.avepdf .split-add img {
  width: 16px;
}
.avepdf .split-add img path {
  fill: #fff;
}
.avepdf .split-add .add-icon-svg svg {
  margin-bottom: 5px;
}
.avepdf .split-add:hover,
.avepdf .split-add:focus {
  opacity: 0.75;
}
.avepdf .split-choose-mode {
  width: 45%;
  margin: 0 auto;
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #1f1f1f;
}
.avepdf .split-mode {
  width: 45%;
  cursor: pointer;
  background: #fff;
  border: 1px solid rgba(102, 110, 132, 0.2);
  border-radius: 5px;
  padding: 25px 20px 10px;
}
.avepdf .split-mode-icon-on-choose {
  width: 60%;
  display: inline-block;
}
.avepdf .split-mode-description {
  padding-top: 20px;
}
.avepdf .split-mode-title {
  font-weight: bold;
  font-size: 18px;
  margin: 0;
}
.avepdf .split-mode-subtitle {
  margin: 0;
  font-size: 13px;
  line-height: 16px;
}
.avepdf .split-simple-mode-wrapper {
  width: 100%;
  margin: 0 auto;
}
.avepdf .split-simple-mode-icon,
.avepdf .split-advanced-mode-icon {
  max-width: 130px;
  display: inline-block;
  width: 100%;
}
.avepdf .split-simple-mode-description,
.avepdf .split-advanced-mode-description {
  margin-top: 30px;
  color: #1f1f1f;
}
.avepdf .split-simple-mode-title,
.avepdf .split-advanced-mode-title {
  font-weight: bold;
  font-size: 30px;
}
.avepdf .split-simple-mode-select {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  padding: 25px 15px;
  background-color: #fff;
  border: 1px solid rgba(102, 110, 132, 0.2);
  border-radius: 5px;
}
.avepdf .split-simple-mode-select-label {
  width: 50%;
  font-size: 13px;
  background-color: #1f1f1f;
  color: #fff;
  border: 1px solid rgba(102, 110, 132, 0.4);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 5px;
}
.avepdf .split-simple-mode-input {
  width: 50%;
  outline: 0;
  border: 1px solid rgba(102, 110, 132, 0.2);
  border-left: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  text-align: center;
}
.avepdf .windows-os-required {
  font-size: 24px;
  font-weight: bold;
}
.avepdf .text-toolbar span {
  margin: 0 1px;
  padding: 5px;
  border-radius: 5px;
}
.avepdf .text-toolbar span.selected {
  background-color: #1f1f1f;
  color: #fff;
}
.paging-text-options.avepdf .text-toolbar {
  border: 2px solid blue;
}
.avepdf .font-family-label {
  display: block;
  margin: 0 5px 10px;
}
.avepdf .font-families.ant-dropdown-trigger i.anticon.anticon-down {
  display: inline-flex;
  padding-left: 0;
}
.avepdf .font-size-input {
  width: 69px;
  text-align: center;
  height: 44px !important;
}
.avepdf .font-size-input.invalid,
.avepdf .font-size-input.invalid:hover,
.avepdf .font-size-input.invalid:focus,
.avepdf .resize-input-w.invalid input,
.avepdf .resize-input-w.invalid:hover input,
.avepdf .resize-input-w.invalid:focus input,
.avepdf .resize-input-h.invalid input,
.avepdf .resize-input-h.invalid:hover input,
.avepdf .resize-input-h.invalid:focus input {
  border: 1px solid red !important;
}
.avepdf .margins-icon-wrapper {
  position: relative;
  width: 450px;
  margin: 35px auto;
}
.avepdf .margins-icon-wrapper .ant-input-number > .ant-input-number-handler-wrap {
  display: none;
}
.avepdf .margins-icon-wrapper .ant-input-number .ant-input-number-input {
  text-align: center;
}
.avepdf .margins-icon {
  max-width: 300px;
  margin: 0 auto;
}
.avepdf .margin-icon-value {
  position: absolute;
  display: inline-block;
  border: 1px solid #dde5ee;
  border-radius: 5px;
  text-align: center;
}
.avepdf .margin-icon-value.ant-input {
  padding: 5px 1px;
  height: auto;
}
.avepdf .margin-icon-value.margin-top {
  width: 70px;
  top: -30px;
  left: 190px;
}
.avepdf .margin-icon-value.margin-bottom {
  width: 70px;
  bottom: -30px;
  left: 190px;
}
.avepdf .margin-icon-value.margin-left {
  left: 45px;
  top: 50%;
  margin-top: -15px;
  width: 70px;
}
.avepdf .margin-icon-value.margin-right {
  right: 45px;
  top: 50%;
  margin-top: -15px;
  width: 70px;
}
.avepdf .pdf-to-jpg-quality {
  margin: 0;
  display: inline-block;
  width: 75%;
}
.avepdf .paging-pdf .position-icon-wrapper {
  position: relative;
  max-width: 230px;
  margin: 0 auto;
}
.avepdf .paging-pdf .ant-select.show {
  display: block !important;
}
.avepdf .paging-pdf .ant-select.hide {
  display: none !important;
}
.avepdf .paging-pdf .validation-errorinput {
  border-color: red !important;
}
.avepdf .paging-pdf .validation-error.ant-select-multiple .ant-select-selector {
  border-color: red !important;
}
.avepdf .paging-pdf .position-icon-value {
  position: absolute;
  margin: 0;
  width: 16px;
  height: 16px;
}
.avepdf .paging-pdf .position-icon-value.position-top-left {
  top: 0;
  left: 0;
}
.avepdf .paging-pdf .position-icon-value.position-top-center {
  top: -10px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.avepdf .paging-pdf .position-icon-value.position-top-right {
  top: 0;
  right: 0;
}
.avepdf .paging-pdf .position-icon-value.position-center-left {
  left: 0;
}
.avepdf .paging-pdf .position-icon-value.position-center-right {
  right: 0;
}
.avepdf .paging-pdf .position-icon-value.position-bottom-left {
  bottom: 0;
  left: 0;
}
.avepdf .paging-pdf .position-icon-value.position-bottom-right {
  bottom: 0;
  right: 0;
}
.avepdf .paging-pdf .position-icon-value.position-bottom-center {
  bottom: -10px;
  right: 0;
  left: 0;
  margin: 0 auto;
}
.avepdf .footer .copyright {
  color: #1f1f1f;
}
.avepdf .menu {
  font-size: 14px;
  font-weight: 600;
  color: #1f1f1f;
}
.avepdf .menu-item {
  margin-right: 48px;
  line-height: 36px;
  vertical-align: bottom;
  cursor: pointer;
}
.avepdf .ave-footer {
  margin-top: 60px;
}
.avepdf .ave-footer .ave-footer-top {
  background-color: #131313;
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fff;
}
.avepdf .ave-footer .ave-footer-top a {
  color: #fff;
}
.avepdf .ave-footer .ave-footer-top .footer-top-menu {
  padding-top: 15px;
  padding-bottom: 15px;
}
.avepdf .ave-footer .ave-footer-top .footer-top-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}
.avepdf .ave-footer .ave-footer-top .footer-top-menu ul li {
  display: inline-block;
  margin-right: 30px;
  cursor: pointer;
}
.avepdf .ave-footer .ave-footer-top .footer-top-menu ul li .pre-menu-icon {
  width: 20px;
  margin-right: 15px;
  display: inline-block;
}
.avepdf .ave-footer .ave-footer-top .footer-top-menu ul li .pre-menu-icon svg path {
  fill: #fff;
}
.avepdf .ave-footer .ave-footer-top .footer-top-menu .menu-item-dropdown {
  position: absolute;
  left: 0;
  bottom: 115%;
  border: 1px solid #474747;
  background-color: #1f1f1f;
  box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 5px;
}
.avepdf .ave-footer .ave-footer-top .footer-top-menu .menu-item-dropdown li {
  margin: 0;
  padding: 10px 15px;
  display: block;
}
.avepdf .ave-footer .ave-footer-top .footer-top-menu .menu-item-dropdown li:hover,
.avepdf .ave-footer .ave-footer-top .footer-top-menu .menu-item-dropdown li:focus {
  background-color: #2e2e2e;
}
.avepdf .ave-footer .ave-footer-top .footer-top-menu .menu-item-dropdown li .dev-icon {
  margin-right: 10px;
}
.avepdf .ave-footer .ave-footer-top.new-footer-top {
  background-color: transparent;
  border-top: 1px solid rgba(102, 110, 132, 0.2);
  color: #2c2c2c;
}
.avepdf .ave-footer .ave-footer-top.new-footer-top .footer-title {
  color: #1f1f1f;
  font-weight: 600;
  margin-bottom: 2rem;
}
.avepdf .ave-footer .ave-footer-top.new-footer-top a {
  color: #2c2c2c;
}
.avepdf .ave-footer .ave-footer-top.new-footer-top a:hover,
.avepdf .ave-footer .ave-footer-top.new-footer-top a:focus {
  color: #1d7c76;
}
.avepdf .ave-footer .ave-footer-top.new-footer-top p {
  font-size: 14px;
}
.avepdf .ave-footer .ave-footer-top.new-footer-top .footer-list {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 14px;
  margin-bottom: 2rem;
}
.avepdf .ave-footer .ave-footer-top.new-footer-top .footer-list li {
  line-height: 2;
  padding-bottom: 5px;
  padding-right: 5px;
}
.avepdf .ave-footer .ave-footer-top.new-footer-top .footer-list li.active-lang a {
  font-weight: 600;
  color: #1d7c76;
}
.avepdf .ave-footer .ave-footer-top.new-footer-top .dev-icon {
  margin-right: 5px;
}
.avepdf .ave-footer .ave-footer-top.new-footer-top .theme-switch {
  margin: 0 10px 2rem 0;
  padding: 0;
}
.avepdf .ave-footer .ave-footer-top.new-footer-top .theme-switch .btn_background {
  border: 2px solid #1f1f1f;
}
.avepdf .ave-footer .ave-footer-top.new-footer-top .theme-switch .btn_background .btn_face {
  background: #1f1f1f;
}
.avepdf .ave-footer .ave-footer-top.new-footer-top .powered-by-container {
  max-width: 240px;
}
.avepdf .ave-footer .ave-footer-bottom {
  background-color: #fff;
  font-size: 13px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.avepdf .ave-footer .ave-footer-bottom a {
  text-decoration: underline;
}
.avepdf .ave-footer .ave-footer-bottom .footer-bottom-menu {
  padding-top: 15px;
  padding-bottom: 15px;
}
.avepdf .ave-footer .ave-footer-bottom .footer-bottom-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.avepdf .ave-footer .ave-footer-bottom .footer-bottom-menu ul li {
  display: inline-block;
  margin-right: 15px;
}
.avepdf .ave-footer .ave-footer-bottom .powered-by-container {
  padding: 15px 0;
}
.avepdf .ave-footer .ave-footer-bottom .powered-by-container svg {
  max-width: 210px !important;
}
.avepdf .ave-footer .theme-switch {
  display: inline-block;
  padding: 15px 0;
  margin: 0 0 0 15px;
}
.avepdf .ave-footer .theme-switch .btn_background {
  width: 45px;
  height: 26px;
  border-radius: 50px;
  background: transparent;
  border: 2px solid #fff;
  cursor: pointer;
}
.avepdf .ave-footer .theme-switch .btn_background .btn_face {
  background: #fff;
}
.avepdf .ave-footer .theme-switch .btn_background .btn_face .theme-switch-icon {
  width: 11px;
  height: 11px;
  vertical-align: text-top;
}
.avepdf .ave-tooltip-wrapper {
  transition: visibility 0.2s, opacity 0.2s linear;
  -webkit-transition: visibility 0.2s, opacity 0.2s linear;
  min-width: auto;
}
@media (max-width: 575.98px) {
  .avepdf .ave-tooltip-wrapper {
    min-width: 180px;
  }
}
.avepdf .element-with-tooltip:hover .ave-tooltip-wrapper {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.4s, opacity 0.4s linear;
  -webkit-transition: visibility 0.4s, opacity 0.4s linear;
  transition-delay: 0.4s;
}
.avepdf .ave-tooltip {
  display: block;
  padding: 15px;
  font-size: 13px;
  line-height: 16px;
  border-radius: 5px;
  background: #fff;
  text-align: start;
  position: relative;
  border: 1px solid rgba(102, 110, 132, 0.2);
}
@media (max-width: 575.98px) {
  .avepdf .ave-tooltip {
    max-width: 120px;
  }
}
.avepdf .ave-tooltip-triangle {
  display: block;
  position: absolute;
  border-top: none !important;
  border-left: none !important;
  background: #fff;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 1px solid rgba(102, 110, 132, 0.2);
  width: 10px;
  height: 10px;
  bottom: -5px;
}
.avepdf .ave-tooltip-layout {
  display: flex;
  flex-direction: column;
  width: 16em;
}
@media (max-width: 991.98px) {
  .avepdf .ave-tooltip-layout {
    width: 12em;
  }
}
@media (max-width: 575.98px) {
  .avepdf .ave-tooltip-layout {
    width: 7em;
  }
}
.avepdf .ave-tooltip-layout span {
  white-space: pre-line;
}
.avepdf .ave-tooltip-title {
  font-weight: bold;
}
.avepdf .ie-user-screen {
  width: 100%;
  height: 100%;
  text-align: center;
  display: table;
}
.avepdf .ie-user-screen .ie-error {
  display: table-cell;
  vertical-align: middle;
}
.avepdf .ie-user-screen .ie-error .ie-error p {
  font-size: 26px;
}
.avepdf button:focus {
  outline: none;
}
.avepdf .ant-btn svg path {
  fill: #1f1f1f;
}
.avepdf .ant-btn-group .ant-btn {
  border-radius: 0;
}
.avepdf .ant-btn-group .ant-btn:first-child:not(:last-child) {
  border-radius: 5px 0 0 5px;
}
.avepdf .ant-btn-group .ant-btn:last-child:not(:first-child) {
  border-radius: 0 5px 5px 0;
}
.avepdf .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.avepdf .ant-btn-group .ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: rgba(102, 110, 132, 0.2);
}
.avepdf .ant-btn-group .ant-btn:only-child {
  border-radius: 5px;
}
.avepdf .ant-btn-group.ant-btn-pdfa .ant-btn {
  border-radius: 5px !important;
  width: 100% !important;
  margin: 6px !important;
  padding: 10px 15px !important;
}
@media (max-width: 991.98px) {
  .avepdf .ant-btn-group.ant-btn-group-3 .ant-btn {
    width: 100%;
    margin: 2px;
    padding: 10px 15px;
    border-radius: 5px !important;
  }
}
@media (max-width: 767.98px) {
  .avepdf .ant-btn-group.ant-btn-group-3 {
    display: block;
  }
  .avepdf .ant-btn-group.ant-btn-group-3 .ant-btn {
    width: 100%;
    margin: 2px;
  }
}
@media (max-width: 1199.98px) {
  .avepdf .ant-btn-group.ant-btn-group-4 .ant-btn {
    width: 45%;
    margin: 10px;
    border-radius: 5px !important;
    border-color: rgba(102, 110, 132, 0.2) !important;
  }
}
@media (max-width: 991.98px) {
  .avepdf .ant-btn-group.ant-btn-group-4 {
    display: block;
  }
  .avepdf .ant-btn-group.ant-btn-group-4 .ant-btn {
    display: block;
    width: 100%;
    margin: 2px 0;
  }
}
.avepdf .ant-btn-group.ant-btn-group-icons {
  display: inline-block;
}
@media (max-width: 767.98px) {
  .avepdf .ant-btn-group.ant-btn-group-icons .ant-btn {
    margin: 4px 2px;
    border-radius: 5px !important;
    border-color: #1f1f1f !important;
  }
}
@media (max-width: 575.98px) {
  .avepdf .ant-btn-group.ant-btn-group-icons .ant-btn {
    margin: 6px 8px;
  }
}
@media (max-width: 575.98px) {
  .avepdf .ant-btn-group .ant-btn {
    white-space: normal;
  }
}
.avepdf .btn {
  color: #1f1f1f;
  outline: none;
}
.avepdf .btn:focus {
  box-shadow: none;
}
.avepdf .btn .btn-white {
  background: #fff;
  border-color: #1f1f1f;
}
.avepdf .white-action-button {
  border-width: 1px;
  border-style: solid;
  border-color: #1f1f1f;
}
.avepdf .white-action-button .material-icons {
  padding-left: 15px;
}
.avepdf .download-file-methods-wrapper,
.avepdf .upload-file-methods {
  position: relative;
  list-style-type: none;
  margin: 0 auto;
}
.avepdf .download-file-methods-wrapper .rename-download-file,
.avepdf .upload-file-methods .rename-download-file {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}
.avepdf .download-file-methods-wrapper .rename-download-file .ant-input-group-wrapper,
.avepdf .upload-file-methods .rename-download-file .ant-input-group-wrapper {
  width: 100%;
}
.avepdf .download-file-methods-wrapper .rename-download-file .ant-input-group-wrapper .ant-input-affix-wrapper,
.avepdf .upload-file-methods .rename-download-file .ant-input-group-wrapper .ant-input-affix-wrapper {
  padding-right: 16px;
  border: none;
  border-radius: 5px 0 0 5px;
  border-color: rgba(102, 110, 132, 0.2);
}
.avepdf .download-file-methods-wrapper .rename-download-file .ant-input-group-wrapper .ant-input-affix-wrapper:hover,
.avepdf .upload-file-methods .rename-download-file .ant-input-group-wrapper .ant-input-affix-wrapper:hover {
  border-color: rgba(102, 110, 132, 0.4);
}
.avepdf .download-file-methods-wrapper .rename-download-file .ant-input-group-wrapper .ant-input-group-addon,
.avepdf .upload-file-methods .rename-download-file .ant-input-group-wrapper .ant-input-group-addon {
  border: none;
  border-radius: 5px 5px 0 0;
  background-color: transparent;
}
.avepdf .download-file-methods-wrapper .rename-download-file .ant-input-group-wrapper .ant-input-group-addon:last-child,
.avepdf .upload-file-methods .rename-download-file .ant-input-group-wrapper .ant-input-group-addon:last-child {
  padding: 0;
}
.avepdf .download-file-methods-wrapper .rename-download-file .ant-input-group-wrapper .ant-input,
.avepdf .upload-file-methods .rename-download-file .ant-input-group-wrapper .ant-input {
  height: 44px;
  font-size: 1em;
}
.avepdf .download-file-methods-wrapper .rename-download-file .ant-btn,
.avepdf .upload-file-methods .rename-download-file .ant-btn {
  min-width: 46px;
  border-radius: 0;
  border-width: 0;
}
.avepdf .download-file-methods-wrapper .rename-download-file .ant-btn:last-child,
.avepdf .upload-file-methods .rename-download-file .ant-btn:last-child {
  border-radius: 0 5px 5px 0;
}
.avepdf .download-file-methods-wrapper .rename-download-file .rename-on .rename-cancel-btn,
.avepdf .upload-file-methods .rename-download-file .rename-on .rename-cancel-btn {
  background-color: #edf2f6;
}
.avepdf .download-file-methods-wrapper .rename-download-file .rename-off .ant-btn,
.avepdf .upload-file-methods .rename-download-file .rename-off .ant-btn {
  background: none;
}
.avepdf .download-file-methods-wrapper .rename-download-file .rename-off span,
.avepdf .upload-file-methods .rename-download-file .rename-off span {
  line-height: 1;
  padding: 19px 11px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
}
@media (max-width: 767.98px) {
  .avepdf .download-file-methods-wrapper .rename-download-file .output-file-name,
  .avepdf .upload-file-methods .rename-download-file .output-file-name {
    white-space: normal !important;
  }
}
.avepdf .rename-btn {
  border: 1px solid transparent;
  border-radius: 5px !important;
  background-color: transparent;
}
.avepdf .rename-download-file .output-file-name {
  text-decoration: underline;
}
.avepdf .upload-file-methods {
  margin: 15px auto 0;
}
.avepdf .download-file-methods-wrapper {
  display: inline-block;
}
.avepdf .download-file-methods {
  margin-top: 30px;
  display: block;
}
.avepdf .upload-file-methods button.upload-file-download,
.avepdf .download-file-methods button.upload-file-download,
.avepdf .upload-file-methods button:hover.upload-file-download,
.avepdf .download-file-methods button:hover.upload-file-download,
.avepdf .upload-file-methods button:focus.upload-file-download,
.avepdf .download-file-methods button:focus.upload-file-download {
  top: 3px;
}
@media (max-width: 992px) {
  .avepdf .upload-file-methods button.upload-file-download,
  .avepdf .download-file-methods button.upload-file-download,
  .avepdf .upload-file-methods button:hover.upload-file-download,
  .avepdf .download-file-methods button:hover.upload-file-download,
  .avepdf .upload-file-methods button:focus.upload-file-download,
  .avepdf .download-file-methods button:focus.upload-file-download {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.avepdf .upload-file-methods button.upload-file-download .cloud-upload-icon,
.avepdf .download-file-methods button.upload-file-download .cloud-upload-icon,
.avepdf .upload-file-methods button:hover.upload-file-download .cloud-upload-icon,
.avepdf .download-file-methods button:hover.upload-file-download .cloud-upload-icon,
.avepdf .upload-file-methods button:focus.upload-file-download .cloud-upload-icon,
.avepdf .download-file-methods button:focus.upload-file-download .cloud-upload-icon {
  width: 32px;
  height: 32px;
}
.avepdf .upload-file-methods button.upload-file-download .cloud-upload-icon svg,
.avepdf .download-file-methods button.upload-file-download .cloud-upload-icon svg,
.avepdf .upload-file-methods button:hover.upload-file-download .cloud-upload-icon svg,
.avepdf .download-file-methods button:hover.upload-file-download .cloud-upload-icon svg,
.avepdf .upload-file-methods button:focus.upload-file-download .cloud-upload-icon svg,
.avepdf .download-file-methods button:focus.upload-file-download .cloud-upload-icon svg {
  width: 32px;
  height: 32px;
}
.avepdf .upload-file-methods button.upload-file-download p,
.avepdf .download-file-methods button.upload-file-download p,
.avepdf .upload-file-methods button:hover.upload-file-download p,
.avepdf .download-file-methods button:hover.upload-file-download p,
.avepdf .upload-file-methods button:focus.upload-file-download p,
.avepdf .download-file-methods button:focus.upload-file-download p {
  display: inline-block;
  margin: 0;
  font-size: 14px;
  line-height: 1;
  vertical-align: bottom;
}
.avepdf .upload-file-methods button.upload-file-download p .file-name,
.avepdf .download-file-methods button.upload-file-download p .file-name,
.avepdf .upload-file-methods button:hover.upload-file-download p .file-name,
.avepdf .download-file-methods button:hover.upload-file-download p .file-name,
.avepdf .upload-file-methods button:focus.upload-file-download p .file-name,
.avepdf .download-file-methods button:focus.upload-file-download p .file-name {
  display: block;
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 2px;
}
.avepdf .upload-file-methods button.upload-file-download .download-icon-svg,
.avepdf .download-file-methods button.upload-file-download .download-icon-svg,
.avepdf .upload-file-methods button:hover.upload-file-download .download-icon-svg,
.avepdf .download-file-methods button:hover.upload-file-download .download-icon-svg,
.avepdf .upload-file-methods button:focus.upload-file-download .download-icon-svg,
.avepdf .download-file-methods button:focus.upload-file-download .download-icon-svg {
  width: 32px !important;
}
.avepdf .download-file-methods li:first-child button .cloud-upload-icon,
.avepdf .upload-file-methods li:first-child button .cloud-upload-icon {
  margin-right: 10px;
}
.avepdf .download-file-methods li:last-child button {
  border-top-left-radius: 0;
  border-bottom-right-radius: 5px;
}
.avepdf .upload-file-methods li:last-child button {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.avepdf .upload-file-methods-small {
  display: none !important;
}
.avepdf .upload-file-methods-small .upload-more {
  margin: 0;
}
.avepdf .upload-file-methods-small .upload-more-methods {
  width: 100%;
}
.avepdf .upload-file-methods-small .upload-more-methods li .ant-btn {
  border: 1px solid;
  border-top: none;
  padding: 10px 15px;
  margin-bottom: 0;
}
.avepdf .upload-file-methods-small .upload-more-methods li .ant-btn.ant-btn-primary {
  color: #1f1f1f;
  background-color: #fff;
  border-color: #1f1f1f;
}
.avepdf .upload-file-methods-small .upload-more-methods li:first-child .ant-btn {
  border-top: 1px solid;
}
@media (max-width: 767.98px) {
  .avepdf .upload-url-input {
    max-width: 100%;
    min-width: 100%;
  }
}
.avepdf .upload-url-input .ant-input-group-addon {
  border: none;
  border-radius: 5px;
  background: none;
}
@media (max-width: 767.98px) {
  .avepdf .upload-url-input .ant-input-group-addon {
    border-radius: 0;
    display: block;
    width: 100%;
  }
}
.avepdf .upload-url-input .ant-input-group-addon:first-child {
  background: #fff;
  border: 1px solid rgba(102, 110, 132, 0.2);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
@media (max-width: 767.98px) {
  .avepdf .upload-url-input .ant-input-group-addon:first-child {
    border-radius: 0 !important;
  }
}
.avepdf .upload-url-input .ant-input {
  font-size: 1em;
  height: 57px;
}
@media (max-width: 767.98px) {
  .avepdf .upload-url-input .ant-input {
    float: none;
    display: block;
    width: 100%;
  }
}
.avepdf .upload-url-input .ant-input:hover {
  border-color: rgba(102, 110, 132, 0.2);
}
.avepdf .upload-url-input .ant-input::placeholder {
  font-size: 12px;
}
.avepdf .upload-url-input .url-input-icon {
  padding: 0 10px;
}
@media (max-width: 767.98px) {
  .avepdf .upload-url-input .url-input-icon {
    padding: 10px;
  }
}
.avepdf .upload-url-input .ant-input-group-addon {
  padding: 0;
}
.avepdf .upload-url-input .ant-btn {
  border-radius: 0;
  padding: 17px 15px;
  border-color: rgba(102, 110, 132, 0.2) !important;
}
@media (max-width: 767.98px) {
  .avepdf .upload-url-input .ant-btn {
    display: inline-block;
    width: 50%;
    border-radius: 0 !important;
  }
}
.avepdf .upload-url-input .ant-btn:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.avepdf .upload-url-input .ant-btn.url-cancel-btn {
  background-color: #edf2f6;
  border-color: rgba(102, 110, 132, 0.2);
}
.avepdf .upload-url-input.uri-input-invalid .ant-input-group-addon:first-child {
  border-color: #c00;
}
.avepdf .upload-url-input.uri-input-invalid .url-input-icon svg path {
  fill: #c00;
}
.avepdf .upload-url-input.uri-input-invalid .ant-input {
  color: #c00;
  border-color: #c00;
}
.avepdf .file-upload-option-wrapper .choose-file-btn {
  color: #1d7c76;
}
.avepdf .file-upload-option-wrapper .choose-file-btn svg path {
  fill: #1d7c76;
}
.avepdf .file-upload-option-wrapper .file-upload-option .ant-btn:hover,
.avepdf .file-upload-option-wrapper .file-upload-option .ant-btn:focus {
  background-color: #1d7c76;
  color: #fff;
}
.avepdf .file-upload-option-wrapper .file-upload-option .ant-btn:hover svg path,
.avepdf .file-upload-option-wrapper .file-upload-option .ant-btn:focus svg path {
  fill: #fff;
}
.avepdf .download-file-methods .download-file-btn {
  color: #1d7c76;
}
.avepdf .download-file-methods .download-file-btn svg path {
  fill: #1d7c76;
}
.avepdf .download-file-methods .file-download-option .ant-btn:hover,
.avepdf .download-file-methods .file-download-option .ant-btn:focus {
  background-color: #1d7c76;
  color: #fff;
}
.avepdf .download-file-methods .file-download-option .ant-btn:hover svg path,
.avepdf .download-file-methods .file-download-option .ant-btn:focus svg path {
  fill: #fff;
}
.avepdf .ant-tabs {
  color: #2c2c2c;
}
.avepdf .ant-tabs.avepdf-account-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background: rgba(102, 110, 132, 0.1);
}
.avepdf .ant-tabs.avepdf-account-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-size: 1.1em;
  color: #2c2c2c;
}
.avepdf .ant-tabs.avepdf-account-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 1.1em;
}
.avepdf .ant-tabs.avepdf-account-tabs .plan-features li {
  padding: 4px 0;
}
.avepdf .ant-tabs.avepdf-account-tabs .ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #1D7C76 !important;
}
.avepdf .ant-tabs-ink-bar {
  background: #2c2c2c;
}
.avepdf .ant-tabs-left > .ant-tabs-content-holder,
.avepdf .ant-tabs-left > div > .ant-tabs-content-holder {
  border-left: 1px solid rgba(102, 110, 132, 0.4);
}
.avepdf .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.avepdf .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  transition: none;
}
.avepdf .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.avepdf .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  transition: none;
}
.avepdf .avepdf-upload-tabs .ant-tabs-nav {
  margin: 0;
}
.avepdf .avepdf-upload-tabs .ant-tabs-nav .ant-tabs-nav-list {
  white-space: initial;
}
.avepdf .avepdf-upload-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
  border-color: #dee2e6;
  border-width: 1px;
  border-style: solid;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.avepdf .avepdf-upload-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab:first-child {
  margin-left: 0 !important;
}
.avepdf .avepdf-upload-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom-color: #fff;
}
.avepdf .avepdf-upload-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1f1f1f;
}
@media (max-width: 767.98px) {
  .avepdf .avepdf-upload-tabs .ant-tabs-nav .ant-tabs-nav-list {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
  .avepdf .avepdf-upload-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
    display: block;
    width: 100%;
    padding: 0.75rem 1.5rem;
    margin: 0 !important;
    border-radius: 0;
  }
  .avepdf .avepdf-upload-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab:first-child {
    border-radius: 5px 5px 0 0;
    margin-top: 0;
  }
  .avepdf .avepdf-upload-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab:nth-last-child(2) {
    border-radius: 0 0 5px 5px;
  }
  .avepdf .avepdf-upload-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff;
  }
}
.avepdf .avepdf-upload-tabs .ant-tabs-content-holder {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media (max-width: 767.98px) {
  .avepdf .avepdf-upload-tabs .ant-tabs-content-holder {
    border-width: 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
@media (max-width: 767.98px) {
  .avepdf .avepdf-upload-tabs .ant-tabs-content-holder .upload-url-input {
    border-radius: 0;
    display: block;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .avepdf .avepdf-upload-tabs .ant-tabs-content-holder .upload-url-input .ant-input-group-addon:first-child {
    border-radius: 5px 5px 0 0;
  }
}
@media (max-width: 767.98px) {
  .avepdf .avepdf-upload-tabs .ant-tabs-content-holder .upload-url-input .ant-input-group-addon .ant-btn {
    display: inline-block;
    width: 100%;
    border-radius: 0 0 5px 5px !important;
  }
}
.avepdf .avepdf-upload-tabs .ant-tabs-content-holder .upload-more-button-wrapper .upload-more-methods {
  position: relative;
  width: 100% !important;
  right: 0;
  left: 0;
}
.avepdf .avepdf-upload-tabs .ant-tabs-content-holder .upload-more-button-wrapper .upload-more-methods .ant-btn img {
  position: absolute;
  top: 50%;
  margin-top: -10px;
}
.avepdf .avepdf-upload-tabs .ant-tabs-content-holder .upload-more-button-wrapper .upload-more-methods .ant-btn.action-button {
  min-width: auto;
}
.avepdf .avepdf-upload-tabs .ant-tabs-content-holder .upload-more-button-wrapper .ant-btn.upload-more {
  white-space: normal;
}
.avepdf .avepdf-upload-tabs .ant-tabs-content-holder .upload-more-button-wrapper .ant-btn.upload-more span {
  margin: 0 5px;
}
@media (max-width: 767.98px) {
  .avepdf .avepdf-upload-tabs .ant-tabs-content-holder .upload-more-button-wrapper {
    padding: 0;
    margin: 0;
  }
}
.avepdf .avepdf-upload-tabs .ant-tabs-nav-operations {
  display: none !important;
}
@media (max-width: 991.98px) {
  .avepdf .avepdf-upload-tabs {
    margin: 30px 0;
  }
}
.avepdf .tool-powered-by {
  padding: 10px 0;
  font-size: 14px;
}
.avepdf .tool-powered-centered .tool-powered-by {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 10px;
  text-align: center;
  border: 2px solid rgba(102, 110, 132, 0.4);
  border-top: none;
}
.avepdf .tool-powered-centered #dragAndDropArea {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.avepdf .tool-powered-centered.tool-powered-strong .tool-powered-by {
  background-color: #1d7c76;
  border-color: #1d7c76;
  color: #C3D5D6;
  margin-top: -2px;
}
.avepdf .tool-powered-centered.tool-powered-strong .tool-powered-by a {
  color: #fff;
}
.avepdf .ant-input {
  background-image: none;
  border-radius: 5px;
  transition: none;
}
.avepdf .prepend-append-input {
  border-radius: 0 !important;
}
.avepdf .ant-input-group-wrapper .ant-input-wrapper .ant-input-group-addon {
  border-radius: 5px;
}
.avepdf .ant-input-group-wrapper .ant-input-wrapper .ant-input-group-addon:first-child {
  border-radius: 5px 0 0 5px;
}
.avepdf .ant-input-group-wrapper .ant-input-wrapper .ant-input-group-addon:last-child {
  border-radius: 0 5px 5px 0;
}
.avepdf .ant-input-number-handler {
  border-left: 1px solid transparent;
}
.avepdf .ant-input-number {
  height: 45px;
  border-radius: 5px;
}
.avepdf .ant-input-number span {
  padding: 0;
  display: block;
  margin: 0;
}
.avepdf .ant-input-number .ant-input-number-handler-up-inner {
  top: 25%;
}
.avepdf .ant-input-number .ant-input-number-handler-up:hover svg path,
.avepdf .ant-input-number .ant-input-number-handler-down:hover svg path {
  fill: #1f1f1f;
}
.avepdf .ant-input-number:hover {
  border-color: rgba(102, 110, 132, 0.4);
}
.avepdf .ant-input-number:focus,
.avepdf .ant-input-number.ant-input-number-focused {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(31, 31, 31, 0.2);
  border-color: rgba(102, 110, 132, 0.4);
}
.avepdf .ant-input-number .ant-input-number-handler-wrap {
  border-radius: 0 5px 5px 0;
}
.avepdf .ant-input-number .ant-input-number-input {
  height: 45px;
}
.avepdf .ant-input-number .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.avepdf .ant-input-number .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #1f1f1f;
}
.avepdf .ant-input-number-rtl .ant-input-number-handler {
  border-right: 1px solid transparent;
  border-left: 0;
}
.avepdf .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.avepdf .ant-checkbox:hover .ant-checkbox-inner,
.avepdf .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #1f1f1f;
}
.avepdf .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1f1f1f;
  border-color: #2c2c2c;
}
.avepdf .ant-checkbox-checked:after {
  border-color: #2c2c2c;
}
.avepdf :hover .ant-radio,
.avepdf .ant-radio:hover .ant-radio-inner,
.avepdf .ant-radio-input:focus + .ant-radio-inner {
  border-color: #1f1f1f;
}
.avepdf .ant-radio-checked .ant-radio-inner {
  border-color: #1f1f1f;
}
.avepdf .ant-radio-checked .ant-radio-inner:after {
  background-color: #1f1f1f;
}
.avepdf .ant-switch {
  background-color: #787878;
}
.avepdf .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: rgba(102, 110, 132, 0.2);
}
.avepdf .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: rgba(102, 110, 132, 0.2);
}
.avepdf .ant-radio-button-wrapper {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
}
.avepdf .ant-radio-button-wrapper:first-child {
  border-radius: 5px 0 0 5px;
}
.avepdf .ant-radio-button-wrapper:last-child {
  border-radius: 0 5px 5px 0;
}
@media (max-width: 768px) {
  .avepdf .ant-radio-button-wrapper {
    display: inline-block;
    border-radius: 5px !important;
    margin: 3px 3px 6px;
  }
}
.avepdf input:-webkit-autofill {
  box-shadow: inset 0 0 0px 9999px white !important;
}
.avepdf input,
.avepdf textarea,
.avepdf input[type="text"],
.avepdf input[type="email"],
.avepdf input[type="search"],
.avepdf input[type="password"] {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  outline: none;
  background-clip: padding-box;
}
.avepdf .form-control {
  color: #1f1f1f;
  border: 1px solid rgba(102, 110, 132, 0.2);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.avepdf .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: rgba(102, 110, 132, 0.4);
}
.avepdf ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6c6c6c;
  opacity: 1;
  /* Firefox */
}
.avepdf :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6c6c6c;
}
.avepdf ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #6c6c6c;
}
.avepdf .form-group {
  margin-bottom: 30px;
}
.avepdf .input-lg {
  height: 50px;
  font-size: 1em;
}
.avepdf .input-sm {
  height: 30px;
}
.avepdf .input-group-btn {
  font-size: 14px;
}
.avepdf .input-group > .form-control,
.avepdf .input-group > .input-group-addon,
.avepdf .input-group > .input-group-btn > .btn {
  padding: 0.375rem 0.9375rem;
  -webkit-border-radius: 5px;
  border-radius: 0 5px 5px 0;
}
.avepdf .input-group-lg > .form-control,
.avepdf .input-group-lg > .input-group-addon,
.avepdf .input-group-lg > .input-group-btn > .btn {
  height: 50px !important;
  font-size: 1em;
}
.avepdf .input-group-sm > .form-control,
.avepdf .input-group-sm > .input-group-addon,
.avepdf .input-group-sm > .input-group-btn > .btn {
  height: 30px;
  padding: 5px 25px;
}
.avepdf .form-group-lg .form-control {
  height: 60px;
}
.avepdf .input-group .form-control {
  z-index: inherit;
}
.avepdf .input-group:not(.has-validation) > .form-control:not(:last-child) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media (max-width: 767.98px) {
  .avepdf .remove-list-elem .input-group .input-group-append {
    display: block;
    margin-top: 10px;
    width: 100%;
  }
  .avepdf .remove-list-elem .input-group .input-group-append .input-group-text {
    border-radius: 5px;
  }
}
.avepdf .input-group-addon {
  background-color: #d4eafa;
  border-color: #d4eafa;
  color: #42a5f5;
}
.avepdf .input-group-addon.input-success {
  background-color: #f0fbf1;
  border-color: #f0fbf1;
  color: #81c784;
}
.avepdf .form-control[disabled],
.avepdf .form-control[readonly],
.avepdf fieldset[disabled] .form-control label {
  background-color: #f7fbff;
}
.avepdf .border-form .form-control {
  background: transparent;
}
.avepdf .border-form .dark-form .form-control {
  background: transparent;
  border: 1px solid #354063;
}
.avepdf .border-form .dark-form .form-control::-moz-placeholder {
  color: #354063;
}
.avepdf label {
  font-weight: 300;
}
@media (max-width: 992px) {
  .avepdf .form-inline .form-group {
    margin-bottom: 15px;
  }
}
.avepdf .input-group-text {
  border-color: rgba(102, 110, 132, 0.2);
  background-color: #f8f8f8;
  font-size: 0.875em;
  border-radius: 5px;
}
.avepdf .input-group-icon {
  position: relative;
}
.avepdf .input-group-icon .form-control {
  padding-left: 44px;
}
.avepdf .input-group-icon .input-icon {
  position: absolute;
  left: 12px;
  width: 20px;
  top: 50%;
  z-index: 100;
  height: 20px;
  margin-top: -12px;
}
.avepdf .font-styles {
  display: inline-block;
  background-color: #fff;
  margin: 0 0 0 5px;
  border: 1px solid rgba(102, 110, 132, 0.2);
  padding: 8px;
  border-radius: 5px;
}
@media (max-width: 767.98px) {
  .avepdf .font-styles {
    width: auto;
  }
}
.avepdf .text-alignment {
  display: inline-block;
  background-color: #fff;
  margin: 0 5px;
  border: 1px solid rgba(102, 110, 132, 0.2);
  padding: 8px;
  border-radius: 5px;
}
@media (max-width: 767.98px) {
  .avepdf .text-alignment {
    width: auto;
  }
}
.avepdf .ant-dropdown-menu {
  border-radius: 5px;
  padding: 0 !important;
}
.avepdf .ant-dropdown-menu li:first-child {
  border-radius: 5px 5px 0 0;
}
.avepdf .ant-dropdown-menu li:last-child {
  border-radius: 0 0 5px 5px;
}
.avepdf .ant-dropdown-menu.ant-dropdown-menu-light {
  padding: 0;
  border: 1px solid rgba(102, 110, 132, 0.2);
}
.avepdf .ant-dropdown-menu.ant-dropdown-menu-light .ant-dropdown-menu-item-selected,
.avepdf .ant-dropdown-menu.ant-dropdown-menu-light .ant-dropdown-menu-submenu-title-selected,
.avepdf .ant-dropdown-menu.ant-dropdown-menu-light .ant-dropdown-menu-item-selected > a,
.avepdf .ant-dropdown-menu.ant-dropdown-menu-light .ant-dropdown-menu-submenu-title-selected > a {
  color: #1f1f1f;
  background-color: rgba(102, 110, 132, 0.2);
}
.avepdf .language-overlay .ant-dropdown-menu {
  margin-top: 0;
  padding: 0;
}
.avepdf .ant-select.small-select {
  min-width: 160px;
}
.avepdf .ant-select.validation-error .ant-select-selector {
  border-color: #ff4d4f !important;
}
.avepdf .ant-dropdown-menu-item:hover,
.avepdf .ant-dropdown-menu-submenu-title:hover {
  background-color: #dedede;
}
.avepdf .ant-select-selection {
  background-color: #fff;
  border-color: rgba(102, 110, 132, 0.2);
}
.avepdf .ant-select-dropdown {
  border-color: rgba(102, 110, 132, 0.2);
  border-radius: 5px;
}
.avepdf .ant-select-multiple .ant-select-selector {
  padding: 10px;
}
.avepdf .ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: middle;
}
.avepdf .ant-select-arrow {
  line-height: 52px;
}
.avepdf .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 52px;
  border-radius: 5px;
}
.avepdf .ant-select-single .ant-select-selector .ant-select-selection-item,
.avepdf .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 52px;
}
.avepdf .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #1f1f1f;
}
.avepdf .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #1f1f1f;
  background-color: #ebebeb;
}
.avepdf .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #ebebeb;
}
.avepdf .ant-select-selection__rendered {
  line-height: 52px;
}
.avepdf .ant-select-dropdown {
  background-color: #f8f8f8;
}
.avepdf .ant-select-dropdown-menu {
  max-height: 300px;
}
.avepdf .ant-select-dropdown-menu-item:hover {
  background-color: #ebebeb;
}
.avepdf .ant-select-dropdown-menu-item-active {
  background-color: #ebebeb;
}
.avepdf .ant-dropdown-trigger {
  background-color: #ffffff;
  border: 1px solid rgba(102, 110, 132, 0.2);
  display: inline-block;
  cursor: default;
}
.avepdf .ant-dropdown-trigger.selected-language {
  background-color: transparent;
  border-color: transparent;
}
.avepdf .ant-dropdown-trigger.font-families {
  margin: 0 5px;
  padding: 9px;
  border-radius: 5px;
}
.avepdf .ant-dropdown-trigger .anticon.anticon-down {
  display: initial;
  line-height: 16px;
}
.avepdf .ant-dropdown-trigger .anticon svg path {
  fill: #1f1f1f;
}
.avepdf .ant-dropdown-trigger.dropdown-button-trigger:hover,
.avepdf .ant-dropdown-trigger.dropdown-button-trigger:focus {
  background-color: #1f1f1f !important;
  color: #fff;
  border: 1px solid rgba(102, 110, 132, 0.2);
}
.avepdf .ant-dropdown-trigger.dropdown-button-trigger:hover .anticon svg path,
.avepdf .ant-dropdown-trigger.dropdown-button-trigger:focus .anticon svg path {
  fill: #fff;
}
.avepdf .paging-text-options .font-families {
  margin-left: 0;
}
.avepdf .btn-filters .ant-btn {
  border: 1px solid rgba(102, 110, 132, 0.2);
  background-color: #fff;
}
@media (max-width: 992px) {
  .avepdf .btn-filters .ant-btn {
    width: 48%;
    margin: 0 1% 1% !important;
    border-radius: 5px !important;
  }
}
@media (max-width: 992px) {
  .avepdf .btn-filters .ant-btn:first-child {
    width: 100%;
  }
}
.avepdf .btn-filters .ant-btn.custom-active-on {
  color: #1f1f1f;
  background-color: rgba(234, 234, 250, 0.2);
}
.avepdf .btn-filters .ant-btn.custom-active-on:hover,
.avepdf .btn-filters .ant-btn.custom-active-on:focus {
  color: #1f1f1f;
}
.avepdf .btn-filters .ant-btn.custom-active-off {
  color: #1f1f1f;
  border: 1px solid rgba(102, 110, 132, 0.2);
}
.avepdf .btn-other-tools .ant-btn {
  border: 1px solid rgba(102, 110, 132, 0.2);
}
.avepdf .btn-other-tools .ant-btn span {
  transition: none;
}
.avepdf .btn-other-tools .ant-btn:hover,
.avepdf .btn-other-tools .ant-btn:focus {
  color: #1f1f1f;
}
@media (max-width: 992px) {
  .avepdf .btn-other-tools .ant-btn {
    width: 100%;
  }
}
@media (max-width: 992px) {
  .avepdf .btn-other-tools {
    width: 100%;
  }
}
.avepdf .other-tools-dropdown {
  padding: 0;
  box-shadow: none;
  border: 1px solid rgba(102, 110, 132, 0.2);
  border-radius: 5px;
}
.avepdf .other-tools-dropdown li {
  font-size: 0.925em;
  border-top: 1px solid rgba(102, 110, 132, 0.2);
}
.avepdf .other-tools-dropdown li:first-child {
  border: none;
}
.avepdf .other-tools-dropdown li a {
  padding: 10px;
  display: block;
}
.avepdf .other-tools-dropdown li a .async-svg-container {
  display: inline-block;
  width: 18px;
  margin-right: 10px;
}
.avepdf .other-tools-dropdown li a .async-svg-container svg path {
  fill: #1f1f1f !important;
}
.avepdf .other-tools-dropdown li:hover {
  background-color: #f7f8fb;
}
.avepdf .search-wrapper {
  position: relative;
  display: inline-block;
}
.avepdf .search-wrapper .search-tools {
  color: #2c2c2c;
  background-color: #ffff;
  border-color: rgba(102, 110, 132, 0.2);
  padding: 9px 10px;
  line-height: inherit !important;
  font-size: 14px;
}
.avepdf .search-icon svg path {
  fill: #1f1f1f;
}
.avepdf .search-tools {
  height: auto !important;
  padding: 10px;
}
.avepdf .search-tools.form-control {
  color: #1f1f1f;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.avepdf .search-tools.form-control:focus {
  border-color: rgba(102, 110, 132, 0.4);
}
@media (max-width: 991px) {
  .avepdf {
    /* filters buttons */
    /* search tools */
  }
  .avepdf .btn-filters .btn:first-child {
    width: 100%;
  }
  .avepdf .btn-filters .btn {
    width: 46%;
    margin: 0 1% 1% !important;
    border-radius: 5px !important;
  }
  .avepdf .search-wrapper {
    width: 98%;
    margin: 0 1% 0;
  }
  .avepdf .search-tools.form-control {
    margin: 0 auto;
  }
}
.avepdf .tool-item-wrapper {
  /*====== New widget layout ======*/
}
@media (max-width: 992px) {
  .avepdf .tool-item-wrapper {
    padding-bottom: 15px;
  }
}
.avepdf .tool-item-wrapper .tool-item-block {
  border: 1px solid rgba(102, 110, 132, 0.2);
  background-color: rgba(234, 234, 250, 0.08);
}
.avepdf .tool-item-wrapper .tool-item-block .tool-item-block-title {
  color: #1f1f1f;
}
.avepdf .tool-item-wrapper .tool-item-block .tool-item-block-description {
  color: #5f5f5f;
}
.avepdf .tool-item-wrapper .tool-item-block .tool-item-block-arrow {
  color: #1f1f1f;
}
.avepdf .tool-item-wrapper .tool-item-block .tool-item-block-arrow svg path {
  fill: #1f1f1f;
}
.avepdf .tool-tooltip-wrapper {
  width: 100%;
  white-space: normal;
}
.avepdf .tool-tooltip-wrapper .ave-tooltip {
  max-width: 190px;
  margin: 0 auto;
}
.avepdf .tool-tooltip-wrapper .ave-tooltip-triangle {
  height: 15px;
  width: 15px;
  bottom: -9px;
  left: 45%;
}
.avepdf .tool-header-outer-container .header-icon {
  margin-right: 15px;
}
.avepdf .upload-icon svg {
  object-fit: contain;
  display: block;
}
.avepdf .loading-icon {
  object-fit: contain;
  display: block;
  fill: #1f1f1f;
}
.avepdf .upload-more-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.avepdf .document-icon {
  width: 64px;
  height: 64px;
  display: block;
  margin: 16px auto;
}
.avepdf .document-icon svg path {
  fill: #2c2c2c;
}
.avepdf .upload-container,
.avepdf .upload-in-progress {
  margin: 0 25px;
}
@media (max-width: 767.98px) {
  .avepdf .upload-container,
  .avepdf .upload-in-progress {
    display: block;
    width: 100%;
    text-align: center;
  }
}
.avepdf .shape-selection-toolbar-popover .cloud-upload-icon {
  width: 22px;
}
.avepdf .download-icon {
  width: 60px;
}
.avepdf .download-icon-wrapper {
  display: inline-block;
}
@media (max-width: 575px) {
  .avepdf .download-icon-wrapper {
    display: block;
    text-align: center;
  }
}
.avepdf .savetogoogledrive-button {
  position: absolute;
  z-index: 999;
  top: 20px;
  opacity: 0.0000001;
}
.avepdf .upload-more-with-action {
  padding: 24px 0;
}
.avepdf .upload-more-with-action .ant-input-group-wrapper {
  vertical-align: middle;
  margin-bottom: 0;
  margin-top: -4px;
}
@media (max-width: 575.98px) {
  .avepdf .upload-more-with-action .upload-more-methods {
    right: 10px;
    left: 10px;
  }
}
.avepdf .upload-more-button-wrapper .upload-more {
  margin: 0;
  padding: 15px 15px;
}
.avepdf .upload-more-methods ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.avepdf .download-options,
.avepdf .upload-more-methods {
  position: absolute;
  z-index: 99;
  border-radius: 5px;
  width: auto;
  margin-top: 5px;
}
.avepdf .download-options ul,
.avepdf .upload-more-methods ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.avepdf .download-options ul li,
.avepdf upload-more-methods ul li {
  position: relative;
}
.avepdf .download-options ul li .savetogoogledrive-button,
.avepdf .download-options ul li .savetogoogledrive-button > iframe,
.avepdf .download-options ul li .savetogoogledrive-button > iframe .save-to-drive-button {
  height: 100% !important;
  left: 0;
  position: absolute;
  top: 0;
  width: 100% !important;
}
.avepdf .download-options ul li button:hover,
.avepdf .download-options ul li button:focus {
  background-color: #f5f9fc;
}
.avepdf .download-options ul li button.download-action-button {
  width: 100%;
  text-align: start;
  height: 40px;
  padding: 15px;
  border-radius: 0;
  margin-top: -2px;
}
.avepdf .download-options ul li:first-child button.download-action-button {
  border-radius: 5px 5px 0 0;
}
.avepdf .download-options ul li:last-child button.download-action-button {
  border-radius: 0 0 5px 5px;
}
.avepdf .download-options ul li .cloud-upload-icon {
  margin: 0 10px;
}
.avepdf .download-options ul li .cloud-upload-icon svg {
  width: 20px;
}
.avepdf .download-options ul li button.download-action-button svg path {
  fill: #1f1f1f;
}
.avepdf .upload-more-methods ul li .ant-btn {
  margin-bottom: -1px;
  text-align: start;
  border-color: #1f1f1f;
}
.avepdf .upload-more-methods ul li .ant-btn.action-button {
  min-width: 200px;
}
.avepdf .upload-more-methods ul li .ant-btn.ant-btn-primary {
  color: #1f1f1f;
  background-color: #fff;
  border-color: #1f1f1f;
}
.avepdf .upload-more-methods ul li .ant-btn.ant-btn-primary:hover,
.avepdf .upload-more-methods ul li .ant-btn.ant-btn-primary:focus {
  background-color: #1f1f1f;
  border-color: #1f1f1f;
  color: #fff;
}
.avepdf .upload-more-methods ul li .ant-btn.ant-btn-primary:hover svg path,
.avepdf .upload-more-methods ul li .ant-btn.ant-btn-primary:focus svg path {
  fill: #fff;
}
@media (max-width: 526.98px) {
  .avepdf .upload-more-methods ul li .ant-btn.ant-btn-primary {
    white-space: initial;
  }
}
.avepdf .upload-more-methods ul li .ant-btn.action-button {
  width: 100%;
  text-align: start;
  height: 40px;
  padding: 15px;
  border-radius: 0;
}
.avepdf .upload-more-methods ul li .ant-btn:hover,
.avepdf .upload-more-methods ul li .ant-btn:focus {
  background-color: #1f1f1f;
  border-color: #1f1f1f;
  color: #fff;
}
.avepdf .upload-more-methods ul li .ant-btn:hover svg path,
.avepdf .upload-more-methods ul li .ant-btn:focus svg path {
  fill: #fff;
}
.avepdf .upload-more-methods ul li .ant-btn svg {
  width: 20px;
}
.avepdf .upload-more-methods ul li .ant-btn .upload-more-label {
  margin-left: 10px;
  font-size: 14px;
}
@media (max-width: 767.98px) {
  .avepdf .upload-more-methods ul li .ant-btn .upload-more-label {
    white-space: break-spaces;
  }
}
.avepdf .upload-more-methods ul li:first-child .ant-btn {
  border-radius: 5px 5px 0 0;
  border-color: #1f1f1f;
}
.avepdf .upload-more-methods ul li:last-child .ant-btn.action-button {
  border-radius: 0 0 5px 5px;
}
@media (max-width: 575.98px) {
  .avepdf .upload-more-methods {
    position: absolute;
    z-index: 99;
    border-radius: 5px;
    width: auto !important;
    right: 0;
    left: 0;
    margin: 5px auto 0;
  }
}
.avepdf .tool-action-title {
  color: #1f1f1f;
}
@media (max-width: 767.98px) {
  .avepdf .tool-action-sub-title {
    margin: 15px auto 15px;
  }
}
@media (max-width: 575.98px) {
  .avepdf .tools-upload .powered-by-link {
    text-align: center;
  }
}
.avepdf .tool-faltten-option-title {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
  padding-bottom: 24px;
}
.avepdf .incorrect-password {
  color: #c00;
}
.avepdf .action-button,
.avepdf .action-button:hover,
.avepdf .action-button:focus,
.avepdf .close-action-button:hover,
.avepdf .close-action-button:focus {
  color: #1f1f1f;
}
.avepdf .action-button,
.avepdf .close-action-button {
  border-radius: 5px;
  color: #1f1f1f;
}
.avepdf .close-action-button {
  border: none;
}
.avepdf .action-button[disabled],
.avepdf .action-button[disabled]:hover {
  color: #787878;
}
.avepdf .ave-notifications .notif-language-change,
.avepdf .ave-notifications .notif-consent {
  border: 1px solid rgba(102, 110, 132, 0.2);
  border-radius: 5px;
}
.avepdf .ave-notifications .notif-language-change a:hover,
.avepdf .ave-notifications .notif-consent a:hover,
.avepdf .ave-notifications .notif-language-change a :focus,
.avepdf .ave-notifications .notif-consent a :focus {
  color: #c5c5c5;
}
.avepdf .ave-notifications .language-changer-loader {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 12px;
}
.avepdf .ave-notifications .btn {
  background-color: #1f1f1f;
  border-color: #1f1f1f;
  color: #fff;
}
.avepdf .ave-notifications .btn svg path {
  fill: #fff;
}
.avepdf .ave-notifications .btn:hover,
.avepdf .ave-notifications .btn :focus {
  background-color: #454545;
  border-color: #454545;
  color: #fff;
}
.avepdf .ave-notifications .btn:hover svg path,
.avepdf .ave-notifications .btn :focus svg path {
  fill: #fff;
}
.avepdf .ave-notifications .btn.btn-cancel {
  background-color: #eaeff4;
  border-color: #eaeff4;
}
.avepdf .ave-notifications .btn.btn-cancel svg path {
  fill: #1f1f1f;
}
.avepdf .ave-notifications .btn.btn-confirm {
  background-color: #b7cc27;
  border-color: #b7cc27;
}
.avepdf .ave-notifications .btn.btn-confirm svg path {
  fill: #fff;
}
.avepdf .ave-notifications .btn.btn-confirm:hover,
.avepdf .ave-notifications .btn.btn-confirm :focus {
  background-color: #a9ca2f;
  border-color: #a9ca2f;
}
.avepdf .ant-notification-notice {
  padding: 10px 20px;
  border: 1px solid rgba(102, 110, 132, 0.2);
  line-height: 1.5715;
  /*====== notification consent ======*/
  /*====== notification language changer ======*/
}
@media (max-width: 767.98px) {
  .avepdf .ant-notification-notice {
    width: 95%;
  }
}
.avepdf .ant-notification-notice a {
  text-decoration: underline;
}
.avepdf .ant-notification-notice a:hover,
.avepdf .ant-notification-notice a :focus {
  color: #c5c5c5;
}
.avepdf .ant-notification-notice .ant-notification-notice-message {
  font-size: 0.925em;
  line-height: inherit;
}
.avepdf .ant-notification-notice .ant-notification-notice-btn {
  margin-top: 15px;
}
.avepdf .ant-notification-notice .ant-notification-notice-btn .ant-btn {
  padding: 8px 15px;
  background-color: #1f1f1f;
  border-color: #1f1f1f;
  color: #fff;
}
.avepdf .ant-notification-notice .ant-notification-notice-btn .ant-btn svg path {
  fill: #fff;
}
.avepdf .ant-notification-notice .ant-notification-notice-btn .ant-btn:hover,
.avepdf .ant-notification-notice .ant-notification-notice-btn .ant-btn :focus {
  background-color: #454545;
  border-color: #454545;
  color: #fff;
}
.avepdf .ant-notification-notice .ant-notification-notice-btn .ant-btn:hover svg path,
.avepdf .ant-notification-notice .ant-notification-notice-btn .ant-btn :focus svg path {
  fill: #fff;
}
.avepdf .ant-notification-notice .ant-notification-notice-btn .ant-btn.btn-cancel {
  background-color: #eaeff4;
  border-color: #eaeff4;
  margin-right: 5px;
}
.avepdf .ant-notification-notice .ant-notification-notice-btn .ant-btn.btn-cancel svg path {
  fill: #1f1f1f;
}
.avepdf .ant-notification-notice .ant-notification-notice-btn .ant-btn.btn-confirm {
  background-color: #b7cc27;
  border-color: #b7cc27;
  margin-right: 8px;
}
.avepdf .ant-notification-notice .ant-notification-notice-btn .ant-btn.btn-confirm svg path {
  fill: #fff;
}
.avepdf .ant-notification-notice .ant-notification-notice-btn .ant-btn.btn-confirm:hover,
.avepdf .ant-notification-notice .ant-notification-notice-btn .ant-btn.btn-confirm :focus {
  background-color: #a9ca2f;
  border-color: #a9ca2f;
}
.avepdf .ant-notification-notice.notif-cconsent .ant-notification-notice-message {
  padding: 0;
  margin: 0;
}
.avepdf .ant-notification-notice.notif-cconsent .ant-notification-notice-btn .ant-btn {
  font-weight: 600;
}
.avepdf .ant-notification-notice.notif-language-change {
  padding-top: 15px;
  padding-bottom: 15px;
}
.avepdf .ant-notification-notice.notif-language-change .notif-country-flag {
  width: 18px !important;
  height: auto !important;
  margin-right: 5px;
  vertical-align: initial !important;
  margin-bottom: -2px;
}
.avepdf .ant-notification-notice.notif-language-change .language-changer-loader {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 12px;
}
.avepdf .ant-notification-notice.notif-language-change .ant-notification-notice-btn {
  margin-top: 10px;
}
.avepdf .ant-notification-notice-close {
  top: 8px;
}
.avepdf .card {
  border: 1px solid rgba(160, 181, 206, 0.44);
}
.avepdf .card-header {
  border-color: rgba(160, 181, 206, 0.44);
}
.avepdf .section-separator svg path {
  fill: #f4f7fe;
}
.avepdf .sdk-offer-wrapper {
  background-color: #f4f7fe;
}
.avepdf .sdk-offer-wrapper .sdk-offer-container {
  overflow-x: hidden;
  width: 100%;
  position: relative;
}
.avepdf .sdk-offer .features-list li:first-child:before {
  display: none;
}
.avepdf .sdk-offer .features-list li:before {
  content: '\2022';
  margin: 0 5px;
  line-height: unset;
  position: relative;
  display: inline-block;
  font-size: 32px;
  vertical-align: bottom;
}
.avepdf .sdk-offer-card {
  border-radius: 5px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  box-shadow: 0px 20px 35px 0px rgba(190, 195, 215, 0.6);
  transition: 0.3s padding ease;
}
.avepdf .sdk-offer-card .card-header {
  padding: 0;
  margin-bottom: 5px;
  background-color: transparent;
  border-bottom: none;
  transition: 0.3s ease !important;
}
.avepdf .sdk-offer-card .card-footer {
  background-color: transparent;
  transform: translateY(100%);
  transition: 0.3s ease !important;
}
.avepdf .sdk-offer-card:hover,
.avepdf .sdk-offer-card:focus {
  border: 1px solid rgba(255, 255, 255, 0.7);
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px 15px 76px;
}
.avepdf .sdk-offer-card:hover .card-footer,
.avepdf .sdk-offer-card:focus .card-footer {
  transform: translateY(0%);
  position: absolute;
  bottom: 17px;
}
.avepdf .sdk-offer-card .sdk-offer-card-icon {
  display: inline-block;
  width: 64px;
  margin: 0 auto;
  margin-bottom: 15px;
}
.avepdf .sdk-offer-card .sdk-offer-card-title {
  font-size: 1.1em;
  font-weight: 500;
}
.avepdf .sdk-offer-card .sdk-offer-card-logo.gdp-logo svg path.path-gd-color {
  fill: #1f1f1f;
}
.avepdf .sdk-offer-card .sdk-offer-card-logo.pp-logo svg path {
  fill: #1f1f1f;
}
.avepdf .sdk-offer-card .sdk-offer-card-link {
  color: #1f1f1f;
}
.avepdf .sdk-offer-card .sdk-offer-card-link:hover,
.avepdf .sdk-offer-card .sdk-offer-card-link:focus {
  opacity: 0.75;
}
.avepdf .sdk-offer-card .sdk-offer-card-link .external-icon {
  width: 16px;
  margin-left: 10px;
  display: inline-block;
  vertical-align: text-bottom;
}
.avepdf .sdk-offer-card .sdk-offer-card-link.purple {
  color: #9b76d8;
}
.avepdf .sdk-offer-card .sdk-offer-card-link.purple svg path {
  fill: #9b76d8;
}
.avepdf .sdk-offer-card .sdk-offer-card-link.blue {
  color: #0382d4;
}
.avepdf .sdk-offer-card .sdk-offer-card-link.blue svg path {
  fill: #0382d4;
}
.avepdf .new-sdk-offer-wrapper {
  margin: 60px 0;
}
.avepdf .new-sdk-offer-wrapper .sdk-offer-container {
  overflow-x: hidden;
  width: 100%;
  position: relative;
}
.avepdf .new-sdk-offer-wrapper .sdk-offer-container .new-sdk-offer-card {
  margin: 30px 0;
  background: #FFFFFF;
  box-shadow: 0px 40px 70px -20px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  border: none;
  z-index: 1;
}
.avepdf .new-sdk-offer-wrapper .sdk-offer-container .new-sdk-offer-card:hover {
  box-shadow: 0px 40px 70px -20px rgba(0, 0, 0, 0.2);
}
.avepdf .new-sdk-offer-wrapper .sdk-offer-container .new-sdk-offer-card .card-body {
  padding: 3rem 2rem;
}
.avepdf .new-sdk-offer-wrapper .sdk-offer-container .new-sdk-offer-card .card-body .sdk-offer-card-description {
  margin: 2rem 0;
}
.avepdf .new-sdk-offer-wrapper .sdk-offer-container .new-sdk-offer-card .ant-btn {
  padding: 10px !important;
  color: #fff !important;
  background-color: #1D7C76;
  border: none;
  border-radius: 5px;
}
.avepdf .new-sdk-offer-wrapper .sdk-offer-container .new-sdk-offer-card .ant-btn:hover,
.avepdf .new-sdk-offer-wrapper .sdk-offer-container .new-sdk-offer-card .ant-btn:focus {
  background-color: #75C7B8;
}
.avepdf .new-sdk-offer-wrapper .background-shape {
  position: absolute;
  border-radius: 25px;
  opacity: 1;
}
.avepdf .new-sdk-offer-wrapper .background-shape.background-shape1 {
  background-color: #f7d370;
  width: 48px;
  height: 48px;
  top: -8px;
  right: -8px;
  left: auto;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  border-radius: 15px;
}
.avepdf .new-sdk-offer-wrapper .background-shape.background-shape2 {
  background-color: #7340f3;
  width: 24px;
  height: 24px;
  top: -40px;
  right: -40px;
  left: auto;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  border-radius: 10px;
}
.avepdf .new-sdk-offer-wrapper .background-shape.background-shape3 {
  background-color: #d63125;
  width: 32px;
  height: 32px;
  bottom: 20%;
  left: -40px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  border-radius: 10px;
  top: auto;
}
.avepdf .new-sdk-offer-wrapper .background-shape.background-shape4 {
  background-color: rgba(117, 199, 184, 0.2);
  width: 32px;
  height: 32px;
  bottom: -10%;
  left: 20px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  border-radius: 10px;
  top: auto;
}
.avepdf .new-sdk-offer-wrapper .background-shape.background-shape5 {
  background-color: #ee9777;
  width: 16px;
  height: 16px;
  bottom: -15%;
  left: 55px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  border-radius: 5px;
  top: auto;
}
.avepdf .new-sdk-offer-wrapper .sdk-features-list {
  list-style: none;
  position: relative;
}
.avepdf .new-sdk-offer-wrapper .sdk-features-list li {
  margin-top: 10px;
  line-height: 2;
  position: relative;
}
.avepdf .new-sdk-offer-wrapper .sdk-features-list li:first-child {
  margin-top: none;
}
.avepdf .new-sdk-offer-wrapper .sdk-features-list li:before {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #F7D370;
  display: inline-block;
  position: absolute;
  left: -25px;
  top: 10px;
  border-radius: 3px;
}
.avepdf .blog-articles {
  margin-top: 90px !important;
}
.avepdf .blog-section-title {
  margin-bottom: 35px;
}
.avepdf .blog-card {
  position: relative;
  z-index: 10;
  height: 100%;
  border-radius: 5px;
  border: 1px solid rgba(102, 110, 132, 0.2);
  background-color: #fff;
  padding: 0;
  overflow: hidden;
  transition: 0.3s all ease;
}
.avepdf .blog-card .card-body {
  padding: 0;
}
.avepdf .blog-card .blog-title {
  display: block;
  padding: 30px;
  background-color: #fff;
  font-size: 1.1em;
  font-weight: 500;
  color: #1f1f1f;
}
.avepdf .blog-card:hover,
.avepdf .blog-card:focus {
  box-shadow: 0px 20px 35px 0px rgba(190, 195, 215, 0.6);
}
.avepdf .avepdf-bck-details a:hover,
.avepdf .avepdf-bck-details a:focus {
  opacity: 0.75;
}
.avepdf .avepdf-bck-details a .link-arrow {
  display: inline-block;
  max-width: 16px;
}
.avepdf .avepdf-bck-details a .link-arrow svg path {
  fill: #1D7C76;
}
.avepdf .avepdf-bck-details .avepdf-bck-details-img {
  text-align: center;
}
.avepdf .avepdf-bck-details .avepdf-bck-details-img svg {
  margin: 0 auto;
}
@media (max-width: 768px) {
  .avepdf .avepdf-bck-details .avepdf-bck-details-img svg {
    max-width: 320px;
  }
}
.avepdf .fancy-bg .fancy-bg-1:before {
  content: "";
  position: absolute;
  width: 64px;
  height: 64px;
  background: #f7d370;
  border-radius: 15px;
  top: 4%;
  left: -2%;
}
@media (max-width: 768px) {
  .avepdf .fancy-bg .fancy-bg-1:before {
    left: -10%;
  }
}
.avepdf .fancy-bg .fancy-bg-1:after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  background: #7340f3;
  border-radius: 10px;
  top: -1%;
  left: 5%;
}
.avepdf .fancy-bg .fancy-bg-2 {
  position: absolute;
  width: 36px;
  height: 36px;
  background: rgba(117, 199, 184, 0.2);
  border-radius: 10px;
  top: 30px;
  right: 30px;
}
.avepdf .fancy-bg .fancy-bg-3:before {
  content: "";
  position: absolute;
  width: 9px;
  height: 9px;
  background: #f7d370;
  border-radius: 50%;
  top: 20%;
  left: 50%;
}
.avepdf .fancy-bg .fancy-bg-3:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: #d63125;
  border-radius: 50%;
  top: 25%;
  left: 52%;
}
.avepdf .fancy-bg .fancy-bg-4:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #EE9777;
  left: 10%;
  bottom: 15%;
}
@media (max-width: 768px) {
  .avepdf .fancy-bg .fancy-bg-4:before {
    left: 5%;
  }
}
.avepdf .fancy-bg .fancy-bg-4:after {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #F7D370;
  left: 13%;
  bottom: 12%;
}
@media (max-width: 768px) {
  .avepdf .fancy-bg .fancy-bg-4:after {
    left: 8%;
  }
}
.avepdf .fancy-bg .fancy-bg-5 {
  position: absolute;
  width: 32px;
  height: 32px;
  background: #d63125;
  border-radius: 10px;
  right: 10%;
  bottom: 20px;
}
.avepdf .testimonials-wrapper {
  background: #FDFDFF;
  box-shadow: 0px 40px 70px -20px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  padding: 120px;
  margin-bottom: 60px;
  position: relative;
}
@media (max-width: 768px) {
  .avepdf .testimonials-wrapper {
    padding: 60px 60px 80px 60px;
  }
}
.avepdf .testimonials-wrapper .slick-dots-bottom {
  bottom: -60px;
  margin-top: 20px;
}
.avepdf .testimonials-wrapper .slick-dots li {
  width: 26px;
  height: 10px;
}
.avepdf .testimonials-wrapper .slick-dots li button {
  background: #1D7C76;
  height: 10px;
  border-radius: 10px;
}
.avepdf .testimonials-wrapper .slick-dots li.slick-active button {
  background: #1D7C76;
}
.avepdf .testimonials-wrapper .testimonial-content a {
  color: #1D7C76 !important;
  padding: 15px 0;
}
.avepdf .testimonials-wrapper .testimonial-content a .link-arrow {
  display: inline-block;
  max-width: 16px;
}
.avepdf .testimonials-wrapper .testimonial-content a .link-arrow svg path {
  fill: #1D7C76 !important;
}
.avepdf .ant-carousel {
  color: inherit;
}
.avepdf .download-offer-tools {
  margin-top: 30px;
  padding-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 30px;
}
.avepdf .offer-after-procesed {
  text-align: initial;
  justify-content: center;
}
.avepdf .policy-protect-wrapper .policy-protect-icon svg path {
  fill: #ffffff;
}
.avepdf .how-to-use .how-to-use-icon {
  text-align: center;
}
.avepdf .did-you-know {
  border-bottom: 1px solid #dde5ee;
  max-width: 95%;
  margin: 0 auto;
  padding: 25px 5px;
}
.avepdf .did-you-know .did-you-know-href {
  color: #0388db;
  text-decoration: underline;
}
.avepdf .did-you-know .did-you-know-href:hover {
  color: #0388db;
  text-decoration: underline;
}
.avepdf .ant-slider-rail {
  height: 8px;
  border-radius: 5px;
  width: calc(100% + 14px);
  margin-left: -7px;
}
.avepdf .ant-slider-with-marks {
  margin-left: 0;
  margin-right: 0;
}
.avepdf .ant-slider-track {
  height: 8px;
  border-radius: 5px;
}
.avepdf .ant-slider-handle,
.avepdf .ant-slider-handle:focus,
.avepdf .ant-slider-handle:hover {
  border-style: solid;
  border-width: 8px;
}
.avepdf .ant-slider-handle:focus {
  outline: none;
  box-shadow: 0 0 0 5px rgba(31, 31, 31, 0.2);
}
.avepdf .ant-slider-dot {
  border-style: solid;
  border-width: 8px;
  top: -5px;
  margin-left: 0px;
}
.avepdf .ant-slider-mark-text {
  padding-top: 8px;
  color: inherit;
  color: #2c2c2c;
  white-space: nowrap;
}
.avepdf .ant-slider-mark-text-active {
  color: #2c2c2c;
}
.avepdf .text-align-center {
  text-align: center;
}
.avepdf .back-icon svg {
  vertical-align: inherit;
}
.avepdf .back-icon svg path {
  fill: #1f1f1f;
}
.avepdf .parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.avepdf .horizontal-tool {
  margin-bottom: 38px;
}
.avepdf .horizontal-tool .horizontal-tool-description {
  font-size: 15px;
  color: #454545;
}
.avepdf .horizontal-tool .horizontal-tool-title {
  font-size: 17px;
  font-weight: bold;
  color: #2c2c2c;
}
.avepdf .horizontal-tool .horizontal-tool-icon-svg {
  display: block;
}
.avepdf .horizontal-tool .horizontal-tool-icon svg {
  height: 100%;
  width: 100%;
}
.avepdf .card-block {
  padding-right: 22px;
  padding-left: 22px;
}
.avepdf .protect-password .ant-input-prefix {
  padding-top: 5px;
}
.avepdf .ant-input-affix-wrapper {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: none;
  transition: none;
  /* Change Autocomplete styles in Chrome*/
}
.avepdf .ant-input-affix-wrapper .ant-input:hover,
.avepdf .ant-input-affix-wrapper .ant-input:focus {
  border: none;
}
.avepdf .ant-input-affix-wrapper:hover,
.avepdf .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
  border-color: rgba(102, 110, 132, 0.4);
  -webkit-box-shadow: none;
}
.avepdf .ant-input-affix-wrapper .ant-input {
  border-radius: 5px;
  border: none;
}
.avepdf .ant-input-affix-wrapper input:-webkit-autofill,
.avepdf .ant-input-affix-wrapper input:-webkit-autofill:hover,
.avepdf .ant-input-affix-wrapper input:-webkit-autofill:focus,
.avepdf .ant-input-affix-wrapper input:-webkit-autofill:active {
  box-shadow: 0 0 0px 1000px #fff inset !important;
}
.avepdf .ant-input-affix-wrapper .ant-input-prefix {
  text-align: center;
  width: 20px;
}
.avepdf .ant-input-affix-wrapper .ant-input-prefix img {
  width: 17px;
}
.avepdf .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 10px;
}
.avepdf .tool-item-wrapper:hover {
  position: relative;
}
.avepdf .tool-item-wrapper:hover .tool-item-block {
  box-shadow: 0px 40px 60px -20px rgba(102, 110, 132, 0.15);
}
.avepdf .tool-item-content:hover {
  box-shadow: 0 14px 14px 0 rgba(77, 113, 173, 0.06);
}
.avepdf .document {
  max-width: 390px;
  border: 1px solid rgba(160, 181, 206, 0.34);
  height: 96px;
  border-radius: 5px;
  background-color: #ffffff;
  position: absolute;
  bottom: 10px;
  left: 2%;
  width: 74%;
  line-height: 1.4;
}
.avepdf .document .horizontal-tool-title {
  font-size: 16px;
  font-weight: 600;
  color: #2c2c2c;
}
.avepdf .document .horizontal-tool-description {
  font-size: 14px;
  color: #454545;
  line-height: 1.5;
}
.avepdf .vertial-auto-margin {
  margin-top: auto;
  margin-bottom: auto;
}
.avepdf .ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.avepdf .ant-input {
  border-radius: 5px;
  height: 52px;
  font-size: 15px;
  line-height: 1.38;
  color: #1f1f1f;
}
.avepdf .ant-progress-success-bg,
.avepdf .ant-progress-bg,
.avepdf .ant-progress-status-success .ant-progress-bg {
  background-color: #4a90e2;
}
.avepdf .upload-progress > .ant-progress-text {
  color: #1f1f1f;
}
.avepdf .upload-progress > .ant-progress-text svg {
  display: none;
}
.avepdf .ant-progress-outer {
  padding-right: calc(2em + 8px) !important;
}
@media (max-width: 575.98px) {
  .avepdf .ant-progress-outer {
    width: 95%;
  }
}
.avepdf .upload-progress-wrapper {
  position: relative;
  width: 100%;
}
.avepdf .upload-progress {
  margin: 36px auto 0 !important;
  width: 70% !important;
  display: block !important;
}
@media (max-width: 575.98px) {
  .avepdf .upload-progress {
    width: 60% !important;
  }
}
.avepdf .upload-progress-loading {
  width: 40px;
  position: absolute;
  right: 10.5%;
  top: -18%;
  fill: #1f1f1f;
}
.avepdf .upload-progress-almost-there {
  margin: 0;
  text-align: center;
  margin-top: 10px;
  min-height: 30px;
  min-width: 20px;
}
.avepdf .drag-enter,
.avepdf .drag-over {
  background-color: rgba(148, 191, 218, 0.03);
}
.avepdf .loading-icon {
  width: 80px;
  height: 90px;
  margin-right: 42px;
}
.avepdf .loader-content {
  position: relative;
  height: 100vh;
}
.avepdf .loader {
  display: block;
  left: 50%;
  margin-top: -180px;
  position: relative;
  padding-top: 100px;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
}
.avepdf .loader .circle {
  position: absolute;
  width: 80px;
  height: 80px;
  opacity: 0;
  transform: rotate(225deg);
  animation-iteration-count: infinite;
  animation-name: orbit;
  animation-duration: 4s;
}
.avepdf .loader .circle:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 500px;
  background: #2c2c2c;
}
.avepdf .loader .circle:nth-child(2) {
  animation-delay: 150ms;
}
.avepdf .loader .circle:nth-child(3) {
  animation-delay: 300ms;
}
.avepdf .loader .circle:nth-child(4) {
  animation-delay: 450ms;
}
.avepdf .loader .circle:nth-child(5) {
  animation-delay: 600ms;
}
.avepdf .loading-tool {
  margin: 0 auto;
}
.avepdf .loading-tool .loading-icon {
  margin-right: 0;
}
.avepdf .upload-file-wrapper .loading-icon {
  margin: 0 auto;
}
.avepdf .rotate-row {
  margin-right: 0;
  margin-left: 0;
}
.avepdf .ave-btn.rotate-btn {
  display: flex;
  height: 45px;
  width: 10rem;
  min-width: 140px;
  margin: auto 0;
  padding: 0 1em !important;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: 600;
}
.avepdf .ant-btn.rotate-btn svg {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}
.avepdf .ant-btn.rotate-btn svg path {
  fill: inherit;
}
.avepdf .ant-btn.rotate-btn:hover svg path,
.avepdf .ant-btn.rotate-btn :focus svg path {
  fill: #fff;
}
.avepdf .rotate-btn span,
.avepdf i {
  margin: auto;
}
.avepdf .rotate-btn:focus svg path {
  fill: inherit;
}
.avepdf .w-100 .tools-rotate-row .rotate-row .rotate-btn-left {
  margin-left: 10px;
  border-radius: 5px 0 0 5px;
}
.avepdf .w-100 .tools-rotate-row .rotate-row .rotate-btn-right {
  border-radius: 0 5px 5px 0;
}
.avepdf .w-100 .tools-rotate-row .rotate-row .rotate-restore {
  margin: auto 10px;
}
@media (max-width: 768px) {
  .avepdf .w-100 .tools-rotate-row .rotate-row .rotate-restore {
    margin: 10px 0;
  }
  .avepdf .w-100 .tools-rotate-row .rotate-row .rotate-btn-left {
    margin-left: 0;
  }
}
.avepdf .download-action-button p,
.avepdf .download-action-button .download-button-text-block .download-size span {
  font-weight: bold;
}
.avepdf .download-action-button .download-button-text-block {
  display: inline-block;
}
.avepdf .download-action-button .download-button-text-block p {
  font-weight: bold;
  margin-bottom: 0px;
  line-height: 1.4;
  text-align: center;
}
.avepdf .download-action-button .download-button-text-block .download-size {
  display: block;
  font-size: 12px;
  white-space: pre-wrap;
}
.avepdf .protect-pdf-password {
  width: 30%;
}
.avepdf .resize-input-w {
  width: 30%;
  text-align: center;
}
.avepdf .resize-input-h {
  width: 30%;
  text-align: center;
}
.avepdf .protect-pdf-password.red-icon svg path,
.avepdf .protect-pdf-password-input.red-icon svg path {
  fill: red !important;
}
.avepdf .full-preview-elem {
  outline: 2px solid #efefef;
}
.avepdf #esign-selection-box {
  border: 1px dotted #1f1f1f;
  background: rgba(209, 209, 209, 0.4);
  position: absolute;
}
.avepdf .full-preview-page {
  position: relative;
  margin-bottom: 10px;
}
.avepdf .crop-wrapper {
  padding: 0 10px;
}
.avepdf .filter-div {
  position: absolute;
  top: 0;
  left: 0;
}
.avepdf .ant-modal {
  max-width: 992px;
}
.avepdf .premium-modal-img {
  max-width: 240px;
  margin: 0 auto 30px;
}
.avepdf .ant-modal-confirm-body .ant-modal-confirm-title {
  color: #1f1f1f;
}
.avepdf .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 3px;
  margin-top: 20px;
}
.avepdf .remeber-input {
  position: relative;
}
.avepdf .remeber-input .ant-checkbox {
  position: absolute;
  top: 2px;
}
.avepdf .remeber-input .ant-checkbox-wrapper + span,
.avepdf .remeber-input .ant-checkbox + span {
  display: block;
  padding-left: 35px;
  font-size: 12px;
}
.avepdf .esign-wrapper {
  text-align: center;
  width: 100%;
  margin: 0 10px;
}
.avepdf .signature-wrapper {
  position: relative;
}
.avepdf .signature-wrapper-text {
  position: relative;
  z-index: 1000;
}
.avepdf .signature-wrapper span.clear-signature {
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  left: 15px;
  font-size: 16px;
  font-weight: bold;
}
.avepdf #signature canvas {
  background-color: #f3f3f3 !important;
  height: 400px !important;
}
.avepdf .signature-image {
  margin: 0px;
  padding: 0px;
  border: none;
  width: 100%;
  touch-action: none;
  background-color: #f3f3f3;
  height: 400px !important;
}
.avepdf .signature-image,
.avepdf .signature-image img {
  max-height: 380px;
}
.avepdf .signature-image img {
  max-width: 100%;
}
.avepdf .signature-modal-button {
  margin-top: 24px;
  margin-bottom: 24px;
}
.avepdf #full-preview-group {
  width: 100%;
  position: relative;
}
.avepdf .crop-box {
  position: absolute;
  z-index: 10;
  border: 2px dotted #1f1f1f;
  background: #fff;
  opacity: 0.6;
}
.avepdf .crop-modal .ant-modal-body {
  padding: 0px;
}
.avepdf .crop-modal .ant-modal-footer {
  padding: 16px 16px;
}
.avepdf .crop-modal .signature-modal-button {
  margin-top: 16px;
  margin-bottom: 16px;
}
.avepdf input::-webkit-outer-spin-button,
.avepdf input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.avepdf input[type=number] {
  -moz-appearance: textfield;
}
.avepdf .redaction-modal-warning {
  color: red;
  margin: 10px 0px;
  text-align: start;
}
.avepdf .signature-modal .action-button {
  padding: 10px 7%;
  min-width: auto;
  height: auto;
  margin: 5px 10px 5px 0;
}
.avepdf .signature-wrapper {
  max-width: 94%;
  margin: 0 auto;
  top: 12px;
}
.avepdf .heart-icon {
  opacity: 0;
}
.avepdf .active .heart-icon {
  opacity: 1;
}
.avepdf .pdf-to-jpg-quality-wrapper {
  color: #1f1f1f;
  display: flex;
  flex-direction: row;
  width: max-content;
  font-size: 16px;
}
.avepdf .pagenumber-preview-wrapper {
  position: relative;
  width: 80%;
  max-width: 320px;
  display: block;
  margin: 15% auto;
  border: 6px solid rgba(160, 181, 206, 0.34);
  border-radius: 20px;
  padding: 45px 15px;
}
.avepdf .pagenumber-preview-wrapper .preview-text-position {
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  max-width: 80%;
  border: 1px dashed #1f1f1f;
  padding: 5px 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.avepdf .pagenumber-preview-wrapper .preview-text-position.position-top-left {
  top: 10px;
  left: 16px;
}
.avepdf .pagenumber-preview-wrapper .preview-text-position.position-top-center {
  top: 10px;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  max-width: 80%;
}
.avepdf .pagenumber-preview-wrapper .preview-text-position.position-top-right {
  top: 10px;
  right: 16px;
}
.avepdf .pagenumber-preview-wrapper .preview-text-position.position-bottom-left {
  bottom: 10px;
  left: 16px;
}
.avepdf .pagenumber-preview-wrapper .preview-text-position.position-bottom-center {
  bottom: 10px;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  max-width: 80%;
}
.avepdf .pagenumber-preview-wrapper .preview-text-position.position-bottom-right {
  bottom: 10px;
  right: 16px;
}
.avepdf .pagenumber-preview-wrapper .preview-text-position.position-center-left {
  top: 50%;
  left: 16px;
}
.avepdf .pagenumber-preview-wrapper .preview-text-position.position-center-right {
  top: 50%;
  right: 16px;
}
.avepdf .pagenumber-preview-wrapper .preview-text-position.position-center-center {
  top: 50%;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  max-width: 80%;
}
.avepdf .pagenumber-preview-wrapper .preview-text-position.watermark-position {
  width: 120px;
  height: 120px;
}
.avepdf .pagenumber-preview-wrapper .preview-text-position.watermark-position.position-center-left {
  margin-top: -60px;
}
.avepdf .pagenumber-preview-wrapper .preview-text-position.watermark-position.position-center-right {
  margin-top: -60px;
}
.avepdf .pagenumber-preview-wrapper .preview-text-position.watermark-position.position-center-center {
  top: 50%;
  margin: -60px auto 0;
  left: 0;
  right: 0;
}
.avepdf .pagenumber-preview-wrapper .preview-text-position.watermark-position img {
  width: auto;
  height: auto;
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}
.avepdf .pagenumber-preview-wrapper.watermark-preview {
  max-width: 100%;
  min-height: auto;
  height: auto;
  margin: 0 auto;
  width: 100%;
  max-height: 100%;
  border-width: 3px;
}
.avepdf .preview-content-img {
  width: 90%;
  height: 90%;
  margin: 5%;
  position: relative;
  top: 0;
  bottom: 0;
}
.avepdf .preview-content-img img {
  width: 100%;
  height: auto;
}
.avepdf .preview-content-img svg {
  width: 100%;
  height: 100%;
}
.avepdf .preview-content-img svg path {
  fill: #1f1f1f;
}
.avepdf .barcode-number {
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  background: #1f1f1f;
  margin: 0;
  text-align: center;
  min-width: 32px;
  color: #fff;
}
.avepdf .select-watermark button svg {
  margin-right: 10px;
}
.avepdf .select-watermark button:hover svg path,
.avepdf .select-watermark button:focus svg path,
.avepdf .select-watermark button:active svg path {
  fill: #fff;
}
@media (max-width: 767px) {
  .avepdf .select-watermark .ant-btn {
    white-space: break-spaces;
  }
}
.avepdf .select-watermark .ant-upload-list-item {
  height: auto;
}
.avepdf .select-watermark .ant-upload-list-item .ant-upload-span {
  padding: 10px;
}
.avepdf .select-watermark .ant-upload-list-item-info {
  border-radius: 5px;
}
.avepdf .select-watermark .ant-upload-list-item-card-actions-btn svg {
  margin: 5px;
}
.avepdf .select-watermark .ant-radio-button-wrapper {
  margin: 0 5px 5px 0 !important;
  border-radius: 5px !important;
  border: 1px solid #d9d9d9;
}
@media (max-width: 767px) {
  .avepdf .select-watermark .ant-radio-button-wrapper {
    height: auto;
    font-size: 15px;
    line-height: 1.8;
    padding: 8px;
  }
}
.avepdf .select-watermark .ant-radio-button-wrapper:not(:first-child)::before {
  display: none !important;
}
.avepdf .select-watermark .text-color {
  line-height: 1 !important;
  padding: 7px;
  vertical-align: middle;
  margin: 0px 5px 5px 5px !important;
  background-color: transparent;
}
.avepdf .select-watermark .text-color span {
  width: 24px;
  height: 24px;
}
@media (max-width: 767px) {
  .avepdf .select-watermark {
    text-align: start;
  }
}
.avepdf .color-hue {
  margin-bottom: 0 !important;
  width: 225px !important;
}
.avepdf .color-saturation {
  width: 225px !important;
  margin-bottom: 15px !important;
}
.avepdf .watermark-preview-image-small {
  width: 32px !important;
  height: auto !important;
}
.avepdf .watermark-preview-image-medium {
  width: 64px !important;
  height: auto !important;
}
.avepdf .watermark-preview-image-large {
  width: 96px !important;
  height: auto !important;
}
.avepdf .below-layer-svg,
.avepdf .above-layer-svg {
  display: inline-block;
  margin-right: 10px;
  max-width: 14px;
}
.avepdf .validation-report-details {
  padding: 15px;
  margin: 15px 0;
  border: 1px solid rgba(102, 110, 132, 0.2);
  background-color: rgba(234, 234, 250, 0.08);
  border-radius: 5px;
}
.avepdf .validation-report-details .pdfa-xml-result.ant-collapse {
  background: #fff9f9;
  border: 1px solid rgba(102, 110, 132, 0.2);
}
.avepdf .validation-report-details .pdfa-xml-result.ant-collapse .ant-collapse-item {
  border-color: rgba(102, 110, 132, 0.2);
}
.avepdf .validation-report-details .pdfa-xml-result.ant-collapse .ant-collapse-item .ant-collapse-header {
  color: #c62828;
}
.avepdf .validation-report-details .pdfa-xml-result.ant-collapse .ant-collapse-item .ant-collapse-content {
  border-color: rgba(102, 110, 132, 0.2);
}
.avepdf .validation-report-details .pdfa-xml-result.ant-collapse .ant-collapse-item .ant-collapse-content .report-error-occurence span:first-child {
  color: #0091ea;
  margin-right: 5px;
}
.avepdf .averes-table {
  margin: 1em 0;
  min-width: 100%;
}
.avepdf .averes-table th {
  display: none;
}
.avepdf .averes-table tbody {
  border-top: 1px solid rgba(102, 110, 132, 0.2);
}
.avepdf .averes-table tbody:first-child {
  border-top: 2px solid rgba(102, 110, 132, 0.2);
}
.avepdf .averes-table tbody:before {
  content: '';
  display: block;
  height: 15px;
}
.avepdf .averes-table td {
  display: block;
}
.avepdf .averes-table td:before {
  content: attr(data-th);
  font-weight: bold;
  display: inline-block;
  margin-right: 0.5rem;
}
@media (min-width: 767px) {
  .avepdf .averes-table td:before {
    display: none;
  }
}
.avepdf .averes-table th,
.avepdf .averes-table td {
  text-align: start;
}
@media (min-width: 767px) {
  .avepdf .averes-table th,
  .avepdf .averes-table td {
    display: table-cell;
    padding: 0.25em 0.5em;
  }
}
.avepdf code {
  color: #1f1f1f;
  background: #fff;
}
.avepdf code.info-code {
  display: block;
  width: 100%;
  border: 1px solid rgba(102, 110, 132, 0.2);
  border-radius: 5px;
  padding: 15px 15px 40px;
  margin: 10px 0;
  word-wrap: anywhere;
}
.avepdf .barcode-info-wrapper {
  position: relative;
}
.avepdf .barcode-info-wrapper td {
  position: relative;
  padding: 0;
}
.avepdf .barcode-info-wrapper .ant-btn {
  position: absolute;
  padding: 5px 15px;
  border-radius: 5px 0;
  right: 0;
  bottom: 10px;
}
@media (max-width: 767.98px) {
  .avepdf .barcode-info-wrapper .ant-btn {
    bottom: 0;
  }
}
.avepdf .barcode-document-preview-wrapper {
  width: 100%;
  height: 540px;
  border: 1px solid rgba(102, 110, 132, 0.2);
}
@media (max-width: 767.98px) {
  .avepdf .barcode-document-preview-wrapper {
    height: 335px;
  }
}
@media (min-width: 1200px) {
  .avepdf .container {
    max-width: 1170px;
  }
  .avepdf .second-slider {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
.avepdf .protect-warning-label {
  font-size: 14px;
  color: #c23321;
  padding-left: 20px;
}
.avepdf .tooltip-icon {
  vertical-align: middle;
  height: 1.2em;
  width: auto;
  margin-top: -0.2em;
}
.avepdf .protect-pdf-dropdown-wrapper {
  padding-bottom: 20px;
}
.avepdf .protect-checkbox-item {
  padding-left: 0px;
  padding-right: 0px;
}
.avepdf .protect-pdf-printing-dropdown {
  width: 100%;
}
.avepdf .protect-pdf-mode-description {
  font-size: 14px;
}
.avepdf .protect-mode-col {
  padding: 40px;
}
.avepdf .protect-pdf-subtitle {
  font-size: 20px !important;
}
.avepdf .head-label {
  position: relative;
  padding-bottom: 20px;
}
.avepdf .head-label.head-label-tooltip {
  display: inline-block;
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
}
.avepdf .head-label.head-label-tooltip .head-label-tooltip-icon {
  position: absolute;
  top: 5px;
  right: 0;
}
@media (max-width: 419.98px) {
  .avepdf .head-label.head-label-tooltip .head-label-tooltip-icon {
    right: auto;
    left: 0;
  }
}
@media (max-width: 419.98px) {
  .avepdf .head-label.head-label-tooltip {
    padding-right: 0;
    padding-left: 25px;
    display: block;
    text-align: start;
  }
}
.avepdf .resolution-menu-label {
  display: flex;
  padding-bottom: 20px;
  justify-content: center;
}
.avepdf .resolution-menu-button-bar {
  justify-content: center;
  display: flex;
}
.avepdf .keep-resolution-checkbox {
  padding-top: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
}
.avepdf .keep-resolution-pdf-to-tiff-checkbox {
  padding-top: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
}
.avepdf .keep-resolution-pdf-to-jpeg-checkbox {
  padding-top: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
}
.avepdf .keep-resolution-pdf-to-jpeg-switch {
  padding-top: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
}
.avepdf .security-options-block {
  margin: 0 15px 45px;
  padding: 40px 30px 40px 30px;
  border: 1px solid rgba(160, 181, 206, 0.34);
  border-radius: 5px;
}
.avepdf .protect-password-label {
  display: inline;
}
.avepdf .protect-pdf-advanced-mode-container {
  margin: 48px auto 0;
}
.avepdf .protect-pdf-select-block {
  align-items: center;
}
.avepdf .protect-pdf-password-block {
  align-items: center;
  font-weight: bolder;
}
.avepdf .protect-pdf-block-title {
  font-size: 18px;
  background-color: #fcfdfe;
  width: max-content;
}
.avepdf .protect-pdf-switch {
  align-items: center;
}
.avepdf .thumbnail-paste-page-wrapper {
  top: -34px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  overflow: hidden;
}
.avepdf .organize-page-divider {
  width: 8px;
  height: 16rem;
  background-color: cornflowerblue;
  border: 1px solid cornflowerblue;
  border-radius: 30px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.avepdf .page-paste-icon svg {
  width: 30px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  line-height: 1;
}
.avepdf .organize-image-wrapper,
.avepdf .unable-load-page-preview {
  width: auto;
}
.avepdf .default-organize-thumbnail {
  margin: 0px 32px 35px 32px !important;
}
.avepdf .eqbox-wrapper-organize {
  width: 200px;
}
.avepdf .add-page-btn {
  background-color: #fff;
  border-color: rgba(102, 110, 132, 0.2);
  position: absolute;
  top: 50%;
  margin-top: -21px;
  max-width: 42px;
  padding: 5px;
  z-index: 10;
}
.avepdf .add-page-btn.add-page-left {
  left: -21px;
}
.avepdf .add-page-btn.add-page-right {
  right: -21px;
}
@media (max-width: 1199px) {
  .avepdf {
    /*.thumbnail-part {
        width: 24%;
    }*/
  }
  .avepdf .protect-pdf-padding-right {
    padding-right: 15px;
  }
  .avepdf .protect-pdf-divider {
    width: 100% !important;
    padding-bottom: 40px !important;
  }
  .avepdf .protect-pdf-left-block {
    padding-right: 40px;
  }
  .avepdf .protect-checkbox-item {
    padding-right: 15px;
  }
  .avepdf .protect-pdf-left-row {
    padding-bottom: 40px;
  }
  .avepdf .protect-pdf-tooltip-icon {
    padding-left: 3px;
  }
  .avepdf .markup-docuviewer-control div div :last-child .dvUi-resizable {
    width: 30% !important;
  }
  .avepdf .resolution-label {
    text-align: center !important;
  }
  .avepdf .quality-label {
    text-align: center !important;
  }
  .avepdf .slider-margin {
    margin-right: 38px !important;
  }
  .avepdf .second-slider {
    margin-left: 0px;
    margin-right: 0px;
  }
  .avepdf .flatten-col {
    margin-bottom: 30px;
  }
  .avepdf .flatten-slider {
    display: block !important;
  }
  .avepdf .flatten-slider-last {
    width: 100%;
  }
  .avepdf .flatten-col {
    max-width: 100%;
  }
  .avepdf .swiper-button-prev,
  .avepdf .swiper-container-rtl .swiper-button-next {
    left: -10px;
    width: 40px;
  }
  .avepdf .swiper-button-next,
  .avepdf .swiper-container-rtl .swiper-button-prev {
    right: -10px;
    width: 40px;
  }
  .avepdf .sign-form .forgot-pass {
    text-align: start;
    float: none;
    display: block;
  }
  .avepdf .grecaptcha-badge {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    bottom: 0 !important;
  }
  .avepdf .tools-sort .tool-item-wrapper {
    width: 24%;
  }
  .avepdf .tool-item-content {
    width: 185px;
    height: 185px;
  }
  .avepdf .small-icons .tool-item-content {
    width: 120px;
    height: 120px;
  }
  .avepdf .small-icons .tool-item-content .tool-item-description span {
    font-size: 10px;
  }
  .avepdf .coming-soon {
    font-size: 10px;
    line-height: 16px;
  }
  .avepdf .split-choose-mode {
    width: 60%;
  }
  .avepdf .signature-modal .ant-modal {
    max-width: 96%;
  }
  .avepdf .crop-modal .ant-modal {
    max-width: 96%;
  }
}
.avepdf .crop-modal .ant-modal {
  top: 10px;
}
.avepdf .select-crop-options {
  cursor: pointer;
  font-weight: bold;
  line-height: 1.43;
  color: #041632;
  margin-right: 20px;
  border: none;
}
.avepdf .crop-wrapper {
  text-align: center;
  width: 100%;
  margin: 0 10px;
}
@media (max-width: 991px) {
  .avepdf {
    /*.thumbnail-part {
        width: 48%;
    }*/
  }
  .avepdf .keep-resolution-pdf-to-jpeg-checkbox {
    padding-top: 0px !important;
  }
  .avepdf .keep-resolution-pdf-to-tiff-checkbox {
    padding-top: 0px !important;
  }
  .avepdf .protect-checkbox-item {
    padding-left: 15px;
    padding-right: 15px;
  }
  .avepdf .resolution-label {
    padding-bottom: 25px;
  }
  .avepdf .slider-margin {
    margin-right: 38px !important;
  }
  .avepdf .second-slider {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .avepdf .flatten-col {
    margin-bottom: 0px;
  }
  .avepdf .first-flatten-col {
    margin-left: 0px;
  }
  .avepdf .flatten-slider-last {
    width: auto;
  }
  .avepdf .main-wrapper {
    display: block;
    width: 100%;
  }
  .avepdf .main-content-wrapper {
    /*padding: 60px 0;*/
  }
  .avepdf .navbar-collapse {
    padding: 0;
  }
  .avepdf .navbar-nav > li > a {
    line-height: 1.375rem;
  }
  .avepdf .header-desktop {
    border-right: none;
    border-bottom: 1px solid #dfe6ec;
    padding-right: 0;
  }
  .avepdf .navbar-btn {
    display: inline-block;
    width: 49%;
    margin: 15px 0 !important;
    padding: 18px 8px;
  }
  .avepdf .swiper-button-next,
  .avepdf .swiper-button-prev {
    display: none;
  }
  .avepdf .desktop-app-screen {
    border-top: none;
    border-left: 1px solid #efefef;
  }
  .avepdf .passport-tools-dropdown {
    position: relative !important;
    top: 0 !important;
  }
  .avepdf .btn.btn-icon {
    margin: 0;
  }
  .avepdf .hr-vertical {
    position: relative;
    height: 40px;
    margin: 10px 0;
  }
  .avepdf .hr-vertical:before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #546c96;
    position: absolute;
    top: 50%;
    left: 0;
  }
  .avepdf .hr-vertical .hr-vertical-text {
    position: absolute;
    top: 0;
    width: 40px;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    font-size: 1.286em;
    height: 40px;
    line-height: 40px;
    background-color: #ffffff;
  }
  .avepdf #account-options-title {
    margin-bottom: 15px;
  }
  .avepdf .account-options-title-wrapper {
    border: none;
  }
  .avepdf .my-profile-nav {
    min-height: 0;
  }
  .avepdf .my-profile-nav-toggle {
    font-size: 1rem;
    margin: 0;
    float: none;
    vertical-align: middle;
    border: 1px solid #dfe6ec !important;
    width: 100%;
    margin-bottom: 10px;
  }
  .avepdf .social-list {
    text-align: center;
  }
  .avepdf .copyright {
    line-height: inherit;
  }
  .avepdf .language-select {
    text-align: center;
  }
  .avepdf .powered-by-wrapper {
    text-align: start;
    margin-top: 5px;
  }
  .avepdf .tools-sort .tool-item-wrapper {
    width: 31.33%;
  }
  .avepdf .tool-item-content {
    width: 170px;
    height: 170px;
  }
  .avepdf .tool-item-content .tool-item-description span {
    font-size: 14px;
  }
  .avepdf .small-icons .tool-item-content .tool-item-description span {
    font-size: 9px;
  }
  .avepdf .coming-soon {
    font-size: 9px;
    line-height: 14px;
  }
  .avepdf .document {
    width: 80%;
  }
  .avepdf .split-choose-mode {
    width: 80%;
  }
  .avepdf .not-found-header {
    font-size: 9em;
  }
}
@media (max-width: 767px) {
  .avepdf {
    /*----------------------
    Crop modal 
    -----------------------*/
    /*----------------------
    Signature modal 
    -----------------------*/
    /***********************
        TEXT TO PDF START
    ***********************/
    /***********************
        TEXT TO PDF END
    ***********************/
    /************************
        HEADER START
    ***********************/
    /************************
        HEADER END
    ***********************/
    /*----------------------
    PDF to JPG
    -----------------------*/
  }
  .avepdf .btn-filters-pdf-to-jpeg .btn {
    width: 46%;
    margin: 0px !important;
    border-radius: 5px !important;
  }
  .avepdf .resolution-menu-button {
    width: 100% !important;
  }
  .avepdf .protect-pdf-left-row {
    padding-bottom: 10px;
  }
  .avepdf .protect-pdf-left-col {
    flex-basis: 0 !important;
  }
  .avepdf .protect-checkbox-item {
    padding-right: 0px;
  }
  .avepdf .protect-pdf-dropdown-wrapper {
    padding-left: 0px;
  }
  .avepdf .protect-pdf-label-row {
    padding-bottom: 20px;
  }
  .avepdf .protect-pdf-left-block {
    padding-left: 25px;
    padding-bottom: 30px;
  }
  .avepdf .protect-pdf-left-row {
    display: flow-root;
  }
  .avepdf .protect-checkbox-item {
    padding-right: 15px;
    padding-bottom: 25px;
  }
  .avepdf .protect-mode-col {
    justify-content: center !important;
  }
  .avepdf .markup-docuviewer-control div div :last-child .dvUi-resizable {
    width: 40% !important;
  }
  .avepdf .slider-margin {
    margin-right: 38px !important;
  }
  .avepdf .second-slider {
    margin-left: 0px;
    margin-right: 0px;
  }
  .avepdf .flatten-col {
    margin-bottom: 30px;
  }
  .avepdf .flatten-slider-last {
    width: auto;
  }
  .avepdf .first-flatten-col {
    margin-left: 0px;
  }
  .avepdf .flatten-slider {
    display: block !important;
  }
  .avepdf .ant-input-number {
    height: 44px;
    width: 70px;
  }
  .avepdf .tools-link {
    text-align: center;
    display: block;
    border: 2px solid !important;
  }
  .avepdf .esign-mode-buttons {
    text-align: center !important;
    padding-bottom: 20px;
  }
  .avepdf .text-align-right {
    text-align: center !important;
  }
  .avepdf .did-you-know-subtitle {
    height: auto !important;
  }
  .avepdf .margin-left-50 {
    padding-left: 5% !important;
    text-align: start !important;
    margin: 0px !important;
  }
  .avepdf .centred-resize-text {
    padding-top: 20px;
    text-align: start !important;
  }
  .avepdf .centred-resize-text > div.pt-4.pb-5 {
    text-align: center !important;
  }
  .avepdf .margin-right-30 {
    width: 40% !important;
    margin-right: 15px !important;
  }
  .avepdf .margin-left-30 {
    width: 40% !important;
    margin-left: 15px !important;
  }
  .avepdf .resize-controls {
    width: 100% !important;
  }
  .avepdf .passport-tools-menu {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .avepdf .passport-tools-menu-open {
    position: relative;
    padding: 15px 0;
  }
  .avepdf .nav-profile > li.active > a:before {
    display: none;
  }
  .avepdf .nav-profile > li.active > a:after {
    display: none;
  }
  .avepdf .my-profile-nav .nav > li {
    margin-bottom: 0.3571rem;
  }
  .avepdf .section-row {
    padding: 30px 0;
  }
  .avepdf table.dataTable > tbody > tr.child ul.dtr-details {
    font-size: 0.6875rem;
  }
  .avepdf div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: center;
  }
  .avepdf .lead.lead-small {
    font-size: 0.875rem;
  }
  .avepdf .contact-send {
    text-align: start;
    margin-top: 0;
  }
  .avepdf .language-select .dropdown-menu {
    left: -5px;
  }
  .avepdf .end-account {
    margin-bottom: 1rem;
  }
  .avepdf .tools-sort .tool-item-wrapper {
    width: 49%;
  }
  .avepdf .btn {
    margin-bottom: 15px;
  }
  .avepdf .horizontal-tool .horizontal-tool-icon {
    width: 100%;
    max-width: 40%;
    margin: auto;
    text-align: center;
  }
  .avepdf .horizontal-tool .horizontal-tool-text {
    text-align: center;
    margin-top: 10px;
  }
  .avepdf .tool-item-content {
    width: 150px;
    height: 155px;
  }
  .avepdf .small-icons .tool-item-content {
    width: 150px;
    height: 155px;
  }
  .avepdf .small-icons .tool-item-content .tool-item-description span {
    font-size: 14px;
  }
  .avepdf .small-icons .tool-item-icon img {
    width: 60%;
  }
  .avepdf .coming-soon {
    font-size: 16px;
    line-height: 24px;
    top: 18px;
    right: -22px;
  }
  .avepdf .powered-by-wrapper {
    text-align: center;
    margin-top: 5px;
  }
  .avepdf .footer-text {
    text-align: center;
  }
  .avepdf .split-choose-mode {
    width: 100%;
  }
  .avepdf .split-mode {
    width: 48%;
  }
  .avepdf .split-instructions {
    display: none;
  }
  .avepdf .split-instructions-mobile {
    display: block;
  }
  .avepdf .signature-modal .ant-modal {
    top: 0;
  }
  .avepdf #signature canvas {
    height: 260px !important;
  }
  .avepdf .ant-slider-with-marks {
    width: 85%;
    margin: 14px auto 28px;
  }
  .avepdf .document {
    height: auto;
    left: 2%;
    bottom: 64px;
    width: 96%;
    padding: 4px 0;
  }
  .avepdf #widget-content .document {
    bottom: 20px !important;
  }
  .avepdf .document .card-block {
    padding-left: 0;
  }
  .avepdf .document-icon {
    width: 100%;
    height: auto;
    margin: 10px auto;
  }
  .avepdf .margins-icon-wrapper {
    width: 250px;
  }
  .avepdf .margins-icon-svg {
    width: 200px;
  }
  .avepdf .margin-icon-value.margin-top {
    left: 90px;
  }
  .avepdf .margin-icon-value.margin-bottom {
    left: 90px;
  }
  .avepdf .margin-icon-value.margin-left {
    left: -15px;
  }
  .avepdf .margin-icon-value.margin-right {
    right: -15px;
  }
  .avepdf .auth-buttons {
    display: none;
  }
  .avepdf .zEWidget-launcher {
    bottom: 65px !important;
    right: -12px !important;
  }
  .avepdf .protect-pdf-advanced-mode-container {
    margin: 48px auto 0;
  }
}
@media (max-width: 575px) {
  .avepdf {
    /*----------------------
    Crop modal 
    -----------------------*/
    /*----------------------
    Extract PDF Pages 
    -----------------------*/
    /*----------------------
    PDF to JPG
    -----------------------*/
  }
  .avepdf .ant-slider-with-marks {
    width: 70%;
  }
  .avepdf .protect-pdf-left-col {
    padding-left: 41px;
  }
  .avepdf .slider-margin {
    margin-right: 38px !important;
  }
  .avepdf .second-slider {
    margin-left: 0px;
    margin-right: 0px;
  }
  .avepdf .flatten-slider-last {
    width: auto;
  }
  .avepdf .first-flatten-col {
    margin-left: 0px;
  }
  .avepdf .navbar-default .navbar-brand {
    width: 220px;
  }
  .avepdf .navbar-default .navbar-brand span {
    margin-left: 58px;
    font-size: 0.75em;
    position: relative;
  }
  .avepdf .nav-tabs .nav-link {
    border: 1px solid #dee2e6;
    margin-bottom: 10px;
    border-radius: 0.25rem;
  }
  .avepdf .nav-tabs .nav-link.active,
  .avepdf .nav-tabs .nav-item.show .nav-link {
    border-color: #dee2e6;
    background-color: #f2f2f2;
    border-radius: 0.25rem;
  }
  .avepdf .tool-item {
    margin: 5px;
  }
  .avepdf .btn {
    margin-bottom: 15px;
  }
  .avepdf .btn.btn-alt {
    padding: 16px 8px;
  }
  .avepdf .input-group-btn > .btn {
    margin-bottom: 0px;
  }
  .avepdf .btn-sm,
  .avepdf .btn-group-sm > .btn {
    padding: 14px 14px;
  }
  .avepdf .main-slider .carousel-inner .carousel-item-content .caption-content .btn-group > .btn,
  .avepdf .main-slider .carousel-inner .carousel-item-content .caption-content .btn-group-vertical > .btn {
    margin-bottom: 0;
  }
  .avepdf .main-slider .carousel-inner .carousel-item-content .caption-content .btn-group > .btn,
  .avepdf .main-slider .carousel-inner .carousel-item-content .caption-content .btn-group-vertical > .btn {
    padding: 12px 16px !important;
  }
  .avepdf .main-header {
    -webkit-background-size: cover !important;
    /* For WebKit*/
    -moz-background-size: cover !important;
    /* Mozilla*/
    -o-background-size: cover !important;
    /* Opera*/
    background-size: cover !important;
    /* Generic*/
  }
  .avepdf code,
  .avepdf kbd,
  .avepdf pre,
  .avepdf samp {
    word-wrap: break-word;
  }
  .avepdf #rc-imageselect,
  .avepdf .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
  .avepdf .btn.g-recaptcha {
    transform: scale(1);
    -webkit-transform: scale(1);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
  .avepdf .generic-error-wrapper .panel-border {
    -webkit-background-size: 100% auto !important;
    /* For WebKit*/
    background-size: 100% auto !important;
    /* Generic*/
  }
  .avepdf .end-account li {
    border-left: none;
    padding-left: 0;
  }
  .avepdf .sign-form .forgot-pass {
    float: none;
    display: block;
  }
  .avepdf .footer-menu li {
    margin-bottom: 0.9375rem;
  }
  .avepdf .thumbnail-part {
    width: 100%;
    height: 50%;
    margin: 10px auto;
    max-width: 90%;
    padding: 40px 10px 10px;
    display: block;
  }
  .avepdf .thumbnail-image-wrapper {
    min-height: 190px;
  }
  .avepdf .thumbnail-number {
    margin: 0px;
  }
  .avepdf .thumbnail-image-wrapper,
  .avepdf .unable-load-page-preview {
    width: 100%;
  }
  .avepdf .thumbnail-image-wrapper canvas,
  .avepdf .unable-load-page-preview {
    width: 210px;
  }
  .avepdf .protect-pdf-password {
    width: 80%;
  }
  .avepdf .protect-pdf-confirm-password {
    margin-left: 0;
    margin-top: 15px;
  }
  .avepdf .action-button.rotate-apply-button {
    display: inline-block;
  }
  .avepdf .split-choose-mode {
    flex-direction: column;
  }
  .avepdf .split-mode {
    width: 55%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .avepdf .not-found-header {
    font-size: 8em;
    line-height: 80px;
  }
  .avepdf .upload-file-methods-small {
    display: block !important;
  }
  .avepdf #successArea .downloaded-wrapper {
    padding-bottom: 0;
    margin-bottom: 20px;
  }
  .avepdf .horizontal-tool {
    margin-bottom: 30px;
  }
  .avepdf .horizontal-tool .horizontal-tool-description {
    font-size: 13px;
    line-height: 1.5;
  }
  .avepdf .horizontal-tool .horizontal-tool-icon {
    max-width: 100%;
  }
  .avepdf .horizontal-tool .horizontal-tool-text {
    text-align: start;
    margin-top: 0;
  }
  .avepdf .keep-resolution-pdf-to-tiff-checkbox,
  .avepdf .keep-resolution-pdf-to-jpeg-checkbox {
    padding: 0 10px 10px;
  }
  .avepdf .keep-resolution-pdf-to-tiff-checkbox .fas,
  .avepdf .keep-resolution-pdf-to-jpeg-checkbox .fas {
    display: initial;
  }
}
@media (max-width: 575px) and (max-width: 767.98px) {
  .avepdf .thumbnail-image-wrapper canvas,
  .avepdf .unable-load-page-preview {
    padding: 15px;
  }
}
@media (max-width: 375px) {
  .avepdf .markup-docuviewer-control div div :last-child .dvUi-resizable {
    width: 45% !important;
  }
  .avepdf .slider-margin {
    margin-right: 0px !important;
  }
  .avepdf .second-slider {
    margin-left: 0px;
    margin-right: 0px;
  }
  .avepdf .flatten-slider-last {
    width: auto;
  }
  .avepdf .first-flatten-col {
    margin-left: 0px;
  }
  .avepdf .end-account li {
    border-left: none;
    padding-left: 0;
  }
  .avepdf .action-button.rotate-apply-button {
    display: inline-block;
    margin: 10px 0px;
  }
  .avepdf .split-mode {
    width: 100%;
  }
  .avepdf .split-simple-mode-select-label {
    font-size: 10px;
  }
}
@media (max-width: 320px) {
  .avepdf .markup-docuviewer-control div div :last-child .dvUi-resizable {
    width: 50% !important;
  }
  .avepdf #signature canvas {
    height: 160px !important;
  }
}
.avepdf .upload-more {
  margin: 0 15px 0 0;
}
@media (max-width: 576px) {
  .avepdf .upload-more {
    display: inline-block;
    margin: 0 0 15px;
  }
}
@media (max-width: 576px) {
  .avepdf .home-header {
    font-size: 26px;
    line-height: 1.2;
  }
  .avepdf .tool-item-wrapper a {
    margin: 0;
  }
  .avepdf .tool-item-wrapper .tool-item {
    text-align: start;
    padding: 0 0 10px;
    margin: 0;
  }
  .avepdf .tool-item-content,
  .avepdf .small-icons .tool-item-content {
    width: 100%;
    height: auto;
  }
  .avepdf .tool-item-content.rounded-icon,
  .avepdf .small-icons .tool-item-content.rounded-icon {
    border-radius: 5px;
  }
  .avepdf .tool-item-icon {
    display: inline-block;
    text-align: center;
    max-width: 80px;
    height: auto;
  }
  .avepdf .tool-item-description {
    display: inline-block;
  }
  .avepdf .coming-soon {
    font-size: 12px;
    top: 4px;
    right: -6px;
  }
  .avepdf .ant-modal-body {
    padding: 30px 10px;
  }
  .avepdf .ant-modal {
    max-width: 96%;
    margin-left: auto;
    margin-right: auto;
  }
}
.avepdf .flexbox-item-grow {
  flex: 1;
  /* same as flex: 1 1 auto; */
}
.avepdf .fill-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* align items in Main Axis */
  align-items: stretch;
  /* align items in Cross Axis */
  align-content: stretch;
  /* Extra space in Cross Axis */
}
.avepdf .fill-area-content {
  /* Needed for when the area gets squished too far and there is content that can't be displayed */
  /*overflow: auto;*/
}
.avepdf .simple-modal-error-wrapper button {
  border-color: inherit;
}
.avepdf .simple-modal-error-wrapper span {
  text-transform: none;
}
.avepdf .simple-modal-error-wrapper .ant-modal-content {
  border-radius: 5px;
}
.avepdf .simple-modal-error-wrapper .ant-modal-content .ant-modal-header {
  border-radius: 5px 5px 0 0;
}
.avepdf .simple-modal-error-wrapper .ant-modal-content .ant-modal-title {
  font-size: 18px;
}
.avepdf .simple-modal-error-wrapper .anticon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  vertical-align: middle;
}
.avepdf .simple-modal-error-wrapper .anticon svg {
  height: 100%;
  width: 100%;
}
.avepdf .simple-modal-error-wrapper .small-error-content {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 1em;
  max-height: 290px;
  min-height: 128px;
  overflow: auto;
}
@media (max-width: 576px) {
  .avepdf .simple-modal-error-wrapper .small-error-content {
    max-height: 190px;
  }
}
.avepdf .icon-fill-upload,
.avepdf .icon-fill-upload:hover,
.avepdf .icon-fill-upload:focus {
  background: #bc4bc9;
}
.avepdf .icon-fill-download,
.avepdf .icon-fill-download:hover,
.avepdf .icon-fill-download:focus {
  background: #bc4bc9;
}
.avepdf .icon-fill-document,
.avepdf .icon-fill-document:hover,
.avepdf .icon-fill-document:focus {
  background: #bc4bc9;
}
.avepdf .icon-fill-loading,
.avepdf .icon-fill-loading:hover,
.avepdf .icon-fill-loading:focus {
  background: #bc4bc9;
}
.avepdf .coming-soon-icon {
  opacity: 0.5;
}
.avepdf .response-error-wrapper {
  margin: auto;
}
.avepdf .unlock-offer p,
.avepdf .unlock-offer-link {
  text-align: center;
}
.avepdf .width_100_per {
  width: 100%;
}
.avepdf .height_100_per {
  height: 100%;
}
.avepdf .document-thumbnail {
  width: 100px;
  height: 100px;
  display: block;
  margin: 16px;
}
.avepdf .not-found-header {
  font-size: 10em;
  line-height: 130px;
  font-weight: bold;
  padding-bottom: 24px;
}
.avepdf .not-found {
  padding-top: 76px;
  text-align: start;
}
.avepdf .not-found-title {
  font-size: 38px;
  line-height: 48px;
  font-weight: bold;
  padding-bottom: 12px;
}
.avepdf .not-found-subtitle {
  padding-bottom: 30px;
}
.avepdf .not-found-action,
.avepdf .not-found-action:hover,
.avepdf .not-found-action:focus {
  background: #000000;
  color: #ffffff;
}
.avepdf .not-found-image img {
  width: 100%;
}
.avepdf .reload {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  border: 1px solid rgba(102, 110, 132, 0.2);
  padding: 12px;
  border-radius: 5px;
  width: 48px;
  height: 48px;
}
.avepdf .reload svg {
  vertical-align: baseline;
}
.avepdf .reload svg path {
  fill: #1d7c76;
}
@media (max-width: 767.98px) {
  .avepdf .reload {
    right: 15px;
    top: 15px;
  }
}
.avepdf .ellipsis-lines {
  overflow: hidden;
  position: relative;
  line-height: 1.2em;
  max-height: 3em;
  margin-right: -1em;
  padding-right: 1em;
  word-break: break-word;
  right: 2px;
}
.avepdf .auth-container-block {
  background-color: #fff;
  border-radius: 0;
  overflow: hidden;
}
.avepdf .auth-tabs a button {
  background-color: transparent;
  color: #1f1f1f;
  border: none;
}
.avepdf .auth-tabs a:first-child button {
  border-top-left-radius: 7px;
}
.avepdf .auth-tabs a:last-child button {
  border-top-right-radius: 7px;
  border-right: none;
}
.avepdf .auth-tabs a button.active {
  background-color: transparent;
  border-bottom: 1px solid #fff;
}
.avepdf .auth-socials {
  text-align: start;
}
.avepdf .auth-socials button {
  border-radius: 5px;
}
.avepdf .auth-socials svg.social-icon path {
  fill: #fff;
}
.avepdf .auth-socials .social-icon-label {
  margin-left: 50px;
}
@media (max-width: 320px) {
  .avepdf span.ant-radio + * {
    padding-left: 5px !important;
    margin-bottom: 15px !important;
  }
}
.avepdf .auth-socials-separator {
  background-color: rgba(102, 110, 132, 0.2);
}
.avepdf .auth-socials-separator-text-wraper span {
  color: #1f1f1f;
  background-color: #fff;
}
.avepdf .auth-form .ant-row .ant-col {
  width: 100%;
}
.avepdf .auth-form .ant-input {
  border: none;
  border-radius: 0 !important;
}
.avepdf .auth-form .ant-input:focus,
.avepdf .auth-form .ant-input:hover {
  border: none;
}
.avepdf .auth-form .has-error {
  color: #ff0000;
}
.avepdf .auth-form .has-error .ant-legacy-form-explain {
  font-size: 14px;
}
.avepdf .auth-form .ant-form-item {
  margin-bottom: 30px;
}
.avepdf .auth-form .ant-form-item-with-help {
  margin-bottom: 11px;
}
.avepdf .auth-form .ant-form-item:last-child {
  margin-bottom: 0;
}
.avepdf .auth-form .ant-form-item-with-help:last-child {
  margin-bottom: -19px;
}
.avepdf .auth-form .ant-form-item-with-help .ant-input,
.avepdf .auth-form .ant-form-item-with-help .ant-input:hover,
.avepdf .auth-form .ant-form-item-with-help .ant-input:focus {
  border: 1px solid red;
  color: red;
}
.avepdf .auth-form .ant-form-item input.ant-input::placeholder,
.avepdf .auth-form .ant-form-item input.ant-input::placeholder {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.2s, opacity 0.2s linear;
}
.avepdf .auth-form .ant-form-item:hover input.ant-input::placeholder,
.avepdf .auth-form .ant-form-item:focus input.ant-input::placeholder {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s linear;
}
.avepdf .auth-form .ant-input-prefix img {
  width: 17px;
}
.avepdf .auth-form .ant-form-item-with-help .ant-input-prefix svg {
  fill: red;
}
.avepdf .auth-form .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}
.avepdf .auth-form .ant-checkbox-checked .ant-checkbox-inner::after {
  -webkit-transform: rotate(50deg) scale(2) translate(-10%, -45%);
  -ms-transform: rotate(50deg) scale(2) translate(-10%, -45%);
  transform: rotate(50deg) scale(2) translate(-10%, -45%);
  left: 6%;
}
.avepdf .auth-form .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.avepdf .auth-form .ant-checkbox:hover .ant-checkbox-inner,
.avepdf .auth-form .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #1f1f1f;
}
.avepdf .auth-form .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1f1f1f;
  border-color: #1f1f1f;
}
.avepdf .auth-form .ant-checkbox-checked::after {
  border: none;
}
.avepdf .auth-form .remember-checkbox {
  color: #1f1f1f;
  align-items: center;
}
.avepdf .auth-form .login-forgot-password {
  text-decoration: underline;
  color: #1f1f1f;
  font-size: 14px;
}
.avepdf .auth-form .input-corner-label {
  transition: visibility 0.2s, opacity 0.2s linear;
  border-radius: 0 0 5px 5px;
}
.avepdf .auth-form .ant-form-item-with-help .input-corner-label {
  color: red;
}
.avepdf .auth-form .ant-form-item:hover .input-corner-label,
.avepdf .auth-form .ant-form-item:focus .input-corner-label {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.2s linear;
}
.avepdf .auth-action-button-block button {
  background-color: #121212;
  border-radius: 5px;
}
.avepdf .auth-action-button-block button:disabled {
  cursor: not-allowed;
  color: #6c6c6c;
  background-color: rgba(0, 0, 0, 0.08);
  box-shadow: none;
}
.avepdf .auth-action-button-block .bottom-colored-border {
  display: block;
}
.avepdf .auth-action-button-block .bottom-colored-border img {
  display: block;
  border-radius: 0 0 5px 5px;
}
.avepdf .profile-dropdown-button {
  font-size: 16px;
  font-weight: 500;
}
.avepdf .profile-dropdown-button a {
  color: #1f1f1f;
}
.avepdf .profile-dropdown-button a:focus,
.avepdf .profile-dropdown-button a:hover {
  color: #1f1f1f;
}
.avepdf .profile-dropdown-button .dropdown-icon {
  padding-left: 10px;
  vertical-align: 0.125em;
}
.avepdf .profile-dropdown {
  font-size: 16px;
  font-weight: 400;
}
.avepdf .profile-dropdown .dropdown-iteam {
  margin-left: 30px;
  margin-right: 30px;
}
.avepdf .profile-dropdown .dropdown-iteam .disabled {
  cursor: not-allowed;
  margin-left: 30px;
  margin-right: 30px;
  color: #707070;
}
.avepdf .profile-dropdown .icon-dropdown {
  width: 20px !important;
  margin-right: 10px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  vertical-align: middle !important;
}
.avepdf .profile-dropdown .icon-dropdown path {
  fill: #1f1f1f;
}
.avepdf .locked-error-buttons {
  margin-right: 30px;
}
.avepdf .loading-centred {
  margin: 0 auto;
}
.avepdf .tool-DV-container {
  width: 100%;
}
.avepdf .tools-actions-row {
  background: #fff;
  border-top: 1px dashed rgba(102, 110, 132, 0.8);
  border-bottom: 1px dashed rgba(102, 110, 132, 0.8);
  margin: 0;
}
.avepdf .tools-rotate-row {
  margin: 0;
}
.avepdf .fixed-action-row {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  border-bottom: none !important;
  border-radius: 0 0 5px 5px;
  z-index: 100;
}
@media (max-width: 1200px) {
  .avepdf .fixed-action-row {
    max-width: 924px;
  }
}
@media (max-width: 992px) {
  .avepdf .fixed-action-row {
    max-width: 684px;
  }
}
@media (max-width: 764px) {
  .avepdf .fixed-action-row {
    max-width: 504px;
  }
}
@media (max-width: 576px) {
  .avepdf .fixed-action-row {
    max-width: 100%;
  }
}
.avepdf .auth-buttons.profile-dropdown-button a.logged-user-text {
  line-height: 40px;
  display: initial;
  background-color: initial !important;
  padding: initial;
  border-radius: initial;
}
.avepdf ul.logged-in-hamburger-menu li {
  margin-bottom: 0;
}
@media (max-width: 992px) {
  .avepdf .picker-dialog {
    top: 75px !important;
    height: 580px !important;
    width: 99% !important;
  }
  .avepdf .picker-dialog-content {
    height: 565px !important;
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  .avepdf .logged-user-image {
    width: 32px;
    height: 32px;
  }
  .avepdf .profile-dropdown-button .dropdown-icon {
    padding-left: 2px;
    vertical-align: -0.025em;
  }
}
@media (max-width: 320px) {
  .avepdf .picker-dialog {
    top: 50px !important;
    height: 480px !important;
    width: 99% !important;
  }
  .avepdf .picker-dialog-content {
    height: 465px !important;
    width: 100% !important;
  }
}
.avepdf #dv-viewer-container {
  height: 780px !important;
}
@media only screen and (max-width: 992px) {
  .avepdf #dv-viewer-container {
    height: 500px !important;
  }
}
@media only screen and (max-width: 480px) {
  .avepdf #dv-viewer-container {
    height: 450px !important;
  }
}
.avepdf .document-viewer-loading-wrapper {
  display: table;
  text-align: center;
}
.avepdf .document-viewer-loading-wrapper .document-viewer-loader {
  width: 200px;
  top: 50%;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: -100px auto 0;
}
.avepdf .document-viewer-loading-wrapper .document-viewer-loader img {
  width: 150px;
}
.avepdf .apply-cutom-angle {
  padding: 0;
}
.avepdf .apply-cutom-angle input {
  border: none;
  width: 50%;
  height: 100%;
  text-align: center;
}
.avepdf .apply-cutom-angle-input {
  border-radius: 5px 0 0 5px;
}
.avepdf .apply-cutom-angle-input.invalid {
  color: red;
}
.avepdf .apply-custom-angle-button {
  border-radius: 0 5px 5px 0;
  background: #1f1f1f;
  color: #fff;
  transition: all 0.3s;
}
.avepdf .apply-custom-angle-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.avepdf .layout-thumbnails {
  padding: 0;
}
.avepdf .docuvieware-layout {
  position: relative;
  overflow-y: hidden;
  display: flex;
  z-index: 1;
}
@media (max-width: 768px) {
  .avepdf .avepdf-toolbar .shape-container {
    margin: auto;
  }
  .avepdf .text-align-center .remove-file-title {
    padding-bottom: 0;
  }
}
.avepdf .shape-container {
  margin: auto 15px;
}
.avepdf .pdf-edit-selected-annotation-toolbar .shape-toolbar-button {
  width: 2rem;
  height: 2rem;
  margin: 0;
  background: #ffffff;
}
.avepdf .pdf-edit-selected-annotation-toolbar .shape-toolbar-button span {
  width: 1.5rem;
  top: -12px;
  position: relative;
  left: -12px;
  opacity: 0.3;
}
.avepdf .additional-options-container {
  display: flex;
  flex-direction: row;
  margin-left: 16rem;
  align-items: center;
}
.avepdf .ant-dropdown .ant-dropdown-menu .shape-menu-item:hover {
  background-color: #d3fbff;
}
.avepdf .ant-dropdown-menu-item-divider {
  margin: 0;
}
.avepdf .shape-button-menu-item {
  border-radius: 0;
  border: 0;
}
.avepdf .ant-dropdown .ant-dropdown-menu .shape-menu-item .shape-button-menu-item:hover {
  background-color: #d3fbff;
}
.avepdf .move-arrow-button span img {
  left: 1px;
  top: 1px;
}
.avepdf .margin-0 {
  margin: 0 !important;
}
.avepdf .remove-file-title {
  padding-top: 60px;
  padding-bottom: 0;
}
.avepdf .padding-0 {
  padding: 0 !important;
}
.avepdf .overflow-visible {
  overflow: visible;
}
.avepdf .file-actions-layout {
  width: 100%;
  text-align: center;
}
.avepdf .shape-button {
  padding: 6px;
}
.avepdf .shape-button svg {
  width: 23px;
  height: 23px;
  position: relative;
}
.avepdf .shape-button:focus {
  background-color: inherit !important;
  border-color: #d9d9d9 !important;
}
@media (max-width: 767.98px) {
  .avepdf .shape-button {
    padding: 10px;
  }
}
.avepdf .avepdf-toolbar .shape-button span:not(.async-svg-container) {
  left: -12px;
  width: 2rem;
}
.avepdf .avepdf-toolbar .shape-button svg {
  fill: #1f1f1f;
}
.avepdf .pdf-edit-selected-annotation-toolbar .floating-toolbar-btn svg path {
  fill: inherit;
}
.avepdf .pdf-edit-selected-annotation-toolbar {
  z-index: 170000000;
  position: absolute;
  left: 40%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.avepdf .pdf-edit-selected-annotation-toolbar .color-picker-button {
  display: flex;
}
.avepdf .pdf-edit-selected-annotation-toolbar .shape-toolbar-button .color-picker-display {
  width: 70%;
  height: 70%;
  margin: auto;
}
.avepdf .border-style-dropdown-item {
  background-position: 1px 10px;
  background-repeat: repeat-x;
  padding: 0;
  height: 26px;
  width: 80px;
}
.avepdf [class*="border-width-"] {
  background-position: 1px 3px;
}
.avepdf .popover {
  position: absolute;
  z-index: 180000000;
}
.avepdf .cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.avepdf .color-picker-button {
  width: 2.5rem;
  height: 2.5rem;
  margin: auto 2px;
  border-radius: 5px;
  border: 1px solid;
}
.avepdf .color-picker-display {
  width: 100%;
  height: 100%;
  min-height: 26px;
  border-radius: 5px;
}
.avepdf .color-picker-cross {
  width: 1rem;
  top: -2px;
  left: -6px;
}
.avepdf .color-picker-cross img {
  height: 2rem;
}
.avepdf .pdf-edit-selected-annotation-toolbar .shape-toolbar-button .color-picker-cross {
  width: 1rem;
  top: 0.1rem;
  left: 0.2rem;
  opacity: 1;
}
.avepdf .pdf-edit-selected-annotation-toolbar .shape-toolbar-button .color-picker-cross img {
  height: 1.5rem;
}
.avepdf .color-picker-wrapper {
  padding: 10px 10px 0;
  box-sizing: initial;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 8px 16px rgba(0, 0, 0, 0.15);
}
.avepdf .color-picker-saturation-container {
  width: 100%;
  padding-bottom: 75%;
  position: relative;
  overflow: hidden;
}
.avepdf .color-picker-controls {
  display: flex;
}
.avepdf .color-picker-sliders {
  padding: 4px 0;
  flex: 1;
}
.avepdf .color-picker-hue-container {
  position: relative;
  height: 10px;
  overflow: hidden;
}
.avepdf .color-picker-alpha-container {
  position: relative;
  height: 10px;
  margin-top: 4px;
  overflow: hidden;
}
.avepdf .color-picker-color-container {
  width: 24px;
  height: 24px;
  position: relative;
  margin-top: 4px;
  margin-left: 4px;
  border-radius: 3px;
}
.avepdf .color-picker-active-color {
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 0 4px rgba(0, 0, 0, 0.25);
}
.avepdf .color-picker-colors-container {
  margin: 0 -10px;
  padding: 10px 0 0 10px;
  border-top: 1px solid #eee;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.avepdf .color-none-button {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.avepdf .color-picker-swatch-wrap {
  width: 16px;
  height: 16px;
  margin: 0 10px 10px 0;
}
.avepdf .color-picker-swatch {
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
}
.avepdf .color-picker-active-swatch {
  outline: 2px solid #6ed7ff;
}
.avepdf .color-none-svg {
  position: relative;
  top: -7px;
  right: 7px;
  width: 16px;
  height: 16px;
}
.avepdf .zoom-wrapper {
  margin-left: auto !important;
}
.avepdf .trash-image {
  height: 1.5rem;
}
.avepdf .shape-dropdown-active {
  background-color: #beebff;
}
.avepdf .resize-controls {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  justify-content: center;
}
.avepdf .resize-button {
  margin: auto 10px;
}
.avepdf .resize-input-height {
  margin-left: 0;
}
.avepdf .resize-homometric-image {
  position: relative;
  opacity: 1;
  height: 20px;
  bottom: 6px;
  right: 5px;
}
.avepdf .border-style-solid {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='6' width='32'><g fill='none' stroke='black'><path stroke-width='2' d='M0 4 l32 0' /></g></svg>");
}
.avepdf .border-style-dash {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='6' width='32'><g fill='none' stroke='black'><path stroke-width='2' stroke-dasharray='8,4' d='M0 4 l32 0' /></g></svg>");
}
.avepdf .border-style-dot {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='6' width='32'><g fill='none' stroke='black'><path stroke-width='2' stroke-dasharray='2,3' d='M0 4 l32 0' /></g></svg>");
}
.avepdf .border-style-dashdot {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='6' width='32'><g fill='none' stroke='black'><path stroke-width='2' stroke-dasharray='6,2,2,2' d='M0 4 l32 0' /></g></svg>");
}
.avepdf .border-style-dashdotdot {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='6' width='32'><g fill='none' stroke='black'><path stroke-width='2' stroke-dasharray='6,2,2,2,2,4' d='M0 4 l32 0' /></g></svg>");
}
.avepdf .border-width-extrathin {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='15' width='32'><g fill='none' stroke='black'><path stroke-width='2' d='M0 10 l32 0' /></g></svg>");
}
.avepdf .border-width-thin {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='15' width='32'><g fill='none' stroke='black'><path stroke-width='4' d='M0 10 l32 0' /></g></svg>");
}
.avepdf .border-width-normal {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='15' width='32'><g fill='none' stroke='black'><path stroke-width='6' d='M0 10 l32 0' /></g></svg>");
}
.avepdf .border-width-strong {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='15' width='32'><g fill='none' stroke='black'><path stroke-width='8' d='M0 10 l32 0' /></g></svg>");
}
.avepdf .border-width-verystrong {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='15' width='32'><g fill='none' stroke='black'><path stroke-width='10' d='M0 10 l32 0' /></g></svg>");
}
.avepdf .text-style-regular {
  background-image: url("./assets/images/regular_text.png");
}
.avepdf .text-style-bold {
  background-image: url("./assets/images/bold-text.png");
}
.avepdf .text-style-italic {
  background-image: url("./assets/images/italic-text.png");
}
.avepdf .ant-modal-wrap .text-annotation-modal {
  max-width: calc(100vw - 16px);
}
.avepdf .text-annotation-input {
  width: 30%;
}
.avepdf .text-annotation-button {
  margin: 10px auto;
}
.avepdf .text-annotation-style-item {
  display: flex;
  align-items: center;
  padding-left: 35px;
}
.avepdf .col-12 .text-annotation-textarea {
  resize: none;
  height: 150px;
  width: 100%;
}
.avepdf .align-text-center .text-annotation-font-style {
  width: 130px;
}
.avepdf .text-annotation-menu {
  max-height: 250px;
  overflow-y: scroll;
}
.avepdf .text-annotation-font-name-label {
  position: relative;
  width: 180px;
  height: 2.5rem;
  padding: 5px;
  word-wrap: normal;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
}
.avepdf .text-annotation-font-name-label::after {
  text-align: center;
  content: "";
  position: absolute;
  top: 15px;
  font-size: 14px;
  padding: 3px;
  border: solid;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  right: 20px;
}
.avepdf .text-annotation-font-name-label span {
  display: block;
  margin: auto;
  white-space: nowrap;
}
.avepdf .flatten-pages-dropdown {
  position: relative;
  background: #ffffff;
  width: 40px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  display: flex;
}
.avepdf .text-annotation-input-number .ant-input-number-handler-wrap {
  opacity: 1;
}
.avepdf .pdf-edit-text-annotation-toolbar .text-color-picker {
  border-radius: 0;
  display: flex;
}
.avepdf .pdf-edit-text-annotation-toolbar .text-color-picker .color-picker-display {
  width: 70%;
  height: 70%;
  margin: auto;
}
.avepdf .pdf-edit-selected-annotation-toolbar .shape-button .svg-small-icon {
  width: 1.4rem;
}
.avepdf .toolbar-color-picker {
  display: flex;
  justify-content: center;
  height: 2.5rem;
  padding: 0 5px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}
.avepdf .toolbar-color-picker .color-picker-button {
  width: 1.6rem;
  height: 1.6rem;
}
.avepdf .toolbar-color-picker .toolbar-color-picker-title {
  font-size: 13px;
  margin: auto;
  padding: 0 5px;
}
.avepdf .mobile-container {
  display: none;
}
.avepdf .button-container {
  display: flex;
}
@media (max-width: 991px) {
  .avepdf .button-container {
    width: 60%;
    flex-wrap: wrap;
  }
  .avepdf .button-container .shape-button {
    border-radius: 5px;
    margin: 0.15rem;
  }
}
.avepdf .shape-selection-toolbar-popover {
  width: auto;
  padding-top: 4px;
}
.avepdf .shape-selection-toolbar-popover .ant-popover-inner {
  background-color: #fafbfd;
  border-radius: 5px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.avepdf .shape-selection-toolbar-popover .ant-popover-inner-content {
  padding: 0px;
  border-radius: 5px;
  border: 1px solid rgba(102, 110, 132, 0.2);
}
.avepdf .ant-popover-arrow-content {
  background-color: transparent;
  box-shadow: none !important;
}
.avepdf .ant-popover-arrow-content:before {
  background-color: transparent;
}
.avepdf .ant-popover-inner-content .shape-button {
  margin: 3px 3px;
}
.avepdf .shape-selection-toolbar-popover .ant-popover-content .ant-popover-arrow {
  top: -7px;
  background: #fafbfd;
  border-left: 1px solid rgba(102, 110, 132, 0.2);
  border-top: 1px solid rgba(102, 110, 132, 0.2);
  transform: translateY(0px) rotate(45deg);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 14px;
  height: 14px;
}
.avepdf .text-align-right {
  text-align: end;
}
.avepdf .text-align-left {
  text-align: start;
}
.avepdf .esign-signature-toolbar {
  padding-top: 20px;
  text-align: start !important;
}
.avepdf .margin-0 {
  margin: 0px !important;
}
.avepdf .popupLink {
  color: #42a5f5;
}
.avepdf .popup-file-formats {
  padding-top: 5%;
  padding-bottom: 5%;
  /* border: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0; */
}
.avepdf .popup-format-column-on {
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-top: 1px solid #e8e8e8;
  border-radius: 5px 5px 0 0;
  padding-top: 20px;
  padding-bottom: 20px;
}
.avepdf .popup-format-column-off {
  border-bottom: 1px solid #e8e8e8;
  border-radius: 5px 5px 0 0;
  padding-top: 20px;
  padding-bottom: 20px;
}
.avepdf .popup-format-block-left {
  border-left: 1px solid #e8e8e8;
  padding-top: 30px;
  padding-bottom: 0px !important;
}
.avepdf .popup-format-block-left-last {
  border-left: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 5px 5px;
  padding-top: 30px;
  padding-bottom: 0px !important;
}
.avepdf .popup-format-block-right {
  border-right: 1px solid #e8e8e8;
  padding-top: 30px;
  padding-bottom: 0px !important;
}
.avepdf .popup-format-block-right-last {
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 5px 5px;
  padding-top: 30px;
  padding-bottom: 0px !important;
}
.avepdf .no-padding {
  padding: 0px;
}
.avepdf .popup-modal .ant-modal-content .ant-modal-footer {
  padding: 0px;
  border-top: none;
}
.avepdf .padding-right-0 {
  padding-right: 0px !important;
}
.avepdf .side-arrow {
  padding-right: 8px;
}
.avepdf .esign-mode-buttons {
  text-align: start;
  margin: 0 !important;
}
.avepdf .text-annotation-collapse .ant-collapse-item {
  border-bottom: 0;
}
.avepdf .align-items-end {
  align-items: flex-end;
}
.avepdf .signature-draw-wrapper {
  max-width: 100%;
  margin: 0 auto;
  top: 12px;
}
.avepdf .draw-signature-buttons {
  justify-content: flex-start !important;
}
.avepdf .draw-signature-buttons div .action-button {
  padding-right: 20px;
  padding-left: 20px;
}
.avepdf .display-none {
  display: none;
}
.avepdf .esign-box {
  width: 100%;
  height: 100%;
  position: relative;
}
.avepdf .file-picker-button div {
  width: 100%;
  display: flex;
}
.avepdf .file-picker-button .ant-btn {
  background: inherit;
  width: 100%;
  border: none;
  border-radius: 0;
  line-height: 2;
}
.avepdf .file-picker-button .ant-btn span {
  font-size: 12px;
  color: #1f1f1f;
}
.avepdf .file-picker-button .ant-btn span svg path {
  fill: #1f1f1f;
}
.avepdf .file-picker-button .ant-btn:hover,
.avepdf .file-picker-button .ant-btn:focus {
  background-color: #1f1f1f;
}
.avepdf .file-picker-button .ant-btn:hover span,
.avepdf .file-picker-button .ant-btn:focus span {
  color: #fff;
}
.avepdf .file-picker-button .ant-btn:hover svg path,
.avepdf .file-picker-button .ant-btn:focus svg path {
  fill: #fff;
}
.avepdf .file-picker-button:first-child:not(:last-child) .ant-btn {
  border-radius: 5px 5px 0 0;
}
.avepdf .file-picker-button:last-child:not(:first-child) .ant-btn {
  border-radius: 0 0 5px 5px;
}
.avepdf .file-picker-button-title {
  margin: auto 0 auto 8px;
}
.avepdf .file-upload-popover {
  display: flex;
  flex-direction: column;
}
.avepdf .file-upload-popover button {
  text-align: start;
}
.avepdf .shape-toolbar-image-wrapper {
  display: flex;
  flex-direction: row;
  height: 2rem;
  width: max-content;
  font-size: 14px;
  background: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}
.avepdf .shape-toolbar-image-opacity {
  margin: 9px 15px;
  margin-left: 5px;
  width: 50px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.avepdf .shape-toolbar-image-wrapper span {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.avepdf .shape-toolbar-image-opacity .ant-slider-rail {
  height: 5px;
}
.avepdf .shape-toolbar-image-opacity .ant-slider-handle {
  border: 0;
  width: 10px;
  height: 10px;
  margin-top: -2.5px;
}
.avepdf .shape-toolbar-image-opacity-icon {
  margin: 23px 3px;
  margin-right: 20px;
  width: 50px;
}
.avepdf .shape-toolbar-image-opacity-icon .ant-slider-step {
  height: 8px;
}
.avepdf .shape-toolbar-image-opacity-icon .ant-slider-rail {
  height: 8px;
}
.avepdf .shape-toolbar-image-opacity-icon .ant-slider-handle {
  border: 0;
  margin-top: -2.5px;
}
.avepdf .certificate-modal div div .ant-modal-footer {
  text-align: center;
}
.avepdf .slider-value {
  width: 40px;
  display: inline-block;
  text-align: center;
  margin-left: 15px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}
.avepdf .pdf-to-jpg-quality .ant-slider-rail {
  margin-left: 0px;
  width: calc(100% + 6px);
}
.avepdf .pdf-to-jpg-quality .ant-slider-handle {
  border: 0;
  margin-top: -3px;
}
.avepdf .cleanup-button {
  width: max-content;
}
.avepdf .cleanup-button span {
  opacity: 1;
}
.avepdf .cleanup-button .cleanup-filter-title {
  width: max-content;
}
.avepdf .cleanup-save-button {
  display: block;
  margin: auto;
}
@media (max-width: 991px) {
  .avepdf .cleanup-button {
    width: 100%;
    padding: 10px !important;
  }
}
.avepdf .insert-page-input input {
  padding-left: 20px !important;
}
.avepdf .keep-proportions label span {
  padding-right: 5px;
}
.avepdf .opacity-icon svg {
  fill: #1f1f1f;
}
.avepdf .shape-container {
  margin: auto 10px;
}
.avepdf .keep-prop .ant-checkbox .ant-checkbox-inner::after {
  border-color: #0d7057;
}
.avepdf .keep-prop .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #0d7057;
}
.avepdf .keep-prop .ant-checkbox .ant-checkbox-inner {
  border-color: #d9d9d9;
  background-color: #8c7a7f00;
}
.avepdf .keep-prop .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #d9d9d9;
  background-color: #8c7a7f00;
}
.avepdf .opacity-old {
  margin: auto 12px !important;
}
.avepdf .opacity-icon {
  padding: 8px;
  height: 44px;
  width: 44px;
  display: inline-block;
}
.avepdf .opacity-icon svg {
  width: 100%;
  height: auto;
}
.avepdf .second-slider {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
.avepdf .cookie-warning-modal {
  overflow: hidden;
  top: 50px;
}
.avepdf .cookie-warning-modal .ant-modal-content .ant-modal-header {
  border-bottom: 0px;
}
.avepdf .cookie-warning-modal .ant-modal-content .ant-modal-footer {
  border-top: 0px;
}
.avepdf .browser-type-icon {
  padding-right: 25px;
  display: initial;
}
.avepdf .warning-title {
  font-size: 24px;
  padding-left: 10px;
  max-width: 75%;
  justify-content: center;
  font-weight: bold;
}
.avepdf .cookie-warning-modal .ant-modal-content .ant-modal-close .ant-modal-close-x {
  border: 1px solid black;
  border-radius: 5px;
  background-color: black;
  width: 26px;
  height: 22px;
  line-height: 0.9;
  color: white;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 4px;
}
.avepdf .cookie-warning-modal .ant-modal-content .ant-modal-close .ant-modal-close-x i img {
  width: 18px;
  height: 18px;
  padding-left: 1px;
}
.avepdf .ant-modal-wrap {
  overflow: auto;
}
.avepdf .keep-aspect-button-on img {
  margin: 3px;
  width: 20px;
  fill: #0d7057;
}
.avepdf .keep-aspect-button-off img {
  margin: 3px;
  width: 20px;
  fill: #b1b1b1;
}
.avepdf .keep-aspect-ratio-button {
  width: 42px;
  height: 42px;
  border-radius: 5px;
  padding: 0px;
}
.avepdf .shape-button-image-toolbar {
  background-color: rgba(148, 191, 218, 0.03);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 5px;
}
.avepdf .shape-button-image-toolbar span img {
  position: relative;
  width: 100%;
}
.avepdf .shape-button-image-toolbar-svg {
  position: relative;
  width: 3rem;
  height: 2rem;
  top: -9px;
  left: -9px;
}
.avepdf .pdf-edit-selected-annotation-toolbar .shape-annotation-toolbar-button {
  width: 2rem;
  height: 2rem;
  margin: 2px;
  background: #fafbfd;
}
.avepdf .shape-annotation-toolbar-button {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
}
.avepdf .trash-button img {
  width: 20px;
  fill: #0d7057;
}
.avepdf .keep-aspect-ratio-button .keep-aspect-button-on {
  width: 38px;
}
.avepdf .keep-aspect-ratio-button .keep-aspect-button-off {
  width: 38px;
}
.avepdf .keep-proportions .shape-toolbar-image-value {
  font-size: 18px;
  color: #1f1f1f;
}
.avepdf .floating-toolbar {
  width: auto !important;
  height: auto !important;
}
.avepdf .floating-opacity-slider {
  width: 154px;
  margin: 15px 5px 16px;
}
.avepdf .floating-bar-input {
  padding: 4px 15px;
  width: 94px;
}
.avepdf .floating-bar-input .ant-input {
  height: 28px;
}
.avepdf .floating-toolbar-btn-wrapper {
  width: 46px !important;
  height: 46px !important;
  padding: 4px;
}
.avepdf .floating-toolbar-btn-content {
  width: 36px;
  height: 36px;
  padding: 0 !important;
}
.avepdf .floating-toolbar-btn-content .ant-btn svg path {
  fill: #1f1f1f;
}
.avepdf .floating-toolbar-btn {
  width: 100%;
  height: 100%;
  padding: 4px;
}
.avepdf .floating-toolbar-btn img {
  margin: 0;
  width: 100%;
  height: auto;
}
@media (max-width: 767.98px) {
  .avepdf .floating-opacity-slider {
    width: 154px;
    margin: 15px 6px;
  }
  .avepdf .floating-bar-input {
    padding: 3px 15px;
    width: 94px;
  }
  .avepdf .opacity-icon {
    padding: 8px;
    height: 42px;
    width: 42px;
    display: inline-block;
  }
  .avepdf .floating-toolbar-btn-wrapper {
    width: 44px !important;
    height: 44px !important;
    padding: 4px;
  }
  .avepdf .floating-toolbar-btn-content {
    width: 34px;
    height: 34px;
  }
  .avepdf .ant-radio-button-wrapper:first-child {
    border-radius: 5px 5px 0 0;
  }
  .avepdf .ant-radio-button-wrapper:last-child {
    border-radius: 0 0 5px 5px;
  }
  .avepdf .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
}
@media (max-width: 767.98px) and (max-width: 768px) {
  .avepdf .ant-radio-button-wrapper {
    border: 1px solid rgba(102, 110, 132, 0.2);
    margin-top: -1px !important;
    border-radius: 0;
  }
}
@media (max-width: 575.98px) {
  .avepdf .floating-opacity-slider {
    width: 68px;
  }
}
.avepdf .edit-toolbar-picker {
  width: 16px;
  height: 16px;
}
.avepdf .page-selection-disabled {
  opacity: 0.4;
}
.avepdf .page-selection-enabled {
  opacity: 1;
}
.avepdf .flatten-select {
  height: 32px;
  min-width: 70px;
}
.avepdf .flatten-select div {
  border-radius: 5px;
  height: 32px;
  background-color: transparent;
}
.avepdf .flatten-page-option-list {
  max-height: 240px;
  overflow: auto;
}
.avepdf .flatten-page-option-list .ant-dropdown-menu-item {
  padding: 4px 10px !important;
}
.avepdf .protect-pdf-select-mode-title {
  font-size: 26px;
  font-weight: bold;
  color: #1f1f1f;
}
.avepdf .protect-pdf-mode-wrapper {
  width: 250px;
  padding: 10px;
  border: 1px solid rgba(102, 110, 132, 0.4);
  border-radius: 5px;
  cursor: pointer;
}
.avepdf .protect-pdf-mode-wrapper svg {
  margin: 20px;
  width: 60px;
}
.avepdf .protect-pdf-password-field {
  padding-bottom: 30px;
}
.avepdf .disabled-block {
  opacity: 0.4;
}
.avepdf .ant-radio-button-wrapper-checked {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.avepdf .ant-radio-button-wrapper-checked:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.avepdf .ant-tooltip {
  padding: 5px 10px;
  font-size: 13px;
}
.avepdf .ant-tooltip .ant-tooltip-inner {
  background-color: #1f1f1f;
}
.avepdf .ant-tooltip-placement-left .ant-tooltip-arrow,
.avepdf .ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.avepdf .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  border-left-color: #1f1f1f;
  right: 6px;
}
.avepdf .ant-tooltip-placement-bottom .ant-tooltip-arrow,
.avepdf .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.avepdf .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: 0px;
}
.avepdf .ant-tooltip-placement-top .ant-tooltip-arrow,
.avepdf .ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.avepdf .ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 0px;
  border-top-color: #1f1f1f;
}
.avepdf .ave-loading-bar {
  height: 20px;
  width: 100%;
  border-radius: 0 0 5px 5px;
  margin-bottom: 30px;
  overflow: hidden;
  padding: 0 8px;
}
.avepdf .ave-loading {
  height: 100%;
  transition: width 0.5s linear;
}
.avepdf .ave-loading-text p {
  font-size: 25px;
  line-height: 1.5;
}
.avepdf .download-action-button[disabled],
.avepdf .download-action-button[disabled]:hover {
  color: grey;
}
.avepdf .download-action-button[disabled],
.avepdf .download-action-button[disabled]:hover {
  color: grey;
}
.avepdf .download-action-button[disabled] .download-icon svg path {
  fill: grey;
}
.avepdf .download-action-button[disabled] img,
.avepdf .download-action-button[disabled]:hover img {
  opacity: 0.5;
}
.avepdf .share-overlay {
  position: absolute;
  height: 182%;
  width: 104%;
  background: rgba(15, 15, 15, 0.95);
  z-index: 999999999;
  top: -68px;
  left: -2%;
}
.avepdf .share-overlay-content {
  top: 0;
  position: relative;
  color: #fff;
}
.avepdf .share-overlay-content .share-icon-bar {
  position: relative;
  width: max-content;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  background: #1f1f1f;
  border-radius: 5px;
  filter: invert(100%);
}
.avepdf .share-overlay-content .share-icon-bar ul li {
  display: inline-block;
  width: 64px;
  text-align: center;
  color: white;
  font-size: 18px;
  background: #1f1f1f;
  cursor: pointer;
}
.avepdf .share-overlay-content .share-icon-bar ul li:hover {
  background-color: #fff;
  color: #1f1f1f;
}
.avepdf .share-overlay-content .share-icon-bar ul li:first-child {
  border-radius: 5px 0 0 5px;
}
.avepdf .share-overlay-content .share-icon-bar ul li:last-child {
  border-radius: 0 5px 5px 0;
}
@media (max-width: 991.98px) {
  .avepdf .share-overlay {
    position: absolute;
    height: 182%;
    width: 106%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 999999999;
    top: -68px;
    left: -3%;
  }
  .avepdf .share-overlay .ave-loading-bar {
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  .avepdf .share-overlay {
    position: absolute;
    height: 200%;
    width: 106%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 999999999;
    top: -57px;
    left: -3%;
  }
  .avepdf .ave-loading-text p {
    font-size: 16px;
    line-height: 1.5;
    padding: 0 5px;
  }
}
@media (max-width: 576px) {
  .avepdf .share-overlay {
    position: absolute;
    height: 128%;
    width: 106%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 999999999;
    top: -57px;
    left: -3%;
  }
  .avepdf .share-overlay-content .share-icon-bar {
    display: block;
  }
}
.avepdf .white-space {
  display: block;
  width: 100%;
}
.avepdf .white-space.space-xmini {
  padding: 3px 0;
}
.avepdf .white-space.space-mini {
  padding: 5px 0;
}
.avepdf .white-space.space-xsmall {
  padding: 10px 0;
}
.avepdf .white-space.space-small {
  padding: 15px 0;
}
.avepdf .white-space.space-medium {
  padding: 30px 0;
}
.avepdf .white-space.space-big {
  padding: 60px 0;
}
.avepdf .fa {
  font-family: "FontAwesome" !important;
}
.avepdf .icon {
  display: inline-block;
  line-height: 1;
  font-size: inherit;
  vertical-align: middle;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.avepdf .icon-lg {
  font-size: 1.33333333em;
  line-height: 1em;
  vertical-align: -15%;
}
.avepdf .icon-2x {
  font-size: 2em !important;
}
.avepdf .icon-3x {
  font-size: 3em !important;
}
.avepdf .icon-4x {
  font-size: 4em !important;
}
.avepdf .icon-5x {
  font-size: 5em !important;
}
.avepdf .iconright {
  margin-left: 10px;
}
.avepdf .iconleft {
  margin-right: 10px;
}
.avepdf .fa-ul {
  margin-left: 2em;
}
.avepdf .fa-ul > li {
  line-height: 2em;
}
.avepdf .fa-ul .fa-li {
  height: 2em;
  left: -2em;
  line-height: 1.9;
  position: absolute;
  text-align: center;
  top: 0;
  width: 2em;
}
.avepdf .icon-ul {
  list-style-type: none;
  margin-left: 2em;
  padding-left: 0;
}
.avepdf .icon-ul > li {
  position: relative;
  line-height: 2em;
}
.avepdf .icon-ul .icon-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  height: 2em;
  line-height: 2.3;
}
.avepdf .fa-ul.icon-border,
.avepdf .icon-ul.icon-border,
.avepdf .fa-ul.icon-bg-box,
.avepdf .icon-ul.icon-bg-box {
  margin-left: 2.5em;
}
.avepdf .icon-border li,
.avepdf .icon-bg-box li {
  margin-bottom: 10px;
}
.avepdf .icon-border > li > .icon-li,
.avepdf .icon-border > li > .fa-li {
  border: 1px solid;
  border-radius: 5px;
  left: -2.5em;
}
.avepdf .icon-border > li > a > .icon-li,
.avepdf .icon-border > li > a > .fa-li {
  border: 1px solid;
  border-radius: 5px;
  left: -2.5em;
}
.avepdf .icon-bg-box > li > .icon-li,
.avepdf .icon-bg-box > li > .fa-li {
  background: #f2f2f2;
  left: -2.5em;
  border-radius: 5px;
}
.avepdf .icon-bg-box > li > a > .icon-li,
.avepdf .icon-bg-box > li > a > .fa-li {
  background: #f2f2f2;
  left: -2.5em;
  border-radius: 5px;
}
.avepdf .icon-radius > li > .icon-li,
.avepdf .icon-radius > li > .fa-li {
  border-radius: 50%;
}
.avepdf .icon-radius > li > a > .icon-li,
.avepdf .icon-radius > li > a > .fa-li {
  border-radius: 50%;
}
.avepdf .icon-list {
  margin-left: 0 !important;
}
.avepdf .icon-list li {
  margin: 0;
}
.avepdf .icon-list > li > a > .icon-li,
.avepdf .icon-list > li > a > .fa-li {
  display: block;
  position: relative;
  left: 0;
}
.avepdf .footer-container .icon-list > li > a,
.avepdf .footer-container .icon-list > li > a,
.avepdf .footer-container .icon-list > li > a,
.avepdf .footer-container .icon-list > li > a {
  color: #ffffff;
}
.avepdf .separator-list > li {
  border-left: 1px solid;
}
.avepdf .separator-list > li:first-child {
  border-left: none;
  padding-left: 0 !important;
}
.avepdf .list-medium > li {
  font-size: 1.2em;
  margin-bottom: 15px;
}
.avepdf .fa-ul.list-medium,
.avepdf .icon-ul.list-medium {
  margin-left: 3em;
}
.avepdf .list-big > li {
  font-size: 1.4em;
  margin-bottom: 15px;
}
.avepdf .fa-ul.list-big,
.avepdf .icon-ul.list-big {
  margin-left: 3.5em;
}
.avepdf .media-heading {
  text-transform: none;
}
.avepdf .rating-stars {
  padding: 0;
  margin: 0;
}
.avepdf .rating-stars li {
  display: inline-block;
  margin-left: 5px;
  color: #fadb14;
}
.avepdf .rating-stars li:first-child {
  margin-left: 0;
}
.avepdf .list-3-col {
  column-count: 3;
}
@media (max-width: 767.98px) {
  .avepdf .list-3-col {
    column-count: 2;
  }
}
.avepdf .list-2-col {
  column-count: 2;
}
.avepdf .icon-error-color {
  color: #c00;
}
.avepdf .icon-warning-color {
  color: #ffe58f;
}
.avepdf .icon-success-color {
  color: #b7eb8f;
}
.avepdf .plan-card {
  background-color: rgba(234, 234, 250, 0.3) !important;
}
.avepdf .plan-card:hover {
  box-shadow: 0px 40px 60px -20px rgba(102, 110, 132, 0.15);
}
.avepdf .plan-card .card-header {
  padding: 1.25rem;
  border: none;
  min-height: 200px;
  background-color: transparent;
  padding-bottom: 0;
}
.avepdf .plan-card .card-footer {
  padding: 0;
}
.avepdf .plan-card .card-title {
  position: relative;
}
.avepdf .plan-card.premium-card {
  border-color: #1D7C76;
}
.avepdf .plan-card.premium-card .card-header {
  border-color: #1D7C76;
}
.avepdf .plan-card.premium-card .plan-features li {
  border-color: #1D7C76;
}
.avepdf .plan-card.premium-card .card-footer ul .plan-icon-footer svg path {
  fill: #2c2c2c;
}
.avepdf .plan-pricing {
  margin: 0;
}
.avepdf .plan-btn-wrapper {
  min-height: 70px;
}
.avepdf .plan-value {
  font-size: 2em;
}
.avepdf .plan-features-title {
  font-size: 1.4em;
  font-weight: 500;
}
.avepdf .plan-name {
  font-size: 1.6em;
  border-bottom: 1px solid rgba(102, 110, 132, 0.2);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.avepdf .plan-features {
  list-style: none;
  padding: 0;
  margin: 0;
}
.avepdf .plan-features li {
  margin: 5px 0;
  padding: 10px 0;
}
.avepdf .plan-features li .plan-list-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.avepdf .plan-features li .plan-list-icon svg path {
  fill: #2c2c2c;
}
.avepdf .plan-users {
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  right: 0;
  color: #2c2c2c;
}
.avepdf .plan-users .plan-users-icon {
  width: 20px;
  display: inline-block;
  margin-right: 5px;
}
.avepdf .plan-users .plan-users-icon path {
  fill: #2c2c2c;
}
.avepdf .secure-data-icon {
  display: inline-block;
  margin: 5px 10px;
  height: 32px;
}
.avepdf .secure-data-icon svg path {
  fill: #2c2c2c;
}
.avepdf .premium-modal-error .ant-modal-body {
  padding: 50px 30px;
}
.avepdf .premium-modal-error .ant-modal-close {
  background: none;
}
.avepdf .premium-modal-error .ant-modal-close .anticon {
  width: 24px;
  height: 24px;
}
.avepdf .premium-banner {
  background: linear-gradient(90deg, #f0f3f9 26.7%, #f6f9fd 73.42%);
  text-align: center;
}
.avepdf .premium-banner .ant-alert {
  max-width: 1170px;
  margin: 0 auto;
  background-color: transparent;
  justify-content: center;
  color: #2c2c2c;
}
.avepdf .premium-banner .ant-alert .ant-alert-content {
  flex: initial;
  margin: 0 1.2rem;
}
.avepdf .premium-banner .ant-alert .ant-alert-content .ant-alert-message {
  color: #2c2c2c;
}
.avepdf .premium-banner .ant-alert .ant-alert-icon {
  color: #1f847d;
  margin-right: 0;
}
.avepdf .premium-banner .ant-alert .ant-alert-close-icon {
  margin-left: 0;
  padding: 4px;
  border-radius: 5px;
}
.avepdf .premium-banner .ant-alert .ant-alert-close-icon:hover {
  background-color: #e5e8ed;
}
.avepdf .premium-banner .ant-alert .ant-alert-close-icon .anticon-close {
  color: #2c2c2c;
}
.avepdf .placeholder-wrapper.price-placeholder {
  width: 100px;
  min-height: 46.6667px !important;
}
.avepdf .placeholder-wrapper.price-placeholder .placeholder-content {
  min-height: 46.6667px !important;
  border-radius: 2px;
}
.avepdf .placeholder-wrapper.price-placeholder.placeholder-wide {
  width: 100%;
}
.avepdf .placeholder-content {
  height: auto;
  overflow: hidden;
  background: #000;
  position: relative;
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: #f6f7f8;
  background: linear-gradient(to right, #e9e9ef 2%, #f9f9fe 18%, #e9e9ef 33%);
  background-size: 1300px;
}
.avepdf .placeholder-content-item {
  background: #f9f9fe;
  height: 9px;
  position: absolute;
  width: 100%;
  z-index: 2;
  top: 9px;
}
.avepdf .placeholder-content-item:before {
  background-color: inherit;
  content: "";
  height: 9px;
  position: absolute;
  width: inherit;
  top: 19px;
}
.avepdf .placeholder-content-item:after {
  content: "";
  position: absolute;
  background-color: inherit;
  top: 27px;
  height: 11px;
  right: 0;
  width: 65%;
}
@keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}
.avepdf .placeholder-animate-bg {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #e9e9ef 2%, #f9f9fe 18%, #e9e9ef 33%);
  background-size: 800px 104px !important;
  min-height: 12px;
  display: block;
  clear: both;
}
.avepdf .cc-window.cc-floating {
  padding: 0 !important;
  background: #1f1f1f;
}
.avepdf .cc-window {
  background: #354063;
}
.avepdf .cc-message {
  width: auto;
  padding: 15px 10px;
  margin: 10px !important;
  line-height: 1.2em;
  color: #ffffff;
  font-weight: 500;
  font-size: 0.857em;
  line-height: 1.5em;
}
.avepdf .cc-link {
  color: #ffffff !important;
}
.avepdf .cc-btn {
  background: #323438;
  color: #ffffff !important;
  border: none !important;
  padding: 10px !important;
}
.avepdf .cc-btn:hover,
.avepdf .cc-btn:focus {
  background-color: #3a3d41;
  color: #ffffff;
}
@media (max-width: 1199.98px) {
  .avepdf .cc-window.cc-floating {
    max-width: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    display: block;
  }
  .avepdf .cc-message {
    display: inline-block !important;
    width: auto;
    padding: 0 10px;
    margin: 0 !important;
    line-height: 1.5em;
  }
  .avepdf .cc-link {
    padding: 1em;
  }
  .avepdf .cc-compliance {
    display: inline-block;
    width: auto;
    float: right;
  }
  .avepdf .cc-btn {
    display: inline-block;
    padding: 12px !important;
  }
}
@media (max-width: 767.98px) {
  .avepdf .cc-message {
    padding: 10px;
  }
  .avepdf .cc-link {
    display: block;
    padding: 1em 0;
  }
  .avepdf .cc-compliance {
    display: block;
    width: 100%;
  }
  .avepdf .cc-btn {
    display: block;
  }
}
@media (max-width: 575.98px) {
  .avepdf .cc-message {
    padding: 10px;
  }
  .avepdf .cc-link {
    display: block;
    padding: 1em 0;
  }
  .avepdf .cc-compliance {
    display: block;
    width: 100%;
  }
  .avepdf .cc-btn {
    display: block;
  }
}
