@primary-color: #1f1f1f; // primary color for all components
@focus-color: #1D7C76; // new design focus color
@link-color: rgba(0, 0, 0, 0.75); // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@heading-color: rgba(0, 0, 0, 1); // heading text color
@text-color: lighten(@primary-color, 5%); // major text color
@text-color-secondary: lighten(@primary-color, 15%); // secondary text color
@disabled-color: lighten(@primary-color, 35%); // disable state color
@border-radius-base: 5px; // major border radius
@border-color-base: rgba(102,110,132,.2); // major border color
@box-shadow-base: none; // major shadow for layers
@primary-color-outline: lighten(@primary-color, 15%);

// Tools Elements Library

////////////////////
// ANTD Elements //
//////////////////
//progress bar start

.ant-progress-bg,
.ant-progress-status-success .ant-progress-bg {
    background-color: var(--tool-color) !important;
}
//progress bar end
//slider start
.ant-slider-dot-active {
    border-color: var(--tool-color) !important;
}

.ant-slider-track,
.ant-slider-track:focus,
.ant-slider-track:hover,
.ant-slider:hover .ant-slider-track,
.ant-slider:hover .ant-slider-handle {
    background-color: var(--tool-color) !important;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: var(--tool-color) !important;
}
//slider end
//inputs start
.ant-input {
    &:hover,
    &:focus {
        border-color: rgba(102,110,132,.4) !important;
    }
}


.ant-input-affix-wrapper {
    color: @text-color;
    border-color: @border-color-base;
    border-radius: @border-radius-base;

    &:focus, .ant-input-affix-wrapper-focused {
        border-color: rgba(102,110,132,.4);
        -webkit-box-shadow: none;
    }

    &:hover {
        border-color: rgba(102,110,132,.4);
    }

    &:hover .ant-input:not(.ant-input-disabled) {
        border-color: rgba(102,110,132,.4) !important;
    }

    .ant-input-prefix :not(.anticon),
    .ant-input-suffix :not(.anticon) {
        fill: var(--tool-color) !important;
    }
}

.remove-label {
    background-color: var(--tool-color) !important;
}
//inputs end
//radio buttons
.ant-radio-button-wrapper {
    &:hover {
        color: var(--tool-color) !important;
    }
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: var(--tool-color) !important;
    border-color: var(--tool-color) !important;
    color: #fff !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: var(--tool-color) !important;
    border-color: var(--tool-color) !important;
    color: #fff !important;
}

.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
    border-color: var(--tool-color) !important;
}

.ant-radio-group {
    .ant-radio-button-wrapper {
        &:hover,
        &:active {
            .below-layer-svg, .above-layer-svg {
                svg {
                    path {
                        fill: var(--tool-color) !important;
                    }
                }
            }
        }
    }
}

.ant-radio-group {
    .ant-radio-button-wrapper {
        &.ant-radio-button-wrapper-checked {

            .below-layer-svg, .above-layer-svg {
                svg {
                    path {
                        fill: #fff !important;
                    }
                }
            }

            &:hover, &:focus, &:active {
                .below-layer-svg, .above-layer-svg {
                    svg {
                        path {
                            fill: #fff !important;
                        }
                    }
                }
            }
        }
    }
}
//radio buttons end
//switch start
.ant-switch-checked {
    background-color: var(--tool-color) !important;
}
//switch end
//buttons start
.ant-btn {

    &.ant-btn-primary {
        color: #fff;
        background-color: var(--tool-color);
        border-color: var(--tool-color);

        &:hover,
        &:focus {
            color: #fff;
        }

        svg {
            path {
                fill: #fff;
            }
        }
    }


    &.ant-btn-primary[disabled],
    &.ant-btn-primary[disabled]:hover,
    &.ant-btn-primary[disabled]:focus,
    &.ant-btn-primary[disabled]:active {
    }

    &.ant-btn-white {

        svg {
            path {
                fill: @primary-color;
            }
        }

        &:hover,
        &:focus {
            svg {
                path {
                    fill: #fff !important;
                }
            }
        }
    }

    &.custom-active-on {
        background-color: var(--tool-color);
        border-color: var(--tool-color);
        color: #fff;
    }

    &.upload-more {
        color: @primary-color;
        border-width: 2px;
        border-color: @primary-color;
        background: #fff;

        svg path {
            fill: @primary-color;
        }

        &:hover,
        &:focus {
        }
    }


    &.shape-button {
        background: #fff;

        svg path {
            fill: @primary-color;
        }

        &:hover {
            color: #fff !important;


            svg path {
                fill: #fff !important;
            }
        }

        &.selected-shape-button {
            background-color: var(--tool-color) !important;

            svg {
                path {
                    fill: #fff !important;
                }
            }
        }
    }

    &.floating-toolbar-btn {

        &:hover,
        &:focus {

            svg path {
                fill: #fff;
            }
        }
    }

    &.white-action-button {
        background: #ffffff;
        color: var(--tool-color);
        border-color: var(--tool-color);

        svg path {
            fill: var(--tool-color);
        }
    }

    &.rename-btn {
        svg {
            path {
            }
        }

        &:hover,
        &:focus {
            background-color: transparent !important;
            border-color: transparent !important;

            svg {
                path {
                    fill: var(--tool-color) !important;
                }
            }
        }
    }

    &.rotate-btn {
        &:hover,
        &:focus {
            background-color: var(--tool-color) !important;
            border-color: var(--tool-color) !important;
        }
    }
}

.ant-popover .ant-btn.shape-button {
    &.selected-shape-button {
        background-color: #2e2e2e !important;
    }

    &:hover,
    &:focus {
        background-color: #2e2e2e !important;
    }
}

&.download-file-header {

    &:hover,
    &:focus {
        color: #1D7C76 !important;
    }
}

.remove-file-header {
    color: #fff;
    background-color: @primary-color;


    &:hover,
    &:focus {
        color: #fff;
        background-color: #1D7C76;
    }
}


.upload-url-input {
    .url-input-icon {

        svg {
            path {
                fill: var(--tool-color);
            }
        }
    }
}

.action-button,
.close-action-button,
.simple-modal-error-wrapper {
    button {
        background: var(--tool-color);
    }
}

.action-button {
    &.white-action-button {
        background: #ffffff;
        color: var(--tool-color);
        border-color: var(--tool-color);

        svg path {
            fill: var(--tool-color);
        }
    }
}

.floating-toolbar-btn-content {

    .ant-btn {
        background: #fff !important;
        border-color: @border-color-base !important;
        max-height: 34px;
        overflow: hidden;

        svg {
            path {
                fill: var(--tool-color) !important;
            }
        }

        &:hover,
        &:focus {
            background-color: var(--tool-color) !important;

            svg {
                path {
                    fill: #fff !important;
                }
            }
        }

        &.btn-fill-picker {
            svg {
                path {
                    fill: lighten(@primary-color, 80%) !important;
                }
            }
        }
    }
}

.ant-btn-group-white {
    .ant-btn {

        &:hover,
        &:focus {
            background: var(--tool-color) !important;
        }
    }
}

.upload-more-methods {

    .ant-btn {
        svg {
            path {
                fill: @primary-color;
            }
        }
    }
}
//buttons end
.tooltip-border {
    border: 2px solid var(--tool-color);
}


> .tooltip-border {
    border-color: var(--tool-color) !important;

    .ave-tooltip-triangle {
        &.tooltip-border {
            border-color: var(--tool-color) !important;
        }
    }
}
//tooltip icon
.tooltip-icon {
    color: var(--tool-color);
}


.tool-small-img {
    background: var(--tool-color);
}
//////////////////////
// AvePDF Elements //
////////////////////
a {
    color: var(--tool-color);

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.header .auth-buttons {
    a {
        color: @primary-color;
    }
}


.upload-icon {
    svg {
        path {
            fill: var(--tool-color);
        }
    }
}



.embed-title {
    color: var(--tool-color);
}

&.tool-item-content {
    background: var(--tool-color);
}

&.tool-item-wrapper {

    a:hover,
    a:focus {
        .tool-item-block-arrow {
            color: var(--tool-color);
        }
    }

    .tool-item-block {

        .tool-item-block-icon {
            background: var(--tool-color);
            border-radius: @border-radius-base;

            svg {
                border-radius: @border-radius-base;
                padding: 2px;
            }
        }
    }
}

.megamenu .dropdown-menu {

    @media (max-width: 991.98px) {
        border-top-left-radius: @border-radius-base;
        border-top-right-radius: @border-radius-base;
        border-top: 1px solid rgba(102,110,132,.2) !important;
    }

    .megamenu-tools {
        ul {
            li {
                .tool-item-block-icon {
                    background: var(--tool-color);
                    border-radius: @border-radius-base;
                }
            }
        }
    }
}




    &.horizontal-tool-icon-svg {
        background: var(--tool-color);
    }



    .how-to-use-icon svg path {
        fill: var(--tool-color);
    }
    // Buttons

    .ant-btn-pdfa {
        .custom-active-on {
            background: var(--tool-color);
        }

        .custom-active-off {
            &:hover,
            &:focus {
                background: var(--tool-color) !important;
            }
        }
    }
    // Forms
    legend {
        color: var(--tool-color);
    }
    //Page Numbers
    .pagenumber-preview-wrapper {
        .preview-text-position {
            color: var(--tool-color);
            border-color: lighten(@primary-color, 30%);
        }
    }
    //Thumnails
    .thumbnail-icons-wrapper {
        .btn {

            &:hover {
                color: var(--tool-color);
            }
        }

        .ant-btn {
            &:hover,
            &:focus {
                color: #fff !important;
                border-color: var(--tool-color) !important;
                background-color: var(--tool-color) !important;

                svg {
                    path {
                        fill: #fff !important;
                    }
                }
            }
        }
    }

    .thumbnail-part.ui-selected-custom .thumbnail-select {
        color: var(--tool-color);

        svg {
            path {
                fill: var(--tool-color) !important;
            }
        }
    }

    .split-pdf .thumbnail-part.ui-selected-custom .thumbnail-select {

        svg {
            path {
                fill: inherit !important;
            }
        }
    }
    //Select
    .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:not(.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
        border-color: var(--tool-color);
        border-right-width: 1px !important;
        outline: 0;
    }
    //Blog
    .blog-card {

        .blog-title {
            background-color: rgba(255, 255, 255, 0.044) !important;
            color: @primary-color !important;
        }

        &:hover,
        &:focus {

            .blog-title {
                color: var(--tool-color) !important;
            }
        }
    }
    //Tabs
    .avepdf-upload-tabs {

        .ant-tabs-nav {

            .ant-tabs-nav-list {

                .ant-tabs-tab {

                    &:hover,
                    &:focus {
                        .ant-tabs-tab-btn {
                            color: var(--tool-color) !important;
                        }
                    }

                    &.ant-tabs-tab-active {
                        @media (max-width: 767.98px) {
                            background-color: var(--tool-color) !important;
                            color: #fff;

                            &:hover,
                            &:focus {
                                .ant-tabs-tab-btn {
                                    color: #fff !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //Grid Colors
    @avecolors: #d1001c, #d72418, #dd3714, #e2470f, #e75608, #ec6301, #f07000, #f37d00, #f78900, #f99600, #fca200, #feae00, #ffba00, #f6bd00, #ecc000, #e2c300, #d8c600, #cdc900, #c2cc00, #b7ce00, #aad000, #9ed201, #90d415, #82d622, #72d72e, #68cd2d, #5fc42d, #55ba2c, #4cb12a, #43a829, #3a9e27, #329526, #298c24, #208322, #167a20, #177a21, #00681b, #00681b, #006a2c, #006c42, #006d5b, #006d78, #006d97, #006db6, #006bd3, #0068ea, #0061f9, #0055fe, #0040f7, #5800e3, #6400dc, #6d00d5, #7500cf, #7b00c8, #8000c2, #8500bc, #8900b6, #8c00b0, #8f00aa, #9100a5, #9100a5, #93009f, #94009a, #a100a0, #ae01a6, #bb02ac, #c902b2, #d603b7, #e404bc, #f206c1;

    // Generate Tools
    .generate-tools(70);

    .generate-tools(@n, @i: 1) when (@i =< @n) {
        .tool-@{i} {
            @tool-color: extract(@avecolors, @i);
            --primary-widget-color: darken(@tool-color, 5%);
            --tool-color: @tool-color;
            //custom operation that need less variable
            .avepdf-moveable * {
                background: var(--tool-color) !important;
                border-color: lighten(extract(@avecolors, @i), 10%) !important;
            }
            //slider start
            .ant-slider-dot {
                border-color: lighten(extract(@avecolors, @i), 50%);
            }

            .ant-slider-rail {
                background-color: lighten(extract(@avecolors, @i), 50%);
            }

            .ant-slider:hover .ant-slider-rail {
                background-color: lighten(extract(@avecolors, @i), 50%);
            }

            .ant-slider-handle,
            .ant-slider-handle:focus,
            .ant-slider-handle:hover {
                border-color: lighten(extract(@avecolors, @i), 20%);
                background-color: lighten(extract(@avecolors, @i), 20%);
            }
            //slider end
            //button start
            .ant-btn {


                &.add-page-btn {
                    &:hover, &:focus, &:active {
                        background-color: lighten(extract(@avecolors, @i), 5%) !important;
                    }
                }

                &:hover, &:focus, &:active {
                    color: #fff;
                    background-color: lighten(extract(@avecolors, @i), 5%);
                    border-color: lighten(extract(@avecolors, @i), 5%);

                    .btn-icon {
                        path {
                            fill: #fff;
                        }
                    }
                }

                &.close-action-button {
                    color: #fff;
                    background-color: darken(desaturate(extract(@avecolors, @i), 100%), 10%);
                    border-color: darken(desaturate(extract(@avecolors, @i), 100%), 10%);

                    &:hover,
                    &:focus {
                        color: #fff;
                        background-color: darken(desaturate(extract(@avecolors, @i), 100%), 0%);
                        border-color: darken(desaturate(extract(@avecolors, @i), 100%), 0%);
                    }
                }

                &.download-action-button {

                    .download-icon-wrapper {
                        .download-icon-svg path {
                            fill: var(--tool-color) !important;
                        }
                    }

                    &.upload-file-download {
                        background-color: var(--tool-color) !important;
                        color: #fff !important;

                        &:hover,
                        &:focus {
                            color: #fff;
                            background-color: lighten(extract(@avecolors, @i), 5%) !important;
                            border-color: lighten(extract(@avecolors, @i), 5%) !important;
                        }

                        .download-icon-svg path {
                            fill: #fff !important;
                        }
                    }
                }

                &.shape-button {
                    &:hover {
                        background-color: darken(extract(@avecolors, @i), 20%) !important;
                        border-color: darken(extract(@avecolors, @i), 20%) !important;
                    }

                    &.selected-shape-button {

                        &:hover {
                            background-color: var(--tool-color) !important;
                            border-color: var(--tool-color) !important;
                        }
                    }

                    &.ant-popover-open {
                        background-color: darken(extract(@avecolors, @i), 20%) !important;
                        border-color: darken(extract(@avecolors, @i), 20%) !important;

                        svg {
                            path {
                                fill: #fff;
                            }
                        }
                    }
                }
            }

            .add-page-btn {
            }
            //end btn
            .upload-file-methods {
                .ant-btn {
                    border-color: lighten(extract(@avecolors, @i), 25%) !important;
                }
            }

            .floating-toolbar-btn-content .ant-btn:focus {
                background: lighten(extract(@avecolors, @i), 10%) !important;
            }


            .head-label-tooltip-icon {
                color: lighten(extract(@avecolors, @i), 20%);
            }

            .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:not(.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
                -webkit-box-shadow: lighten(extract(@avecolors, @i), 30%);
                box-shadow: lighten(extract(@avecolors, @i), 30%);
            }
        }

        .generate-tools(@n, (@i + 1));
    }
    // DARK Theme - User perefers dark mode in windows
:root[data-theme="dark"] {

    @primary-color: #212121;
    @link-color: @text-color;
    @text-color: #f2f2f2;
    @text-color-secondary: #e7e8eb;
    color-scheme: dark;
    // General Dark Colors
    body {
        background-color: @primary-color;
        color: @text-color-secondary;
    }


    .avepdf {

        a {
            color: #4CA49F;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6 {
            color: @text-color;
        }

        hr {
            border-top-color: @primary-color-outline;
        }

        i.selected {
            background-color: #fff;
            color: #1f1f1f;
        }

        .home-header {
            color: @text-color;
        }

        .tool-action-title {
            color: @text-color;
        }

        .horizontal-tool .horizontal-tool-title {
            color: @text-color;
        }

        .horizontal-tool .horizontal-tool-description {
            color: @text-color-secondary;
        }

        .logo-wrapper {
            svg {
                path {
                    fill: #fff !important;
                }
            }
        }

        .modal-logo {
            path {
                fill: @text-color !important;
            }
        }

        .tool-action-sub-title {
            color: @text-color-secondary;
        }
        // Site Notice

        .site-notice {
            background: linear-gradient(90deg,#2a2a2a 26.7%,#2a2a2a 73.42%);
            color: @text-color;

            .notice-sep {
                background-color: @primary-color-outline;
            }

            .pspdf-icon {
                svg {
                    path {
                        fill: @text-color;
                    }
                }

                &:hover {
                    svg {
                        path {
                            fill: #4537de;
                        }
                    }
                }
            }

            a {
                color: @text-color !important;
            }

            .site-notice-dismiss {
                color: @text-color;

                svg {
                    path {
                        stroke: @text-color;
                    }
                }
            }
        }
        //Header notification
        .header-alert {
            background-color: @primary-color;
            border-bottom: 1px solid lighten(@primary-color, 10%);
        }
        //Header container

        .header-container {
            a {
                color: #fff;
            }
        }
        // AvePDF Hamburger Menu
        .hamburger-menu-button {
            &:after,
            &:before,
            span {
                background-color: @text-color;
            }

            &.active {
                border-color: @primary-color-outline;
            }
        }

        .header-hamburger {


            .username, a.username {
                color: @text-color;
            }
        }
        /*=== New main header ===*/
        .ave-header {

            .navbar {

                a {

                    &:hover,
                    &:focus,
                    &:active {
                        color: @focus-color !important;
                    }
                }

                .navbar-nav {

                    @media (max-width: 1199.98px) {
                        background: lighten(@primary-color, 5%);
                        border-color: @primary-color-outline;
                    }

                    .selected-language {
                        border-color: @primary-color-outline !important;
                        color: #fff;
                    }

                    li > a {
                        @media (max-width: 1199.98px) {
                            border-bottom-color: @primary-color-outline !important;
                        }
                    }

                    .ave-main-menu-user {
                        .user-buttons {
                            .ant-btn {
                                color: @focus-color !important;

                                &:hover,
                                &:focus,
                                &:active {
                                    color: #fff !important;
                                }

                                svg {
                                    path {
                                    }
                                }
                            }
                        }
                    }

                    .nav-link {
                        @media (max-width: 991.98px) {
                            background: rgba(234,234,250,.04);
                        }
                    }
                }

                &.ave-navbar {
                    a {
                        color: #fff;
                    }

                    .navbar-nav {
                        background: transparent;
                        border-color: transparent;
                    }

                    .megamenu .dropdown-menu {
                        background: @primary-color;
                        border: 1px solid rgba(102,110,132,.2);
                        border-top: transparent;
                        box-shadow: 0 40px 60px -20px rgba(0,0,0,.25);

                        .megamenu-tools {

                            ul {

                                li {

                                    a {
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        /* Premium banner notification*/

        .premium-banner {
            background: linear-gradient(90deg,#2a2a2a 26.7%,#2a2a2a 73.42%);

            .ant-alert {
                color: @text-color;

                .ant-alert-content {
                    .ant-alert-message {
                        color: @text-color;
                    }
                }

                .ant-alert-close-icon {

                    &:hover {
                        background-color: @primary-color;
                    }

                    .anticon-close {
                        color: @text-color;
                    }
                }
            }
        }

        .tool-header-container {
            .tool-label {
                p {
                    color: @text-color;
                }
            }
        }

        .megamenu-list-category {
            .beta-tool {
                .tool-label {
                    p {
                        color: @text-color;
                    }
                }
            }
        }
        // Watermark

        .select-watermark {

            .ant-upload-list-item-info {
                color: #fff;

                svg {
                    path {
                        fill: #fff;
                    }
                }

                &:hover,
                &:focus,
                &:active {
                    color: #fff;
                    background-color: rgba(160,188,222,.03);

                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }

                .ant-btn {
                    &:hover,
                    &:focus,
                    &:active {
                        svg {
                            path {
                                fill: #fff;
                            }
                        }
                    }
                }
            }

            .font-styles {
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }

        .text-color {
            border-color: @primary-color-outline;
        }
        // Upload Block
        .tools-box {
            background-color: rgba(234,234,242,.04);
        }
        //Embedd

        .embedded-power-by {

            svg {
                path {
                    fill: @text-color;
                }
            }
        }
        // AvePDF Auth Buttons
        .header .auth-buttons {

            a {
                background-color: @primary-color;
                color: #fff;
            }

            .login-button {
                background-color: #fff;
                color: @primary-color !important;
            }

            .signup-button {
            }

            .logged-user-text {
                color: @text-color;
            }
        }

        .header-hamburger-menu {

            a {
                color: @text-color !important;
            }

            .login-button {
                color: @primary-color !important;
                background-color: #fff;
            }

            .signup-button {
            }

            .logged-user-text {
                color: @text-color;
            }

            .horizontal-borders {

                a {
                    color: @text-color;
                }
            }

            .pre-menu-icon,
            .main-menu-nav-icon {
                svg {
                    path {
                        fill: @text-color !important;
                    }
                }
            }

            .horizontal-borders {
                border-color: @primary-color-outline;
            }
        }

        .file-header-wrapper {

            .notification-dot {
            }

            .download-file-header {
                color: @text-color !important;
                border-color: @primary-color-outline !important;

                &:hover,
                &:focus {
                    color: #1D7C76 !important;
                    border-color: @primary-color-outline !important;
                }

                &.pricing-button {
                    color: #fff !important;

                    &:hover,
                    &:focus {
                        color: #fff !important;
                    }
                }
            }

            .remove-file-header {
                color: #1D7C76;
                background-color: #fff;

                &:hover,
                &:focus {
                    color: #fff;
                    background-color: #1D7C76;
                }
            }
        }

        .ave-main-menu {
            background-color: @primary-color;
            border-color: @primary-color-outline;

            &:before {
                background-color: @primary-color;
            }

            .ave-main-menu-user {
                border-bottom-color: @primary-color-outline;

                .ave-main-menu-profile {
                    .logged-in-menu {
                        .ant-btn {
                            background-color: @primary-color;
                            border-color: @primary-color-outline;
                            color: @text-color;

                            .ant-btn-disabled {
                                background-color: fade(@disabled-color, 5%);
                                color: fade(@text-color, 25%);
                                border-color: fade(@text-color, 25%);

                                svg {
                                    path {
                                        fill: fade(@text-color, 25%) !important;
                                    }
                                }
                            }

                            &[disabled] {
                                .ant-btn-disabled();


                                &:hover {
                                    .ant-btn-disabled();
                                }
                            }

                            &:hover,
                            &:focus {
                                background-color: rgba(160, 188, 222, 0.08);
                            }
                        }
                    }
                }
            }

            .ave-main-menu-nav {

                ul {

                    li {
                        border-top-color: @primary-color-outline;

                        .ave-nav-link {
                            color: @text-color !important;
                        }
                    }
                }

                .menu-item-dropdown {
                    background-color: @primary-color;
                    border-color: @primary-color-outline;
                }
            }

            .ave-main-menu-theme-switch {
                border-top-color: @primary-color-outline;
            }
        }

        .profile-dropdown .icon-dropdown {
            path {
                fill: @text-color;
            }
        }
        //Tools Preloader
        .avetool-preloader {
            path {
                fill: @text-color;
            }
        }
        /* WIN10 LOADER */
        .loader .circle:after {
            background: @text-color;
        }
        // Login & Signup
        .auth-container-block {
            background-color: @primary-color;
            border-color: @primary-color-outline;
        }

        .card {
            background-color: #212121;
        }
        //Tabs
        .ant-tabs {
            color: @text-color;

            .ant-tabs-tab {
                &.ant-tabs-tab-active {

                    .ant-tabs-tab-btn {
                        color: @text-color;
                    }
                }
            }
        }

        .ant-tabs-ink-bar {
            background: @text-color;
        }

        .auth-tabs {
            a {
                color: @text-color;

                button {
                    color: @text-color;

                    &.active {
                    }
                }
            }
        }

        .auth-socials-separator-text-wraper {
            span {
                background-color: @primary-color;
                color: @text-color;
            }
        }

        .auth-form {

            .input-corner-label {
                background-color: @primary-color;
                color: @text-color;
            }

            .remember-checkbox {
                color: @text-color;
            }

            .login-forgot-password {
                color: @text-color;
            }
        }

        .color-picker-wrapper input {
            color: @primary-color;
            background-color: @text-color;
        }

        .ant-input-affix-wrapper {
            background-color: lighten(@primary-color, 10%);
            color: @text-color;
            border-color: @primary-color-outline;
            border-radius: @border-radius-base;
        }
        /* Change Autocomplete styles in Chrome*/
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            box-shadow: 0 0 0px 1000px lighten(@primary-color, 10%) inset !important;
            -webkit-text-fill-color: #fff !important;
        }

        .auth-socials-separator {
            background-color: @primary-color-outline;
        }
        //Code
        code {

            &.info-code {
                background-color: lighten(@primary-color, 10%);
                color: @text-color;
                border-color: @primary-color-outline;
            }
        }
        /*====== Testimonials ======*/


        .testimonials-wrapper {
            background-color: rgba(255, 255, 255, 0.044);
        }
        /*====== SDK OFFER ======*/

        .section-separator {
            svg {
                path {
                    fill: rgba(244, 247, 254, 0.04);
                }
            }
        }

        .background-shape {
            opacity: 0.15;
        }

        .sdk-offer-wrapper {
            background-color: rgba(244, 247, 254, 0.04);
        }

        .sdk-offer {
            .features-list {

                li {


                    &:first-child {
                        &:before {
                        }
                    }

                    &:before {
                    }
                }
            }
        }

        .sdk-offer-card {
            border: 1px solid rgba(31, 31, 31, 1);
            background-color: rgba(31, 31, 31, 1);
            box-shadow: 0px 20px 35px 0px rgba(0, 0, 0, 0.2);

            &:hover,
            &:focus {
                border: 1px solid rgba(31, 31, 31, 0.7);
                background-color: rgba(31, 31, 31, 0.7);
            }

            .sdk-offer-card-icon {

                svg {
                    path {
                        fill: @text-color;
                    }
                }
            }

            .sdk-offer-card-title {
            }

            .sdk-offer-card-description {
                color: darken(@text-color, 25%);
            }

            .sdk-offer-card-logo {

                &.gdp-logo {
                    svg {
                        path.path-gd-color {
                            fill: @text-color;
                        }
                    }
                }

                &.pp-logo {
                    svg {
                        path {
                            fill: @text-color;
                        }
                    }
                }
            }

            .sdk-offer-card-link {
                color: @text-color;

                .external-icon {
                    svg {
                        path {
                            fill: @text-color;
                        }
                    }
                }
            }
        }
        //New SDK offer
        .new-sdk-offer-wrapper {

            .sdk-offer-container {

                .new-sdk-offer-card {
                    background-color: rgba(255, 255, 255, 0.044);
                }
            }

            .sdk-offer-card-logo {

                &.gdp-logo {
                    svg {
                        path.path-gd-color {
                            fill: @text-color;
                        }
                    }
                }

                &.pp-logo {
                    svg {
                        path {
                            fill: @text-color;
                        }
                    }
                }
            }
        }
        //Blog
        .blog-card {
            border: 1px solid rgba(31, 31, 31, 1);
            background-color: rgba(31, 31, 31, 1);

            .blog-title {
                color: @text-color !important;
            }

            &:hover,
            &:focus {
                box-shadow: 0px 20px 35px 0px rgba(0, 0, 0, 0.2);

                .blog-title {
                    opacity: .75;
                }
            }
        }
        //Filters
        .btn-filters {

            .ant-btn {

                &.custom-active-on {
                    color: #fff;
                    background-color: rgba(234,234,242,.04) !important;
                    border-color: @primary-color-outline;

                    &:hover,
                    &:focus {
                        color: #fff;
                        border-color: @primary-color-outline;
                    }
                }

                &.custom-active-off {
                    color: #fff;
                    background-color: lighten(@primary-color, 5%);
                    border-color: @primary-color-outline;


                    &:hover {
                        color: #fff;
                    }
                }
            }
        }

        .btn-other-tools {
            .ant-btn {
                color: #fff;
                background-color: lighten(@primary-color, 5%);
                border-color: @primary-color-outline;

                &:hover,
                &:focus {
                    color: #fff;
                    background-color: @primary-color;
                }
            }
        }

        .other-tools-dropdown {
            li {
                border-top-color: @primary-color-outline;

                a {
                    color: inherit;

                    svg {
                        path {
                            // This has been setted as important because one of the icons have a fill color that make it ignore this property
                            fill: @text-color !important;
                        }
                    }

                    &:hover,
                    &:focus {
                        color: @text-color !important;
                    }
                }
            }
        }

        .ellipsis-lines {

            &:after,
            &:before,
            div {
                background-color: @primary-color;
            }
        }
        // Search
        .search-wrapper {
            .search-tools {
                color: #fff;
                background-color: lighten(@primary-color, 5%);
                border-color: lighten(@primary-color, 15%);
            }
        }

        .search-tools.form-control {
            color: #fff;
            background-color: lighten(@primary-color, 5%);
            border-color: @primary-color-outline;
        }

        .search-icon svg path {
            fill: @text-color-secondary;
        }

        .header-search-wrapper .header-search-button {
            background-color: #1d7c76 !important;
        }

        .header-search-wrapper .header-search-button:hover, .avepdf .header-search-wrapper .header-search-button:focus {
            background-color: #1d7c76 !important;
        }

        .header-search-wrapper .header-search-button.ant-btn[disabled] {
            background-color: transparent !important;
        }

        .avepdf .header-search-wrapper .ant-input-group-addon {
            border-color: rgba(102,110,132,.2) !important;
        }

        .header-search-wrapper .ant-input {
            background-color: transparent;
        }
        // Tabs

        .nav-tabs {
            border-bottom-color: lighten(@primary-color, 5%);
        }

        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
            background-color: lighten(@primary-color, 5%);
        }

        .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
            border-color: @primary-color-outline @primary-color-outline lighten(@primary-color, 5%);
        }

        .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
            border-color: @primary-color-outline @primary-color-outline lighten(@primary-color, 5%);
        }

        @media (max-width: 575px) {
            .nav-tabs {
                .nav-link {
                    border-color: @primary-color-outline;
                }
            }
        }
        //Widgets block
        .tool-item-wrapper:hover {

            .tool-item-block {
                box-shadow: 0 40px 60px -20px rgba(0,0,0,.25);
            }
        }

        .tool-item-block {
            background-color: rgba(234, 234, 242, .04);

            .tool-item-block-title {
                color: @text-color;
            }

            .tool-item-block-description {
                color: darken(@text-color, 25%);
            }

            .tool-item-block-arrow {

                svg {
                    path {
                        fill: @text-color;
                    }
                }
            }

            .tool-item-block-arrow {
                color: @text-color;
            }
        }
        // Footer
        .footer .copyright {
            color: @text-color-secondary;
        }

        .powered-by-container {
            display: inline-block;

            .powered-by {
                path.path-gd-color {
                    fill: #fff;
                }
            }
        }
        // New Footer
        .ave-footer {

            .ave-footer-top {


                .footer-top-menu {

                    .menu-item-dropdown {

                        li {

                            &:hover,
                            &:focus {
                                background-color: lighten(@primary-color, 5%);
                            }
                        }
                    }
                }

                &.new-footer-top {
                    background-color: transparent;
                    color: @text-color;

                    .footer-title {
                        color: #fff;
                    }

                    a {
                        color: @text-color;

                        &:hover,
                        &:focus {
                            color: #1d7c76;
                        }
                    }

                    .theme-switch {
                        .btn_background {
                            border-color: @text-color;

                            .btn_face {
                                background: @text-color;
                            }
                        }
                    }

                    .powered-by-container {
                        svg {
                            .path-gd-color {
                                fill: #fff;
                            }
                        }
                    }
                }
            }

            .ave-footer-bottom {
                background-color: #080808;

                a {
                    &:hover,
                    &:focus,
                    &:active {
                        color: @focus-color !important;
                    }
                }

                .powered-by-container {

                    svg {

                        .path-gd-color {
                            fill: #ffffff;
                        }
                    }
                }
            }

            .theme-switch {

                .btn_background {
                    background: rgba(243, 243, 243, 0.2);
                    cursor: pointer;

                    .btn_face {
                        background: #fff;
                    }
                }
            }
        }
        // Buttons
        .ant-btn {

            &.add-page-btn {
                background-color: @primary-color;

                svg {
                    path {
                        fill: @text-color;
                    }
                }
            }

            &.ant-btn-buy {
                background-color: #fff !important;
                border-color: #fff !important;
                color: @primary-color !important;
            }

            svg,
            .cloud-upload-icon svg {
                path {
                    fill: @text-color;
                }
            }

            .ant-btn-disabled {
                background-color: fade(@text-color, 10%);
                color: fade(@text-color, 50%) !important;
                border-color: fade(@text-color, 20%);
            }

            &[disabled] {
                .ant-btn-disabled();

                svg {
                    path {
                        fill: fade(@text-color, 50%) !important;
                    }
                }

                &:hover {
                    .ant-btn-disabled();
                }
            }

            &.close-action-button {
                color: #fff;
                background-color: lighten(@primary-color, 5%);
                border-color: lighten(@primary-color, 5%);

                &:hover,
                &:focus {
                    color: #fff;
                    background-color: @primary-color;
                    border-color: @primary-color;
                }
            }

            &.download-action-button {
                color: #f2f2f2 !important;
                border-width: 2px;
                border-color: @primary-color-outline;
                background: @primary-color;

                &.white-action-button {
                    color: @primary-color;
                    border-width: 2px;
                    border-color: @primary-color;

                    svg path {
                    }
                }

                &:hover,
                &:focus {
                    border-color: @primary-color-outline !important;
                    background: @primary-color !important;
                }
            }

            &.shape-button {
                background-color: lighten(@primary-color, 5%);
                border-color: lighten(@primary-color, 15%) !important;
                color: @text-color;

                svg path {
                    fill: @text-color;
                }


                &.selected-shape-button {
                    background: lighten(@primary-color, 5%);
                }
            }

            &.rotate-btn {
                background-color: darken(@primary-color, 5%);
                border-color: @primary-color-outline !important;
                color: #fff;
            }

            &.custom-active-off {
                color: #fff;
                background-color: lighten(@primary-color, 5%);
                border-color: lighten(@primary-color, 15%);
            }

            &.upload-more {
                color: @text-color !important;
                border-color: @primary-color-outline;
                background: @primary-color;

                svg path {
                    fill: @text-color;
                }

                &:hover,
                &:focus {
                    border-color: @primary-color-outline !important;
                    background: @primary-color !important;
                }
            }

            &.rename-cancel-btn {
                background-color: @primary-color !important;
            }
        }

        .file-upload-option {
            .ant-btn.choose-file-btn {
                color: #4CA49F;

                svg {
                    path {
                        fill: #4CA49F;
                    }
                }

                &:hover,
                &:focus {
                    background-color: #4CA49F;
                    color: #fff;

                    svg {
                        path {
                            fill: #ffffff;
                        }
                    }
                }
            }
        }

        .ave-processed-file {
            .ant-btn {
                &:hover,
                &:focus {
                    svg {
                        path {
                            fill: #4CA49F;
                        }
                    }
                }
            }
        }

        .file-download-option {
            .ant-btn.download-file-btn {
                color: #4CA49F;

                svg {
                    path {
                        fill: #4CA49F;
                    }
                }

                &:hover,
                &:focus {
                    background-color: #4CA49F;
                    color: #fff;

                    svg {
                        path {
                            fill: #ffffff;
                        }
                    }
                }
            }
        }

        .ant-btn-group {

            &.ant-btn-group-icons {
                @media (max-width: 767.98px) {
                    .ant-btn {
                        border-color: @primary-color-outline !important;
                    }
                }
            }

            &.ant-btn-group-white {
                .ant-btn {
                    background-color: lighten(@primary-color, 5%);
                    border-color: @primary-color-outline !important;
                    color: @text-color;

                    svg {
                        path {
                            fill: @text-color;
                        }
                    }

                    &:hover,
                    &:focus {
                    }
                }
            }
        }

        .upload-more-methods li {

            .ant-btn {
                color: #fff;
                background-color: @primary-color;
                border-color: @primary-color-outline !important;

                &.ant-btn-primary {
                    color: #fff;
                    background-color: @primary-color;
                    border-color: @primary-color-outline;
                }

                &:hover,
                &:focus {
                    background-color: lighten(@primary-color, 5%);
                }
            }

            &:first-child .ant-btn {
                border-top: 1px solid @primary-color-outline;
            }
        }

        .rename-download-file {
            border-color: @primary-color-outline;

            .ant-input-group-wrapper {

                .ant-input-affix-wrapper {
                    border-color: @primary-color-outline;

                    &:hover {
                        border-color: @primary-color-outline;
                    }
                }
            }
        }

        .upload-url-input {

            .ant-input-group-addon {

                &:first-child {
                    background-color: @primary-color;
                    border-color: @primary-color-outline;
                }
            }

            .ant-btn {
                border-color: @primary-color-outline !important;

                &.url-cancel-btn {
                    background-color: @primary-color;
                }
            }
        }

        .file-picker-button {
            .ant-btn {

                &:hover,
                &:focus {
                    background-color: darken(@primary-color, 5%) !important;
                }

                span {
                    color: @text-color;

                    svg {
                        path {
                            fill: @text-color;
                        }
                    }
                }
            }
        }

        .auth-socials button {
            border: 1px solid rgba(102,110,132,.2);
            color: @text-color;
        }

        .login-icon {
            svg {
                path {
                    fill: @text-color !important;
                }
            }
        }

        .remember-checkbox {
            .ant-checkbox-inner {
                background-color: lighten(@primary-color, 10%);
                color: @text-color;
                border-color: @primary-color-outline;
            }
        }
        //Modal
        .ant-modal {
            color: @text-color;
        }

        .ant-modal-confirm-body {
            .ant-modal-confirm-title {
                color: @text-color;
            }

            .ant-modal-confirm-content {
                color: @text-color;
            }
        }

        .ant-modal-confirm-btns {
            .ant-btn-primary {
                background: darken(@primary-color, 5%);
                color: @text-color;
                border: @border-color-base;
            }
        }

        .ant-modal-content {
            background-color: @primary-color;
            border: 1px solid @primary-color-outline;

            .ant-modal-header {
                background-color: @primary-color;
                border-color: @primary-color-outline;

                .ant-modal-title {
                    color: @text-color;
                }
            }

            .ant-modal-footer {
                border-color: @primary-color-outline;
            }

            .color-picker-button {
                border-color: @primary-color-outline;
            }
        }

        .ant-modal-close {
            color: @text-color;
        }

        .ant-modal-close:focus,
        .ant-modal-close:hover {
            color: darken(@text-color, 25%);
        }

        .embed-modal {

            .embed-copy-message {


                span {
                    background: @primary-color;
                    border-color: @primary-color-outline;
                }
            }
        }
        /*====== Plans ======*/

        .plan-card {
            background-color: rgba(234,234,250,.03) !important;

            .card-footer {
                ul {
                    .plan-icon-footer {
                        svg {
                            path {
                                fill: @text-color;
                            }
                        }
                    }
                }
            }
        }

        .plan-features {


            li {


                .plan-list-icon {


                    svg {
                        path {
                            fill: @text-color;
                        }
                    }
                }
            }
        }

        .plan-users {
            color: @text-color;

            .plan-users-icon {

                path {
                    fill: @text-color;
                }
            }
        }

        .secure-data-icon {


            svg {
                path {
                    fill: @text-color;
                }
            }
        }
        /*====== Custom Notifications ======*/
        .ave-notifications {

            .notif-language-change,
            .notif-consent {
                border-color: @primary-color-outline;
                background-color: @primary-color;
            }

            .btn {
                &.btn-cancel {
                    background-color: lighten(@primary-color, 5%);
                    border-color: @primary-color-outline;
                    color: #fff;

                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }
            }

            .btn-cookie-dismiss {
                .btn {
                    background-color: lighten(@primary-color, 5%);
                    border-color: @primary-color-outline;
                }
            }
        }
        /*====== Notifications ======*/
        .ant-notification-notice {
            border-color: @primary-color-outline;
            background-color: @primary-color;

            .ant-notification-notice-message {
                color: #fff;
            }

            .ant-btn {
                background-color: lighten(@primary-color, 5%);
                border-color: @primary-color-outline;
                color: #fff;

                &.btn-cancel {
                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }

                &.btn-confirm {
                    background-color: #b7cc27;
                    border-color: #b7cc27;
                }

                &:hover,
                :focus {
                    background-color: @primary-color;
                    border-color: @primary-color-outline;
                    color: #fff;
                }
            }
            /*====== notification consent ======*/
            &.notif-cconsent {
            }
            /*====== notification language changer ======*/
            &.notif-language-change {
            }
        }
        //Dropdowns
        .ant-dropdown-menu {
            background-color: lighten(@primary-color, 10%);
            border-color: @primary-color-outline;

            .ant-dropdown-menu-item-divider {
                background-color: @primary-color-outline;
            }

            .ant-dropdown-menu-item {
                color: @text-color;

                &:hover,
                &:focus {
                    background-color: @primary-color;
                }
            }

            &.ant-dropdown-menu-light {


                &.profile-dropdown {
                    background-color: @primary-color;
                    color: #fff;
                    border-color: @primary-color-outline;

                    .ant-dropdown-menu-item {
                        color: #fff;
                    }

                    .ant-dropdown-menu-item:hover, .ant-dropdown-menu .ant-dropdown-menu-item:focus {
                        background-color: lighten(@primary-color, 5%);
                        color: #fff;
                    }

                    .ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected, .ant-dropdown-menu-item-selected > a, .ant-dropdown-menu-submenu-title-selected > a {
                        background-color: lighten(@primary-color, 5%);
                    }
                }

                .ant-dropdown-menu-item {
                    color: @text-color;
                }

                .ant-dropdown-menu-item:hover, .ant-dropdown-menu .ant-dropdown-menu-item:focus {
                    background-color: lighten(@primary-color, 75%);
                    color: @primary-color;
                }

                .ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected, .ant-dropdown-menu-item-selected > a, .ant-dropdown-menu-submenu-title-selected > a {
                    background-color: lighten(@primary-color, 75%);
                    color: @primary-color;
                }
            }

            &.other-tools-dropdown {
                background-color: lighten(@primary-color, 10%);
                border-color: @primary-color-outline;
                border-radius: @border-radius-base;

                .ant-dropdown-menu-item {
                    &:hover,
                    &:focus {
                        background-color: darken(@primary-color, 5%);
                        border-color: @primary-color-outline;
                    }
                }

                li {
                    a {
                        color: @text-color;

                        svg {
                            path {
                                fill: @text-color;
                            }
                        }
                    }

                    &:last-child {
                        border-radius: 0 0 @border-radius-base @border-radius-base;
                    }
                }
            }
        }


        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            background-color: lighten(@primary-color, 10%);
            border-color: @primary-color-outline;
            color: @text-color;
        }

        .ant-select-dropdown {
            border-color: @primary-color-outline;
            border-radius: @border-radius-base;
        }

        .ant-select-arrow {
            color: @text-color;
        }

        .ant-select-item {
            color: @text-color;
        }

        .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
            background-color: @primary-color;
        }

        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background-color: @primary-color;
        }

        .ant-dropdown-trigger {
            background-color: lighten(@primary-color, 10%);
            border-color: @primary-color-outline;
            color: @text-color;

            &.select-crop-options {
                background-color: transparent;
                border-color: transparent;
            }

            &.selected-language {
                background-color: transparent;
                border-color: transparent;
            }

            .anticon {
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }

        .ant-dropdown-trigger:after {
            color: @text-color;
        }

        .ant-select-selection {
            background-color: lighten(@primary-color, 10%);
            border: @primary-color-outline;
            color: #fff;
        }

        .ant-select-dropdown {
            background-color: lighten(@primary-color, 10%);
            padding: 0;

            .ant-select-dropdown-menu {
                background-color: lighten(@primary-color, 10%);
                border: 1px solid @primary-color-outline;
            }

            .ant-select-dropdown-menu-item {
                color: #fff;

                &:hover {
                    background-color: @primary-color !important;
                }
            }
        }

        .ant-select-dropdown-menu-item-selected,
        .ant-select-dropdown-menu-item-selected:hover {
            background-color: @primary-color;
            color: #fff;
        }

        .ant-select-multiple {
            .ant-select-selection-item {
                background-color: darken(@primary-color, 5%);
                border-color: @primary-color-outline;

                svg {
                    path {
                        fill: #fff;
                    }
                }
            }

            .ant-select-selector {
                background-color: @primary-color;
                border-color: @primary-color-outline;
                color: #fff;
            }
        }

        .ant-input {
            background-color: lighten(@primary-color, 10%);
            color: @text-color;
            border-color: @primary-color-outline;

            svg {
                path {
                    fill: @text-color;
                }
            }
        }

        .ant-input-group-wrapper {
            .ant-input-wrapper {
                .ant-input-group-addon {
                    background-color: @primary-color;
                    border-color: @primary-color-outline;
                    color: @text-color;
                }
            }
        }

        .ant-input-number {
            background-color: lighten(@primary-color, 10%);
            border-color: @primary-color-outline;
            color: @text-color;
        }

        .ant-input-number-handler-down {
            border-top-color: @primary-color-outline;
        }

        .ant-input-number-handler-up-inner,
        .ant-input-number-handler-down-inner {
            color: @text-color;
        }

        .ant-input-number-handler-up:hover,
        .ant-input-number-handler-down:hover {
            svg path {
                fill: white;
            }
        }

        .ant-input-number-handler-wrap {
            background-color: @primary-color;
            border-color: @primary-color-outline;
        }

        .ant-input-prefix {
            color: @text-color;

            svg {
                path {
                    fill: @text-color;
                }
            }
        }

        .ant-input-suffix {
            color: @text-color;

            svg {
                path {
                    fill: @text-color;
                }
            }
        }

        split-simple-mode-input {
            color: @text-color;
        }

        .ant-input-password-icon {
            color: #fff;

            svg {
                path {
                    fill: @text-color !important;
                }
            }
        }

        ::-webkit-input-placeholder,
        ::-moz-placeholder,
        ::-ms-input-placeholder {
            /* Chrome/Opera/Safari */
            color: fadeout(@text-color, 50%);
        }

        .form-control {
            border-color: @primary-color-outline;
            background: lighten(@primary-color, 10%);
            color: #fff !important;
        }

        .input-group-text {
            border-color: @primary-color-outline;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background: @primary-color;
            border-color: @primary-color-outline;
        }

        .ant-radio-button-wrapper {
            background: @primary-color;
            border-color: @primary-color-outline;
            color: @text-color;

            &:not(:first-child)::before {
                background-color: @primary-color-outline;
            }

            .below-layer-svg, .above-layer-svg {
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }

            @media (max-width: 768px) {
                border-color: @primary-color-outline;
            }
        }

        .font-styles {
            border-color: @primary-color-outline;
            background: lighten(@primary-color, 10%);
        }

        .text-alignment {
            border-color: @primary-color-outline;
            background: lighten(@primary-color, 10%);
        }
        //Forms
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: lighten(@primary-color, 30%);
            opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: lighten(@primary-color, 30%);
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
            color: lighten(@primary-color, 30%);
        }
        //Tabs
        .avepdf-upload-tabs {


            .ant-tabs-nav {
                &:before {
                    border-color: @primary-color-outline;
                }


                .ant-tabs-nav-list {
                    .ant-tabs-tab {
                        background-color: @primary-color;
                        border-color: @primary-color-outline;

                        .ant-tabs-tab-btn {
                            color: @text-color;
                        }

                        &.ant-tabs-tab-active {
                            background-color: lighten(@primary-color, 5%);
                            border-bottom-color: lighten(@primary-color, 5%);

                            .ant-tabs-tab-btn {
                                color: @text-color;
                            }
                        }
                    }
                }
            }

            .ant-tabs-content-holder {
                background-color: lighten(@primary-color, 5%);
                border-color: @primary-color-outline;
            }
        }
        //Others
        .ant-popover-content {
            .ant-popover-inner {
                background-color: @primary-color;

                .ant-popover-inner-content {
                    border-color: @primary-color-outline;

                    .selected-shape-button:active:hover {
                    }
                }
            }

            .ant-popover-arrow {
                background: @primary-color;
                border-left-color: @primary-color-outline;
                border-top-color: @primary-color-outline;
            }
        }
        // Policy Protect
        .policy-protect-wrapper {
            .policy-protect-icon {
                svg {
                    path {
                        fill: @text-color;
                    }
                }
            }

            .policy-protect-content {
                line-height: 1.6;
                display: inline-block;
            }
        }
        // Tools
        .tools-actions-row {
            background-color: @primary-color;
        }
        //Thumbnails
        .thumbnail-part {
            background-color: @primary-color;
            border: 1px solid @primary-color-outline;

            .thumbnail-title {
                color: @text-color;
            }

            .thumbnail-number {
                color: @text-color;
            }

            &:hover:not(.ui-sortable-helper) {
                background-color: darken(@primary-color, 5%);

                svg {
                    path {
                    }
                }
            }
        }

        .thumbnail-icons-wrapper {

            .btn {
                background: @primary-color;
                border-color: @primary-color-outline;
                color: @text-color;

                &:hover {
                }
            }

            .thumbnail-close {
                svg {
                    path {
                        fill: @text-color;
                    }
                }
            }
        }
        // upload
        .back-icon svg path {
            fill: @text-color;
        }
        // slider
        .ant-slider-mark-text {
            color: @text-color;
        }

        .ant-slider-mark-text-active {
            color: @text-color;
        }

        .document {
            background-color: @primary-color;
            border-color: @primary-color-outline;

            .horizontal-tool-title {
                color: @text-color;
            }

            .horizontal-tool-description {
                color: @text-color-secondary;
            }
        }

        .upload-progress > .ant-progress-text {
            color: @text-color;
        }

        .upload-progress-loading {
            fill: @text-color;
        }

        .upload-icon,
        .loading-icon svg {
            fill: @text-color;
        }

        .document-icon {
            path {
                fill: @text-color;
            }
        }
        // Offer tools on download
        .download-offer-tools {
            border-top-color: @primary-color-outline;
            border-bottom-color: @primary-color-outline;
        }
        // How to use
        .how-to-use {
            border-top-color: @primary-color-outline;
            border-bottom-color: @primary-color-outline;
        }
        // Split PDF
        .split-mode {
            background-color: @primary-color;
            border-color: @primary-color-outline;

            .split-mode-title {
                color: @text-color;
            }

            .split-mode-subtitle {
                color: @text-color;
            }

            svg {
                path {
                    fill: @text-color;
                }
            }
        }

        .split-add {
            border: 1px solid @primary-color-outline;
        }

        .split-close {
            color: #fff !important;
        }

        .split-simple-mode-select {
            background-color: @primary-color;
            border-color: @primary-color-outline;

            .split-simple-mode-select-label {
                background-color: darken(@primary-color, 5%);
                border-color: darken(@primary-color, 5%);
            }
        }

        .split-simple-mode-description,
        .split-advanced-mode-description {
            color: @text-color;
        }

        .split-simple-mode-icon,
        .split-advanced-mode-icon {
            path {
                fill: @text-color
            }
        }
        //Protect PDF
        .protect-pdf-block-title {
            background-color: fade(mix(rgb(160, 188, 222), @primary-color, 3%), 100%);
        }

        .protect-pdf-select-mode-title,
        .protect-pdf-subtitle {
            color: @text-color;
        }

        .protect-pdf-mode-wrapper {
            svg {
                path {
                    fill: @text-color;
                }
            }
        }
        //Edit PDF
        .remeber-input.ant-checkbox-wrapper {
            color: @text-color;
        }

        .preview-content-img {
            svg {
                path {
                    fill: @primary-color-outline;
                }
            }
        }
        //Read Barcodes
        .barcode-info-wrapper {

            .ant-btn {
                &.ant-btn-white {
                    background-color: @primary-color;
                    border-color: @primary-color-outline;

                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }

                &:hover,
                &:focus {
                    background-color: darken(@primary-color, 10%);

                    svg {
                        path {
                            fill: #fff !important;
                        }
                    }
                }
            }
        }
        /*----------------------
    PDF/A Validation
    -----------------------*/
        .pdfa-xml-result {

            &.ant-collapse {
                background: @primary-color;
                border-color: rgba(198, 40, 40, 0.4);

                .ant-collapse-item {
                    border-color: rgba(198, 40, 40, 0.4);

                    .ant-collapse-header {
                        color: #ff6666;
                    }

                    .ant-collapse-content {
                        color: @text-color;
                        background-color: rgba(160, 188, 222, 0.03);
                        border-color: rgba(198, 40, 40, 0.4);

                        .report-error-occurence {
                            span:first-child {
                            }
                        }
                    }
                }
            }
        }
        //Remove Text & Resize PDF
        .ant-radio-wrapper,
        .ant-checkbox-wrapper {
            color: @text-color;
        }
        //Txt to PDF
        .margins-icon-wrapper {
            .margin-icon-value {
                background-color: lighten(@primary-color, 10%);
                color: @text-color;
                border-color: @primary-color-outline;
            }

            svg {
                path {
                    fill: @text-color;
                }
            }
        }
        // Tooltip
        .ave-tooltip {
            background-color: lighten(@primary-color, 5%);
            color: @text-color;
            /*border-color: lighten(@primary-color, 15%);*/
        }

        .ave-tooltip-triangle {
            background-color: lighten(@primary-color, 5%);
            /*border-color: lighten(@primary-color, 15%);*/
        }

        .not-found-header,
        .not-found-title,
        .not-found-subtitle {
            color: @text-color;
        }
        /* Empty placeholder */
        .placeholder-wrapper {

            &.price-placeholder {


                .placeholder-content {
                }
            }
        }

        .placeholder-content {
            background: #4a4a4a; // Fallback
            background: linear-gradient(to right, #4a4a4a 2%, #282828 18%, #4a4a4a 33%);
            background-size: 1300px; // Animation Area

            &-item {
                background: #282828;

                &:before {
                    background-color: inherit;
                }

                &:after {
                    background-color: inherit;
                }
            }
        }

        .placeholder-animate-bg {
            background: #4a4a4a;
            background: linear-gradient(to right, #4a4a4a 2%, #282828 18%, #4a4a4a 33%);
        }
    }
}

/* Avelyn ads start */

#avelyn-ads-banner {
    padding: 1rem 2rem;
    background-color: #EAEDF5;

    .avelyn-ai-star-svg {
        width: 40px;
        margin: 5px 0;
        background-color: #777CF0;
        border-radius: 15%;
        fill: white;
        padding: 0
    }
    
    .title {
        font-weight: 600;
        font-size: 24px;
    }
    
    .goto-btn {
        background-color: black;
        color: white;
        stroke: white;
        fill: white;
        margin: 0
    }
}

.avelynTool {
    .tool-item-block {
        background-color: #EAEDF5;
    }
    .tool-item-block-icon, &.tool-item-block-icon {
        background-color: #777CF0 !important;
    }
    .external-website-arrow-md-svg {
        fill: black;
        stroke: black;
        width: 20px;
        height: 20px
    }
}

#avelyn-btn-header {
    align-self: center;
    .btn {
        background: black;
        color: white;
        fill: white
    }
}

:root[data-theme="dark"] {
    #avelyn-ads-banner {
        background-color: #3D3E3F;
    }

    .avelynTool .tool-item-block {
        background-color: #3D3E3F;
        .external-website-arrow-md-svg {
            fill: white;
            stroke: white;
        }
    }
    
    #avelyn-btn-header {
        .btn {
            background: white;
            color: black;
            fill: black
        }
    }
}



